import React, { useContext, useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import logo from '../logo.png';
import logoDark from '../logo_dark.png';
import AuthContext from '../context/AuthContext';

const NavBar = ({ mode, setMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);

  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  // Menu anchor states
  const [organizeAnchorEl, setOrganizeAnchorEl] = useState(null);
  const [createAnchorEl, setCreateAnchorEl] = useState(null);
  const [customAnchorEl, setCustomAnchorEl] = useState(null);
  const [predictAnchorEl, setPredictAnchorEl] = useState(null);

  // Determine if the current page is the home page
  const isHomePage = location.pathname === '/';

  // Decide if the navbar should have a dark (solid) background
  useEffect(() => {
    if (isHomePage) {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > window.innerHeight - 80);
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    } else {
      setIsScrolled(true);
    }
  }, [isHomePage]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setPredictAnchorEl(null); // Close predict menu when drawer is toggled
  };

  // Menu handlers
  const handleOrganizeClick = (event) => setOrganizeAnchorEl(event.currentTarget);
  const handleCreateClick = (event) => setCreateAnchorEl(event.currentTarget);
  const handleCustomClick = (event) => setCustomAnchorEl(event.currentTarget);
  const handleCloseMenus = () => {
    setOrganizeAnchorEl(null);
    setCreateAnchorEl(null);
    setCustomAnchorEl(null);
    setPredictAnchorEl(null);
  };

  // Common button style for desktop navigation
  const buttonStyle = {
    display: { xs: 'none', md: 'inline-flex' },
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: isScrolled || !isHomePage ? '#000' : '#fff',
  };

  // MOBILE DRAWER content
  const drawer = (
    <List>
      {/* Home always visible */}
      <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
        <ListItemText primary="Home" />
      </ListItem>

      {/* Show API Documentation *only* if on home page & user is logged in */}
      {isHomePage && user && (
        <ListItem
          button
          component={Link}
          to="/api-docs"
          onClick={handleDrawerToggle}
        >
          <ListItemText primary="API Documentation" />
        </ListItem>
      )}

      {user && (
        <>
          <ListSubheader>Organize & Discover</ListSubheader>
          <ListItem button component={Link} to="/classify-save" onClick={handleDrawerToggle}>
            <ListItemText primary="Upload & Classify" />
          </ListItem>
          <ListItem button component={Link} to="/search-images" onClick={handleDrawerToggle}>
            <ListItemText primary="Search Library" />
          </ListItem>
          <ListItem button component={Link} to="/web-search-images" onClick={handleDrawerToggle}>
            <ListItemText primary="Web Search" />
          </ListItem>

          <ListSubheader>Create & Generate</ListSubheader>
          <ListItem button component={Link} to="/generate-images" onClick={handleDrawerToggle}>
            <ListItemText primary="Image Generation" />
          </ListItem>
          <ListItem button component={Link} to="/generate-design" onClick={handleDrawerToggle}>
            <ListItemText primary="Design Copilot" />
          </ListItem>

          <ListSubheader>Custom Models</ListSubheader>
          <ListItem button component={Link} to="/create-model" onClick={handleDrawerToggle}>
            <ListItemText primary="Create Model" />
          </ListItem>
          <ListItem button component={Link} to="/list-models" onClick={handleDrawerToggle}>
            <ListItemText primary="My Models" />
          </ListItem>
          <ListItem button component={Link} to="/add-class" onClick={handleDrawerToggle}>
            <ListItemText primary="Add Classes" />
          </ListItem>
          <ListItem button onClick={(e) => setPredictAnchorEl(e.currentTarget)}>
            <ListItemText primary="Predict" />
          </ListItem>
          {Boolean(predictAnchorEl) && (
            <List sx={{ pl: 4 }}>
              <ListItem 
                button 
                component={Link} 
                to="/predict" 
                onClick={() => {
                  handleDrawerToggle();
                  setPredictAnchorEl(null);
                }}
              >
                <ListItemText primary="Image Prediction" />
              </ListItem>
              <ListItem 
                button 
                component={Link} 
                to="/predict-video" 
                onClick={() => {
                  handleDrawerToggle();
                  setPredictAnchorEl(null);
                }}
              >
                <ListItemText primary="Video Prediction" />
              </ListItem>
            </List>
          )}
        </>
      )}

      {/* Auth Buttons */}
      {!user ? (
        <>
          <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
            <ListItemText primary="Login" />
          </ListItem>
          <ListItem button component={Link} to="/signup" onClick={handleDrawerToggle}>
            <ListItemText primary="Sign Up" />
          </ListItem>
        </>
      ) : (
        <ListItem button onClick={() => { handleLogout(); handleDrawerToggle(); }}>
          <ListItemText primary="Logout" />
        </ListItem>
      )}
    </List>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: isScrolled || !isHomePage ? '#fff' : 'transparent',
          boxShadow: isScrolled || !isHomePage ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
          transition: 'background-color 0.3s ease',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Mobile menu icon */}
          <IconButton
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { xs: 'block', md: 'none' },
              color: isScrolled || !isHomePage ? '#000' : '#fff',
              marginRight: 'auto',
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo */}
          <Link to="/">
            <img
              src={isScrolled || !isHomePage ? logoDark : logo}
              alt="Logo"
              style={{ height: 40, cursor: 'pointer' }}
            />
          </Link>

          {/* Desktop Navigation */}
          <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end' }}>
            <Button color="inherit" component={Link} to="/" sx={buttonStyle}>
              Home
            </Button>

            {isHomePage && user && (
              <Button
                color="inherit"
                component={Link}
                to="/api-docs"
                sx={buttonStyle}
              >
                API Documentation
              </Button>
            )}

            {user && (
              <>
                <Button color="inherit" onClick={handleOrganizeClick} sx={buttonStyle}>
                  Organize & Discover
                </Button>
                <Menu
                  anchorEl={organizeAnchorEl}
                  open={Boolean(organizeAnchorEl)}
                  onClose={handleCloseMenus}
                >
                  <MenuItem component={Link} to="/classify-save" onClick={handleCloseMenus}>
                    Upload & Classify
                  </MenuItem>
                  <MenuItem component={Link} to="/search-images" onClick={handleCloseMenus}>
                    Search Library
                  </MenuItem>
                  <MenuItem component={Link} to="/web-search-images" onClick={handleCloseMenus}>
                    Web Search
                  </MenuItem>
                </Menu>

                <Button color="inherit" onClick={handleCreateClick} sx={buttonStyle}>
                  Create & Generate
                </Button>
                <Menu
                  anchorEl={createAnchorEl}
                  open={Boolean(createAnchorEl)}
                  onClose={handleCloseMenus}
                >
                  <MenuItem component={Link} to="/generate-images" onClick={handleCloseMenus}>
                    Image Generation
                  </MenuItem>
                  <MenuItem component={Link} to="/generate-design" onClick={handleCloseMenus}>
                    Design Copilot
                  </MenuItem>
                </Menu>

                <Button color="inherit" onClick={handleCustomClick} sx={buttonStyle}>
                  Custom Models
                </Button>
                <Menu
                  anchorEl={customAnchorEl}
                  open={Boolean(customAnchorEl)}
                  onClose={handleCloseMenus}
                >
                  <MenuItem component={Link} to="/create-model" onClick={handleCloseMenus}>
                    Create Model
                  </MenuItem>
                  <MenuItem component={Link} to="/list-models" onClick={handleCloseMenus}>
                    My Models
                  </MenuItem>
                  <MenuItem component={Link} to="/add-class" onClick={handleCloseMenus}>
                    Add Classes
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={(e) => setPredictAnchorEl(e.currentTarget)}
                  >
                    Predict
                  </MenuItem>
                </Menu>

                {/* Nested Predict Menu */}
                <Menu
                  anchorEl={predictAnchorEl}
                  open={Boolean(predictAnchorEl)}
                  onClose={() => setPredictAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onMouseLeave={() => setPredictAnchorEl(null)}
                  MenuListProps={{
                    onMouseLeave: () => setPredictAnchorEl(null)
                  }}
                >
                  <MenuItem 
                    component={Link} 
                    to="/predict" 
                    onClick={handleCloseMenus}
                  >
                    Image Prediction
                  </MenuItem>
                  <MenuItem 
                    component={Link} 
                    to="/predict-video" 
                    onClick={handleCloseMenus}
                  >
                    Video Prediction
                  </MenuItem>
                </Menu>
              </>
            )}

            {!user ? (
              <>
                <Button color="inherit" component={Link} to="/login" sx={buttonStyle}>
                  Login
                </Button>
                <Button color="inherit" component={Link} to="/signup" sx={buttonStyle}>
                  Sign Up
                </Button>
              </>
            ) : (
              <Button color="inherit" onClick={handleLogout} sx={buttonStyle}>
                Logout
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default NavBar;