import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../api";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
  Box,
  Alert,
  Snackbar,
  Tabs,
  Tab,
  Chip,
  Paper,
  IconButton,
  Stack,
  Tooltip
} from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AnalyticsIcon from '@mui/icons-material/Analytics';

const ListModels = ({ setModelId, setModelName }) => {
  const [models, setModels] = useState({ image: [], video: [] });
  const [activeTab, setActiveTab] = useState('image');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [selectedModelType, setSelectedModelType] = useState(null);
  const [shareUsername, setShareUsername] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchModels = async () => {
    try {
      const [imageResponse, videoResponse] = await Promise.all([
        api.get("/models"),
        api.get("/video-models")
      ]);
      
      setModels({
        image: imageResponse.data,
        video: videoResponse.data
      });
    } catch (error) {
      console.error("Failed to fetch models", error);
    }
  };

  useEffect(() => {
    if (location.state?.message) {
      setShowMessage(true);
    }
    fetchModels();
  }, [location.state]);

  const handleModelSelect = (modelId, modelName, type) => {
    setModelId(modelId);
    setModelName(modelName);
    
    if (location.state?.returnTo) {
      navigate(location.state.returnTo);
    } else {
      navigate(type === 'video' ? '/predict-video' : '/predict');
    }
  };

  const handleOpenShareDialog = (modelId, type) => {
    setSelectedModelId(modelId);
    setSelectedModelType(type);
    setShareDialogOpen(true);
  };

  const handleOpenDeleteDialog = (modelId, type) => {
    setSelectedModelId(modelId);
    setSelectedModelType(type);
    setDeleteDialogOpen(true);
  };

  const handleShare = async () => {
    try {
      const formData = new FormData();
      formData.append("target_username", shareUsername);

      const endpoint = selectedModelType === 'video' 
        ? `/video-models/${selectedModelId}/share`
        : `/models/${selectedModelId}/share`;

      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setShareDialogOpen(false);
      setShareUsername("");
      setShowMessage(true);
      location.state = { message: "Model shared successfully!" };
    } catch (error) {
      console.error("Failed to share model", error);
      setShowMessage(true);
      location.state = { message: "Failed to share model" };
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const endpoint = selectedModelType === 'video' 
        ? `/video-models/${selectedModelId}`
        : `/models/${selectedModelId}`;

      await api.delete(endpoint);
      setDeleteDialogOpen(false);
      fetchModels();
      setShowMessage(true);
      location.state = { message: "Model deleted successfully" };
    } catch (error) {
      console.error("Delete error:", error);
      setShowMessage(true);
      location.state = { message: "Failed to delete model" };
    }
  };

  const renderModelList = (modelType) => {
    const currentModels = models[modelType];
    
    if (currentModels.length === 0) {
      return (
        <Box sx={{ textAlign: "center", mt: 4, p: 3 }}>
          <Typography variant="h6" color="textSecondary">
            No {modelType} models available
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate("/create-model")}
            sx={{ mt: 2 }}
          >
            Create {modelType} Model
          </Button>
        </Box>
      );
    }

    return (
      <List>
        {currentModels.map((model) => (
          <Paper 
            key={model.id} 
            elevation={2} 
            sx={{ 
              mb: 2, 
              borderRadius: '12px',
              overflow: 'hidden'
            }}
          >
            <ListItem sx={{ p: 3 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={7}>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      {modelType === 'video' ? 
                        <VideoLibraryIcon color="primary" /> : 
                        <ImageIcon color="primary" />
                      }
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {model.name}
                      </Typography>
                      {model.shared && (
                        <Chip label="Shared" size="small" color="secondary" />
                      )}
                    </Box>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="textSecondary">
                        {model.description}
                      </Typography>

                      {/* Class Count on one line, class names on the next line */}
                      <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
                        <Box>
                          <Chip label={`${model.class_count} Classes`} size="small" />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                          {Object.entries(model.class_details || {}).map(([className, count]) => (
                            <Chip
                              key={className}
                              label={`${className}: ${count}`}
                              size="small"
                              variant="outlined"
                            />
                          ))}
                        </Box>
                      </Stack>
                    </>
                  }
                />

                </Grid>

                <Grid item xs={12} md={5}>
                <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: 'flex-end',     // Align items to the right
                      justifyContent: 'flex-end', // Push them to the right
                    }}
                  >
    {/* First row of icon-only buttons */}
    <Stack direction="row" spacing={2} flexWrap="wrap">
      <Tooltip title="Select Model">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleModelSelect(model.id, model.name, modelType)}
          sx={{
            // Adjust these as needed for consistent sizing
            minWidth: 40,
            padding: '6px 12px',
          }}
        >
          <PlayArrowIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Add Class">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setModelId(model.id);
            setModelName(model.name);
            navigate(modelType === 'video' ? "/add-class-video" : "/add-class");
          }}
          sx={{
            minWidth: 40,
            padding: '6px 12px',
          }}
        >
          <AddIcon />
        </Button>
      </Tooltip>

      <Tooltip title="View Inferences">
        <Button
          variant="outlined"
          onClick={() => {
            if (modelType === 'video') {
              navigate(`/video-models/${model.id}/inferences`);
            } else {
              navigate(`/models/${model.id}/inferences`);
            }
          }}
          sx={{
            minWidth: 40,
            padding: '6px 12px',
          }}
        >
          <AnalyticsIcon />
        </Button>
      </Tooltip>
    </Stack>

    {/* Second row of icon-only buttons */}
    <Stack direction="row" spacing={2} flexWrap="wrap">
      <Tooltip title="Share">
        <span>
          <Button
            variant="outlined"
            color="primary"
            disabled={!model.is_owner}
            onClick={() => handleOpenShareDialog(model.id, modelType)}
            sx={{
              minWidth: 40,
              padding: '6px 12px',
            }}
          >
            <ShareIcon />
          </Button>
        </span>
      </Tooltip>

      <Tooltip title="Delete">
        <span>
          <Button
            variant="outlined"
            color="error"
            disabled={!model.is_owner}
            onClick={() => handleOpenDeleteDialog(model.id, modelType)}
            sx={{
              minWidth: 40,
              padding: '6px 12px',
            }}
          >
            <DeleteIcon />
          </Button>
        </span>
      </Tooltip>
    </Stack>
  </Box>
</Grid>

              </Grid>
            </ListItem>
          </Paper>
        ))}
      </List>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: "5rem", padding: "1rem" }}>
      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowMessage(false)} 
          severity="info"
          elevation={6}
          variant="filled"
        >
          {location.state?.message}
        </Alert>
      </Snackbar>

      <Paper elevation={3} sx={{ p: 3, borderRadius: '12px' }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center", color: "#550FCC", mb: 3 }}>
          My AI Models
        </Typography>

        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          centered
          sx={{ mb: 3 }}
        >
          <Tab 
            icon={<ImageIcon />} 
            label="Image Models" 
            value="image"
            sx={{ 
              '&.Mui-selected': { color: '#550FCC' },
              textTransform: 'none',
              fontSize: '1rem'
            }}
          />
          <Tab 
            icon={<VideoLibraryIcon />} 
            label="Video Models" 
            value="video"
            sx={{ 
              '&.Mui-selected': { color: '#550FCC' },
              textTransform: 'none',
              fontSize: '1rem'
            }}
          />
        </Tabs>

        {renderModelList(activeTab)}
      </Paper>

      {/* Share Dialog */}
      <Dialog open={shareDialogOpen} onClose={() => setShareDialogOpen(false)}>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Enter the username of the person you want to share this {selectedModelType} model with:
          </DialogContentText>
          <TextField
            fullWidth
            label="Username"
            value={shareUsername}
            onChange={(e) => setShareUsername(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShareDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleShare} 
            color="primary" 
            variant="contained"
            disabled={!shareUsername.trim()}
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {selectedModelType} model? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ListModels;