import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Chip
} from "@mui/material";
import AuthContext from "../context/AuthContext";
import AddIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import VideoFileIcon from '@mui/icons-material/VideoFile'; // Added for video
import DeleteIcon from '@mui/icons-material/Delete';
import Joyride from "react-joyride";

const AddClass = ({ modelId, modelName, modelType = "image" }) => {
  const [className, setClassName] = useState("");
  const [reasoning, setReasoning] = useState("");
  const [files, setFiles] = useState([]);
  const [embeddingChoice, setEmbeddingChoice] = useState("image");
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [loadingDescription, setLoadingDescription] = useState(false);
  const [isClassLoading, setIsClassLoading] = useState(false);
  const [isDescriptionGenerated, setIsDescriptionGenerated] = useState(false);
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true);
  const [uploadProgress, setUploadProgress] = useState({});
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  // Separate tutorial steps for image and video
  const tutorialSteps = [
    {
      target: ".class-name-field",
      content: "Enter the name of the class you want to add.",
    },
    {
      target: ".file-upload",
      content: modelType === "video" 
        ? "Upload example videos for this class. You can upload multiple videos."
        : "Upload example images for this class. You can upload multiple images.",
    },
    {
      target: ".embedding-choice",
      content: `Select how the ${modelType} will be processed for classification.`,
    },
    ...(embeddingChoice === "combined" ? [{
      target: ".generate-explanation-button",
      content: `Click here to generate an explanation for the ${modelType} class.`
    }] : []),
    {
      target: ".add-class-button",
      content: "Click here to add the class.",
    },
  ];

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
    event.target.value = ''; // Reset file input
  };

  const removeFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setUploadProgress(prev => {
      const newProgress = { ...prev };
      delete newProgress[index];
      return newProgress;
    });
  };

  const generateExplanation = async () => {
    if (files.length === 0 || !className) {
      alert(`Please upload at least one ${modelType} and provide a class name before generating a description.`);
      return;
    }

    setLoadingDescription(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("class_name", className);

    try {
      const response = await api.post(
        modelType === "video" ? "/generate-video-description" : "/generate-description", 
        formData, 
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setReasoning(response.data.description);
      setIsDescriptionGenerated(true);
    } catch (error) {
      alert("Error generating description");
    } finally {
      setLoadingDescription(false);
    }
  };

  const addClass = async () => {
    if (!modelId) {
      alert("No model selected. Please create a model first OR select from existing models.");
      return;
    }

    if (files.length === 0) {
      alert(`Please upload at least one ${modelType} file.`);
      return;
    }

    setIsClassLoading(true);
    const formData = new FormData();
    formData.append("name", className);
    formData.append("reasoning", reasoning);
    formData.append("embedding_choice", embeddingChoice);

    // Add files to formData
    files.forEach((file, index) => {
      formData.append("files", file);

      // Set up progress tracking for each file
      setUploadProgress(prev => ({
        ...prev,
        [index]: 0
      }));
    });

    try {
      const endpoint = modelType === "video" 
        ? `/video-models/${modelId}/classes`
        : `/models/${modelId}/classes`;

      const response = await api.post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(prev => ({
            ...prev,
            total: progress
          }));
        }
      });

      setIsClassAdded(true);
      alert(`Class Added: ${response.data.class_name}`);
    } catch (error) {
      alert(`Error adding ${modelType} class`);
    } finally {
      setIsClassLoading(false);
      setUploadProgress({});
    }
  };

  const getFileTypeValidation = () => {
    return modelType === "video" 
      ? "video/*"
      : "image/jpeg,image/png,image/gif,image/webp";
  };

  return (
    <Container maxWidth="md" sx={{ 
      display: "flex", 
      flexDirection: "column", 
      justifyContent: "center", 
      alignItems: "center", 
      minHeight: "100vh", 
      padding: "1rem",
      paddingTop: '100px'
    }}>
      <Joyride steps={tutorialSteps} run={true} continuous showSkipButton />

      <Paper elevation={3} sx={{ padding: "2rem", width: "100%", borderRadius: "12px", mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center", color: "#550FCC", mb: 2 }}>
          Add {modelType.charAt(0).toUpperCase() + modelType.slice(1)} Class to Model: {modelName}
        </Typography>

        <TextField
          fullWidth
          label="Class Name"
          variant="outlined"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          margin="normal"
          className="class-name-field"
          InputProps={{
            sx: { borderRadius: "8px" },
          }}
        />

        <FormControl fullWidth sx={{ marginTop: "1rem" }} className="embedding-choice">
          <InputLabel>Model Training Type</InputLabel>
          <Select
            value={embeddingChoice}
            onChange={(e) => setEmbeddingChoice(e.target.value)}
            label="Model Training Type"
          >
            <MenuItem value="image">
              {modelType === "video" ? "Video Only" : "Image Only"}
            </MenuItem>
            <MenuItem value="combined">
              {modelType === "video" ? "Video + Text (Combined)" : "Image + Text (Combined)"}
            </MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ mt: 2 }} className="file-upload">
          <input
            type="file"
            multiple
            accept={getFileTypeValidation()}
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <Button
            variant="outlined"
            startIcon={modelType === "video" ? <VideoFileIcon /> : <AddIcon />}
            onClick={() => fileInputRef.current.click()}
            sx={{ mb: 2 }}
          >
            Select {modelType === "video" ? "Videos" : "Images"}
          </Button>

          {files.length > 0 && (
            <List>
              {files.map((file, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={file.name}
                    secondary={
                      uploadProgress[index] !== undefined && (
                        <LinearProgress 
                          variant="determinate" 
                          value={uploadProgress[index]} 
                          sx={{ mt: 1 }}
                        />
                      )
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      aria-label="delete"
                      onClick={() => removeFile(index)}
                      disabled={isClassLoading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Box>

        {embeddingChoice === "combined" && (
          <Box mt={2}>
            <Button 
              variant="contained" 
              color="secondary"
              onClick={generateExplanation}
              disabled={files.length === 0 || loadingDescription}
              className="generate-explanation-button"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#550FCC",
                '&:hover': {
                  backgroundColor: "#8338ec",
                },
              }}
            >
              {loadingDescription ? <CircularProgress size={24} /> : "Generate Explanation"}
            </Button>
            {isDescriptionGenerated && (
              <Typography mt={2} variant="body1" color="textSecondary">
                Description generated! You can modify it below.
              </Typography>
            )}
          </Box>
        )}

        <TextField
          fullWidth
          label="Explanation / Reasoning"
          variant="outlined"
          value={reasoning}
          onChange={(e) => setReasoning(e.target.value)}
          margin="normal"
          multiline
          rows={4}
          InputProps={{
            sx: { borderRadius: "8px" },
          }}
        />

        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={addClass}
            disabled={isClassLoading}
            className="add-class-button"
            sx={{
              padding: "10px 20px",
              borderRadius: "8px",
              backgroundColor: "#550FCC",
              '&:hover': {
                backgroundColor: "#8338ec",
              },
            }}
          >
            {isClassLoading ? <CircularProgress size={24} /> : "Add Class"}
          </Button>
        </Box>

        {uploadProgress.total && (
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              Total Upload Progress:
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={uploadProgress.total} 
              sx={{ mt: 1 }}
            />
          </Box>
        )}

        {isClassAdded && (
          <Box mt={4}>
            <Alert severity="success">
              Class added successfully! Add another class or proceed to prediction.
            </Alert>

            <Box mt={2} display="flex" gap={2} justifyContent="center">
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => {
                  setIsClassAdded(false);
                  setFiles([]);
                  setClassName("");
                  setReasoning("");
                  setIsDescriptionGenerated(false);
                }} 
                sx={{ borderRadius: "8px" }}
              >
                Add Another Class
              </Button>

              <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => navigate(modelType === "video" ? "/predict-video" : "/predict")} 
                sx={{ borderRadius: "8px" }}
              >
                Proceed to Predict
              </Button>
            </Box>
          </Box>
        )}
      </Paper>

      {showExplanatoryCard && (
        <Card sx={{
          width: "90%",
          maxWidth: "800px",
          margin: "2rem auto",
          padding: "2rem",
          borderRadius: "12px",
          backgroundColor: "#f9f9f9",
          position: "relative",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}>
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: "center" }}>
            {modelType === "video" ? <VideoFileIcon sx={{ fontSize: "3rem", color: "#550FCC" }} /> : 
             <AddIcon sx={{ fontSize: "3rem", color: "#550FCC" }} />}
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
              Why add a {modelType} class?
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
              {modelType === "video" 
                ? "Adding a video class allows your model to learn and recognize different types of video content. Each class represents a category that your model will use to classify videos. Upload multiple example videos to help the model better understand the characteristics of this class."
                : "Adding a class allows your model to learn and recognize different categories or objects. Each class represents a label or category that your model will use to make predictions based on the data provided. By adding meaningful classes, you are enhancing your model's ability to understand and categorize images more effectively."}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default AddClass;