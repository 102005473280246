import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Chip,
  Fade,
  Zoom,
  Slide,
  IconButton,
  Paper,
  Tooltip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  Divider,
  InputAdornment,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  alpha,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Switch,
} from "@mui/material";

// Icons
import {
  AddCircle as AddCircleIcon,
  CloudUpload as CloudUploadIcon,
  VideoLibrary as VideoLibraryIcon,
  Create as CreateIcon,
  AutoAwesome as AutoAwesomeIcon,
  Tag as TagIcon,
  Send as SendIcon,
  SmartToy as SmartToyIcon,
  Close as CloseIcon,
  Folder as FolderIcon,
  FolderOpen as FolderOpenIcon,
  SaveAlt as SaveAltIcon,
  Refresh as RefreshIcon,
  Info as InfoIcon,
  MoreVert as MoreVertIcon,
  Palette as PaletteIcon,
  EmojiObjects as EmojiObjectsIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  KeyboardCommandKey as KeyboardCommandKeyIcon,
  UploadFile as UploadFileIcon,
  FilterNone as FilterNoneIcon,
  VideoFile as VideoFileIcon,
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
} from "@mui/icons-material";

import api from "../api";
import FolderSelector from './FolderSelector';
import FolderBreadcrumb from './FolderBreadcrumb';
import FolderActions from './FolderActions';

const ClassifyAndSaveVideos = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const fileInputRef = useRef(null);
  
  // State for classified videos
  const [files, setFiles] = useState([]);
  const [externalVideos, setExternalVideos] = useState([]);
  const [classSuggestions, setClassSuggestions] = useState([]);
  const [isClassified, setIsClassified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processingStage, setProcessingStage] = useState(""); // For detailed processing feedback
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [videoTags, setVideoTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedModelId, setSelectedModelId] = useState("");
  const [tagPrompt, setTagPrompt] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [showAIChat, setShowAIChat] = useState(false);
  const [aiChatMessages, setAiChatMessages] = useState([
    { from: 'ai', message: 'Hi! I can help classify and tag your videos. Upload some videos to get started or ask me a question.' }
  ]);
  const [aiChatInput, setAiChatInput] = useState("");
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [showCommandPalette, setShowCommandPalette] = useState(false);
  const [commandInput, setCommandInput] = useState("");
  const [folderMenuAnchor, setFolderMenuAnchor] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [allFolders, setAllFolders] = useState([]);
  const [showTagsDialog, setShowTagsDialog] = useState(false);
  const [activeVideoForTags, setActiveVideoForTags] = useState(null);
  const [newTag, setNewTag] = useState("");
  const [userClasses, setUserClasses] = useState([]);
  const [commonClass, setCommonClass] = useState("");
  const [annotations, setAnnotations] = useState("");

  // Advanced states for AI-native experience
  const [aiSuggestions, setAiSuggestions] = useState([]);
  const [confidenceVisualization, setConfidenceVisualization] = useState(false);
  const [batchProcessingProgress, setBatchProcessingProgress] = useState(0);
  const [autoClassifyOnUpload, setAutoClassifyOnUpload] = useState(false);
  const [showFeaturesDialog, setShowFeaturesDialog] = useState(false);
  const [tagCount, setTagCount] = useState(5);
  const [generatingTags, setGeneratingTags] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [folderError, setFolderError] = useState('');
  const [folderLoading, setFolderLoading] = useState(false);
  
  // State for create folder dialog
  const [showCreateFolderDialog, setShowCreateFolderDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [newFolderParentId, setNewFolderParentId] = useState(null);
  const [creatingFolder, setCreatingFolder] = useState(false);
  
  const chatEndRef = useRef(null);

  useEffect(() => {
    // This effect scrolls the chat to the bottom when new messages are added
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [aiChatMessages]);

  useEffect(() => {
    const initialize = async () => {
      await fetchFolders();
      await fetchModels();

      if (location.state?.videos) {
        setExternalVideos(location.state.videos);
        if (autoClassifyOnUpload) {
          // Auto-classify external videos
          setTimeout(() => classifyVideos(location.state.videos), 500);
        }
      }

      if (location.state?.selectedModelId) {
        setSelectedModelId(location.state.selectedModelId);
      }

      // Check for keyboard shortcut
      document.addEventListener('keydown', handleKeyDown);
    };

    initialize();

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      // Clean up preview URLs on component unmount
      previewUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [location.state]);

  const handleKeyDown = (e) => {
    // Command palette shortcut (Cmd+K or Ctrl+K)
    if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
      e.preventDefault();
      setShowCommandPalette(true);
    }
  };

  const fetchModels = async () => {
    try {
      const response = await api.get("/video-models");
      setModels(response.data);
      
      // Auto-select the best model (in a real implementation, 
      // this would intelligently choose based on content)
      if (response.data.length > 0) {
        // Find a model that might be good for general purpose classification
        const generalModel = response.data.find(model => 
          model.name.toLowerCase().includes('general') || 
          model.name.toLowerCase().includes('default')
        );
        
        if (generalModel) {
          setSelectedModelId(generalModel.id);
          
          // Add an AI suggestion
          setAiSuggestions(prev => [
            ...prev, 
            {
              type: 'model',
              message: `I've selected the ${generalModel.name} model which works well for general video classification.`,
              action: () => {}, // Already applied
              actionText: 'Using this model'
            }
          ]);
        }
      }
    } catch (error) {
      console.error("Failed to fetch models", error);
      setError("Failed to fetch available models");
    }
  };

  const fetchFolders = async () => {
    try {
      setFolderLoading(true);
      const response = await api.get('/folders', {
        params: { recursive: true }
      });
      const foldersData = response.data.folders || [];
      setAllFolders(foldersData);

      if (selectedFolder) {
        const updatedFolder = foldersData.find(f => f.id === selectedFolder.id);
        if (updatedFolder) {
          setSelectedFolder(updatedFolder);
        }
      }

      return foldersData;
    } catch (error) {
      console.error('Error fetching folders:', error);
      setFolderError('Failed to fetch folders');
      return [];
    } finally {
      setFolderLoading(false);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFiles(Array.from(e.dataTransfer.files));
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFiles(Array.from(e.target.files));
    }
  };

  const handleFiles = (newFiles) => {
    // Filter to only include video files
    const videoFiles = newFiles.filter(file => file.type.includes('video/'));
    
    if (videoFiles.length === 0) {
      setError("Please select video files only");
      return;
    }
    
    // Clean up existing preview URLs
    previewUrls.forEach(url => URL.revokeObjectURL(url));
    
    // Create new preview URLs
    const urls = videoFiles.map(file => URL.createObjectURL(file));
    setPreviewUrls(urls);
    
    setFiles(videoFiles);
    setExternalVideos([]);
    resetClassificationStates();
    
    // Add message to AI chat
    setAiChatMessages(prev => [
      ...prev,
      { from: 'user', message: `I've uploaded ${videoFiles.length} video${videoFiles.length > 1 ? 's' : ''}.` },
      { 
        from: 'ai', 
        message: `Great! I see ${videoFiles.length} video${videoFiles.length > 1 ? 's' : ''}. ${
          autoClassifyOnUpload 
            ? "I'll classify them for you automatically." 
            : "You can adjust the model and tag options above, then click 'Analyze with AI' when ready."
        }`
      }
    ]);
    
    if (autoClassifyOnUpload) {
      // Auto-classify with slight delay for better UX
      setTimeout(() => classifyVideos(videoFiles), 800);
    } else {
      // Suggest classification
      setAiSuggestions(prev => [
        ...prev,
        {
          type: 'action',
          message: 'Would you like me to classify these videos now?',
          action: () => classifyVideos(videoFiles),
          actionText: 'Classify Videos'
        }
      ]);
    }
  };

  const resetClassificationStates = () => {
    setClassSuggestions([]);
    setIsClassified(false);
    setSelectedVideos([]);
    setUserClasses([]);
    setCommonClass("");
    setVideoTags([]);
    setNewTags([]);
    setAnnotations("");
  };

  const getFolderBreadcrumbPath = (folder, allFoldersList) => {
    const path = [];
    let current = folder;
    while (current) {
      path.unshift({
        id: current.id,
        name: current.name
      });
      current = allFoldersList.find(f => f.id === current.parent_folder_id);
    }
    return path;
  };

  const classifyVideos = async (videosToClassify = null) => {
    setLoading(true);
    setError("");
    setProcessingStage("initializing");
    setBatchProcessingProgress(0);
    setUploadProgress(0);
    
    // Add message to AI chat
    setAiChatMessages(prev => [
      ...prev,
      { from: 'ai', message: "I'm analyzing your videos now. This will take a moment..." }
    ]);

    try {
      let response;
      
      if (!videosToClassify) {
        videosToClassify = externalVideos.length > 0 ? externalVideos : files;
      }
      
      if (typeof videosToClassify[0] === 'string') {
        // These are external video URLs
        setProcessingStage("analyzing");
        
        const data = {
          video_urls: videosToClassify,
          ...(selectedModelId && { model_id: selectedModelId }),
          tag_count: tagCount,
          tag_prompt: tagPrompt || undefined
        };

        response = await api.post("/videos/classify", data, {
          headers: {
            "Content-Type": "application/json",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setBatchProcessingProgress(percentCompleted);
          }
        });
      } else {
        // These are file objects
        setProcessingStage("uploading");
        
        const formData = new FormData();
        
        for (const file of videosToClassify) {
          formData.append("files", file);
        }
        
        if (selectedModelId) {
          formData.append("model_id", selectedModelId);
        }
        
        formData.append("tag_count", tagCount.toString());
        if (tagPrompt) {
          formData.append("tag_prompt", tagPrompt);
        }

        response = await api.post("/videos/classify", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
            setBatchProcessingProgress(percentCompleted);
          }
        });
      }
      
      // Process results
      setProcessingStage("finalizing");
      setClassSuggestions(response.data.classification_results);
      setIsClassified(true);
      
      const resultsLength = videosToClassify.length;
      setUserClasses(new Array(resultsLength).fill(""));
      
      // Set video tags from the suggestions
      setVideoTags(response.data.classification_results.map(result => 
        result.suggested_tags || []
      ));
      
      setNewTags(new Array(resultsLength).fill(""));

      // Group similar videos based on AI classification
      const classGroups = {};
      response.data.classification_results.forEach((result, index) => {
        const className = result.suggested_class || "Unclassified";
        if (!classGroups[className]) {
          classGroups[className] = [];
        }
        classGroups[className].push(index);
      });
      
      // Add insights to chat
      const classCount = Object.keys(classGroups).length;
      let aiMessage = `I've analyzed your videos and found ${classCount} main ${
        classCount === 1 ? 'category' : 'categories'
      }: `;
      
      Object.keys(classGroups).forEach((className, idx) => {
        const count = classGroups[className].length;
        aiMessage += `${className} (${count} ${count === 1 ? 'video' : 'videos'})`;
        
        if (idx < Object.keys(classGroups).length - 2) {
          aiMessage += ', ';
        } else if (idx === Object.keys(classGroups).length - 2) {
          aiMessage += ' and ';
        }
      });
      
      aiMessage += '. You can now add custom tags, organize them into folders, or save them directly.';
      
      setAiChatMessages(prev => [
        ...prev,
        { from: 'ai', message: aiMessage }
      ]);
      
    } catch (error) {
      console.error("Classification Error:", error);
      setError("Error classifying videos: " + (error.response?.data?.detail || error.message));
      
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I encountered an error while analyzing your videos. ${error.response?.data?.detail || error.message}. Please try again or upload different videos.` 
        }
      ]);
    } finally {
      setLoading(false);
      setProcessingStage("");
      setBatchProcessingProgress(0);
      setUploadProgress(0);
    }
  };

  const saveVideos = async () => {
    // Determine which videos to save
    const videosToSave = selectedVideos.length > 0 
      ? selectedVideos
      : Array.from({ length: classSuggestions.length }, (_, i) => i);
      
    if (videosToSave.length === 0) {
      setError("Please select at least one video to save");
      return;
    }
    
    setLoading(true);
    setError("");
    setFolderError("");
    setProcessingStage("saving");
    setUploadProgress(0);
    
    try {
      if (externalVideos.length > 0) {
        // Save external videos
        const filteredUrls = videosToSave.map(idx => externalVideos[idx]);
        const filteredClasses = videosToSave.map(
          idx => userClasses[idx] || commonClass || classSuggestions[idx].suggested_class
        );
        const filteredTags = videosToSave.map(
          idx => videoTags[idx] || []
        );
        
        const data = {
          video_urls: filteredUrls,
          common_class: commonClass,
          annotations: annotations,
          class_names: filteredClasses,
          suggested_tags: filteredTags,
          ...(selectedFolder?.id && { folder_id: selectedFolder.id })
        };

        await api.post("/videos/save", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        // Save uploaded files
        const formData = new FormData();
        
        // Add common class and folder
        formData.append("common_class", commonClass || "");
        formData.append("annotations", annotations || "");
        if (selectedFolder?.id) {
          formData.append("folder_id", selectedFolder.id);
        }
        
        // Add each file with its class
        videosToSave.forEach(idx => {
          const className = userClasses[idx] || commonClass || classSuggestions[idx].suggested_class;
          formData.append("files", files[idx]);
          formData.append("class_names", className);
        });
        
        // Add tags
        formData.append("suggested_tags_json", JSON.stringify(videosToSave.map(idx => 
          videoTags[idx] || []
        )));

        await api.post("/videos/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          }
        });
      }

      setSuccess(`Successfully saved ${videosToSave.length} videos`);
      
      // Add message to AI chat
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I've saved ${videosToSave.length} videos${
            selectedFolder 
              ? ` to the "${selectedFolder.name}" folder` 
              : ''
          }. Is there anything else you'd like to do?` 
        }
      ]);
      
      // Reset states
      resetClassificationStates();
      setFiles([]);
      setExternalVideos([]);
      setSelectedFolder(null);
      
      // Clean up preview URLs
      previewUrls.forEach(url => URL.revokeObjectURL(url));
      setPreviewUrls([]);
      
    } catch (error) {
      console.error("Save Videos Error:", error);
      
      if (error.response?.data?.detail?.includes('folder')) {
        setFolderError(error.response.data.detail);
      } else {
        setError("Error saving videos: " + (error.response?.data?.detail || error.message));
      }
      
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I encountered an error while saving your videos: ${error.response?.data?.detail || error.message}` 
        }
      ]);
    } finally {
      setLoading(false);
      setProcessingStage("");
      setUploadProgress(0);
    }
  };

  const generateTagsForAllVideos = async () => {
    if (!isClassified || classSuggestions.length === 0) return;
    
    setGeneratingTags(true);
    setError("");
    
    try {
      // Add message to chat
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I'm generating additional tags for all videos using the prompt: "${tagPrompt || 'general video features'}"...` 
        }
      ]);
      
      // For external videos, we can use the URLs directly
      if (externalVideos.length > 0) {
        const requestData = {
          video_urls: externalVideos,
          tag_count: tagCount,
          tag_prompt: tagPrompt,
          existing_tags: videoTags,
          exclude_existing: true
        };
        
        const response = await api.post("/videos/generate-tags", requestData);
        
        if (response.data && response.data.results) {
          const updatedVideoTags = [...videoTags];
          
          response.data.results.forEach((result, index) => {
            if (result.generated_tags && result.generated_tags.length > 0) {
              updatedVideoTags[index] = [
                ...updatedVideoTags[index],
                ...result.generated_tags
              ];
            }
          });
          
          setVideoTags(updatedVideoTags);
          
          // Add success message to chat
          setAiChatMessages(prev => [
            ...prev,
            { 
              from: 'ai', 
              message: `I've added new tags to all videos based on your criteria.` 
            }
          ]);
        }
      } 
      // For local files, we need to send the files directly
      else {
        const formData = new FormData();
        
        for (const file of files) {
          formData.append("files", file);
        }
        
        formData.append("tag_count", tagCount.toString());
        formData.append("tag_prompt", tagPrompt || "");
        formData.append("existing_tags_json", JSON.stringify(videoTags));
        formData.append("exclude_existing", "true");
        
        const response = await api.post("/videos/generate-tags-for-files", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        
        if (response.data && response.data.results) {
          const updatedVideoTags = [...videoTags];
          
          response.data.results.forEach((result, index) => {
            if (result.generated_tags && result.generated_tags.length > 0) {
              updatedVideoTags[index] = [
                ...updatedVideoTags[index],
                ...result.generated_tags
              ];
            }
          });
          
          setVideoTags(updatedVideoTags);
          
          // Add success message to chat
          setAiChatMessages(prev => [
            ...prev,
            { 
              from: 'ai', 
              message: `I've added new tags to all videos based on your criteria.` 
            }
          ]);
        }
      }
    } catch (error) {
      console.error("Error generating tags for all videos:", error);
      setError("Failed to generate tags: " + (error.response?.data?.detail || error.message));
      
      // Add error message to chat
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I encountered an error while generating tags: ${error.response?.data?.detail || error.message}` 
        }
      ]);
    } finally {
      setGeneratingTags(false);
    }
  };

  const generateCustomTags = async (index) => {
    if (!isClassified || !classSuggestions[index]) return;
    
    setGeneratingTags(true);
    setError("");
    
    try {
      if (externalVideos.length > 0) {
        // For external videos, we can use the URL directly
        const requestData = {
          video_urls: [externalVideos[index]],
          tag_count: tagCount,
          tag_prompt: tagPrompt,
          existing_tags: [videoTags[index] || []],
          exclude_existing: true
        };
        
        const response = await api.post("/videos/generate-tags", requestData);
        
        if (response.data && response.data.results && response.data.results.length > 0) {
          const generatedTags = response.data.results[0].generated_tags || [];
          
          // Add new tags to the current video's tags
          const updatedVideoTags = [...videoTags];
          updatedVideoTags[index] = [...updatedVideoTags[index], ...generatedTags];
          setVideoTags(updatedVideoTags);
          
          // Add message to chat
          setAiChatMessages(prev => [
            ...prev,
            { 
              from: 'ai', 
              message: `I've generated ${generatedTags.length} additional tags for this video.` 
            }
          ]);
        }
      } else {
        // For local files, create a FormData and send the file directly
        const formData = new FormData();
        formData.append("file", files[index]);
        formData.append("tag_count", tagCount.toString());
        formData.append("tag_prompt", tagPrompt || "");
        formData.append("existing_tags_json", JSON.stringify(videoTags[index] || []));
        formData.append("exclude_existing", "true");
        
        const response = await api.post("/videos/generate-tags-for-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        
        if (response.data && response.data.generated_tags) {
          const generatedTags = response.data.generated_tags || [];
          
          // Add new tags to the current video's tags
          const updatedVideoTags = [...videoTags];
          updatedVideoTags[index] = [...updatedVideoTags[index], ...generatedTags];
          setVideoTags(updatedVideoTags);
          
          // Add message to chat
          setAiChatMessages(prev => [
            ...prev,
            { 
              from: 'ai', 
              message: `I've generated ${generatedTags.length} additional tags for this video.` 
            }
          ]);
        }
      }
    } catch (error) {
      console.error("Error generating custom tags:", error);
      setError("Failed to generate custom tags: " + (error.response?.data?.detail || error.message));
    } finally {
      setGeneratingTags(false);
    }
  };

  const handleSelectFolder = (folder) => {
    setSelectedFolder(folder);
    setFolderMenuAnchor(null);
    
    // Add message to chat
    setAiChatMessages(prev => [
      ...prev,
      { 
        from: 'user', 
        message: `I want to save to the "${folder.name}" folder.` 
      },
      { 
        from: 'ai', 
        message: `I've selected the "${folder.name}" folder for saving your videos.` 
      }
    ]);
  };

  const handleVideoSelection = (index) => {
    setSelectedVideos(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handleVideoTagClick = (index) => {
    setActiveVideoForTags(index);
    setShowTagsDialog(true);
  };

  const handleAddTag = () => {
    if (!newTag.trim() || activeVideoForTags === null) return;
    
    const updatedTags = [...videoTags];
    
    // Initialize the array if it's undefined
    if (!updatedTags[activeVideoForTags]) {
      updatedTags[activeVideoForTags] = [];
    }
    
    const videoCurrTags = updatedTags[activeVideoForTags];
    
    if (!videoCurrTags.includes(newTag.trim())) {
      updatedTags[activeVideoForTags] = [
        ...videoCurrTags,
        newTag.trim()
      ];
      
      setVideoTags(updatedTags);
      setNewTag("");
      
      console.log("Added tag:", newTag.trim(), "to video", activeVideoForTags);
      console.log("Updated tags:", updatedTags);
    }
  };

  const handleRemoveTag = (index, tag) => {
    const updatedTags = [...videoTags];
    updatedTags[index] = updatedTags[index].filter(
      t => t !== tag
    );
    setVideoTags(updatedTags);
  };

  const handleAddVideoTag = (index) => {
    const tag = newTags[index].trim();
    if (tag && !videoTags[index].includes(tag)) {
      const updatedVideoTags = [...videoTags];
      updatedVideoTags[index].push(tag);
      setVideoTags(updatedVideoTags);

      const updatedNewTags = [...newTags];
      updatedNewTags[index] = "";
      setNewTags(updatedNewTags);
    }
  };

  const handleFolderClick = (event) => {
    setFolderMenuAnchor(event.currentTarget);
  };

  const handleSendAiChat = () => {
    if (!aiChatInput.trim()) return;
    
    // Add user message to chat
    setAiChatMessages(prev => [
      ...prev, 
      { from: 'user', message: aiChatInput }
    ]);
    
    // Process the command (in a real implementation, this would
    // parse natural language and perform actions)
    processAiCommand(aiChatInput);
    
    setAiChatInput("");
  };

  const processAiCommand = (command) => {
    const normalizedCommand = command.toLowerCase();
    
    // Simple example of command processing
    if (normalizedCommand.includes('classify') || normalizedCommand.includes('analyze')) {
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { from: 'ai', message: 'I\'ll classify your videos right away.' }
        ]);
        classifyVideos();
      }, 500);
    }
    else if (normalizedCommand.includes('save')) {
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { from: 'ai', message: 'I\'ll save your videos now.' }
        ]);
        saveVideos();
      }, 500);
    }
    else if (normalizedCommand.includes('folder') || normalizedCommand.includes('directory')) {
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { from: 'ai', message: 'Would you like to select a folder for saving your videos?' }
        ]);
        
        setAiSuggestions(prev => [
          ...prev,
          {
            type: 'folder',
            message: 'Choose a folder for your videos:',
            action: (e) => handleFolderClick(e),
            actionText: 'Select folder'
          }
        ]);
      }, 500);
    }
    else if (normalizedCommand.includes('tag') || normalizedCommand.includes('label')) {
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { from: 'ai', message: 'You can add custom tags to any video by clicking on the tag icon. Would you like me to help with tagging?' }
        ]);
      }, 500);
    }
    else {
      // Generic response for unrecognized commands
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { 
            from: 'ai', 
            message: "I understand you want to do something with your videos. You can ask me to classify them, suggest tags, save them, or select a folder." 
          }
        ]);
      }, 500);
    }
  };

  const executeCommand = (command) => {
    setShowCommandPalette(false);
    
    switch(command) {
      case 'classify':
        classifyVideos();
        break;
      case 'save':
        saveVideos();
        break;
      case 'selectFolder':
        handleFolderClick({ currentTarget: document.querySelector('#folderButton') });
        break;
      case 'createFolder':
        setShowCreateFolderDialog(true);
        break;
      case 'clearAll':
        setFiles([]);
        setExternalVideos([]);
        resetClassificationStates();
        previewUrls.forEach(url => URL.revokeObjectURL(url));
        setPreviewUrls([]);
        break;
      case 'toggleChat':
        setShowAIChat(prev => !prev);
        break;
      case 'toggleConfidence':
        setConfidenceVisualization(prev => !prev);
        break;
      case 'generateTags':
        generateTagsForAllVideos();
        break;
      default:
        break;
    }
  };

  // Filtered commands for the command palette
  const getFilteredCommands = () => {
    const commands = [
      { id: 'classify', name: 'Classify Videos', icon: <AutoAwesomeIcon /> },
      { id: 'save', name: 'Save Videos', icon: <SaveAltIcon /> },
      { id: 'selectFolder', name: 'Select Folder', icon: <FolderIcon /> },
      { id: 'createFolder', name: 'Create New Folder', icon: <CreateIcon /> },
      { id: 'clearAll', name: 'Clear All', icon: <DeleteIcon /> },
      { id: 'toggleChat', name: `${showAIChat ? 'Hide' : 'Show'} AI Chat`, icon: <SmartToyIcon /> },
      { id: 'toggleConfidence', name: `${confidenceVisualization ? 'Hide' : 'Show'} Confidence Scores`, icon: <InfoIcon /> },
      { id: 'generateTags', name: 'Generate Tags for All Videos', icon: <TagIcon /> },
    ];
    
    if (!commandInput) return commands;
    
    return commands.filter(cmd => 
      cmd.name.toLowerCase().includes(commandInput.toLowerCase())
    );
  };
  
  // Create a new folder
  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;
    
    setCreatingFolder(true);
    try {
      const folderData = {
        name: newFolderName.trim(),
        parent_folder_id: newFolderParentId
      };
      
      const response = await api.post('/folders', folderData);
      
      // Close dialog and reset form
      setShowCreateFolderDialog(false);
      setNewFolderName("");
      setNewFolderParentId(null);
      
      // Refresh folders
      await fetchFolders();
      
      // Select the newly created folder
      if (response.data && response.data.id) {
        handleSelectFolder(response.data);
      }
      
      // Show success message
      setSuccess(`Folder "${newFolderName.trim()}" created successfully`);
      
    } catch (error) {
      console.error('Error creating folder:', error);
      setError('Failed to create folder: ' + (error.response?.data?.detail || error.message));
    } finally {
      setCreatingFolder(false);
    }
  };

  // Render processing state visualization based on current stage
  const renderProcessingState = () => {
    return (
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column' 
        }}
        open={loading}
      >
        <Box sx={{ maxWidth: 400, textAlign: 'center' }}>
          <Box sx={{ position: 'relative', width: 200, height: 200, margin: '0 auto' }}>
            <CircularProgress 
              size={120} 
              thickness={3} 
              sx={{ 
                color: '#fff',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-60px',
                marginLeft: '-60px',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-40px',
                marginLeft: '-40px',
                width: 80,
                height: 80,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {processingStage === "initializing" && <FilterNoneIcon sx={{ fontSize: 40 }} />}
              {processingStage === "uploading" && <CloudUploadIcon sx={{ fontSize: 40 }} />}
              {processingStage === "analyzing" && <AutoAwesomeIcon sx={{ fontSize: 40 }} />}
              {processingStage === "finalizing" && <CheckCircleIcon sx={{ fontSize: 40 }} />}
              {processingStage === "saving" && <SaveAltIcon sx={{ fontSize: 40 }} />}
            </Box>
          </Box>
          
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'medium' }}>
            {processingStage === "initializing" && "Initializing AI..."}
            {processingStage === "uploading" && "Uploading Videos..."}
            {processingStage === "analyzing" && "AI Analyzing Videos..."}
            {processingStage === "finalizing" && "Finalizing Results..."}
            {processingStage === "saving" && "Saving Videos..."}
          </Typography>
          
          {(batchProcessingProgress > 0 || uploadProgress > 0) && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress 
                variant="determinate" 
                value={batchProcessingProgress || uploadProgress} 
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: alpha('#fff', 0.2),
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: '#fff'
                  }
                }}
              />
              <Typography variant="body2" sx={{ mt: 1, color: 'rgba(255,255,255,0.8)' }}>
                {batchProcessingProgress || uploadProgress}% Complete
              </Typography>
            </Box>
          )}
        </Box>
      </Backdrop>
    );
  };

  // Main upload area when no videos are present
  const renderUploadArea = () => {
    return (
      <Box
        sx={{
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: dragActive ? '3px dashed #550FCC' : '3px dashed #ccc',
          borderRadius: 4,
          p: 4,
          m: 2,
          backgroundColor: dragActive ? alpha('#550FCC', 0.05) : 'transparent',
          transition: 'all 0.3s ease'
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <VideoFileIcon 
          sx={{ 
            fontSize: 80, 
            color: dragActive ? '#550FCC' : '#aaa',
            transition: 'all 0.3s ease',
            animation: dragActive ? 'pulse 1.5s infinite' : 'none',
            '@keyframes pulse': {
              '0%': {
                transform: 'scale(1)',
                opacity: 0.7,
              },
              '50%': {
                transform: 'scale(1.05)',
                opacity: 1,
              },
              '100%': {
                transform: 'scale(1)',
                opacity: 0.7,
              },
            },
          }} 
        />
        
        <Typography variant="h5" sx={{ mt: 2, mb: 1, fontWeight: 'medium' }}>
          Drag & drop videos here
        </Typography>
        
        <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
          Or click to browse your files
        </Typography>
        
        <Button
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onClick={() => fileInputRef.current.click()}
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: 3,
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 'medium',
            boxShadow: 3,
            backgroundColor: '#550FCC',
            '&:hover': {
              backgroundColor: '#4700B3'
            }
          }}
        >
          Upload Videos
        </Button>
        
        <input
          type="file"
          multiple
          accept="video/*"
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: 'none' }}
        />
      </Box>
    );
  };

  // Gallery view of classified videos
  const renderClassifiedVideos = () => {
    return (
      <Box sx={{ my: 4 }}>
        <Grid container spacing={2}>
          {classSuggestions.map((suggestion, index) => {
            const isSelected = selectedVideos.includes(index);
            const videoUrl = externalVideos.length > 0 
              ? externalVideos[index] 
              : previewUrls[index];
              
            const confidence = suggestion.confidence || Math.random() * 0.5 + 0.5; // Fallback for demo
            
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Zoom in={true} style={{ transitionDelay: `${index * 100}ms` }}>
                  <Card 
                    sx={{ 
                      position: 'relative',
                      height: '100%',
                      cursor: 'pointer',
                      transform: isSelected ? 'scale(0.98)' : 'scale(1)',
                      transition: 'transform 0.2s ease',
                      outline: isSelected ? `3px solid ${theme.palette.primary.main}` : 'none',
                      '&:hover': {
                        boxShadow: 6
                      }
                    }}
                    onClick={() => handleVideoSelection(index)}
                  >
                    {isSelected && (
                      <CheckCircleIcon 
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          zIndex: 2,
                          color: theme.palette.primary.main,
                          backgroundColor: 'white',
                          borderRadius: '50%'
                        }}
                      />
                    )}
                    
                    <Box sx={{ position: 'relative' }}>
                      <CardMedia
                        component="video"
                        height="160"
                        image={videoUrl}
                        controls
                        sx={{ objectFit: 'cover' }}
                        onClick={(e) => e.stopPropagation()}
                      />
                      
                      {confidenceVisualization && (
                        <Box 
                          sx={{ 
                            position: 'absolute', 
                            bottom: 0, 
                            left: 0, 
                            right: 0,
                            height: 6,
                            background: `linear-gradient(90deg, 
                              ${confidence < 0.6 ? 'red' : confidence < 0.8 ? 'orange' : 'green'} 
                              ${confidence * 100}%, 
                              rgba(255,255,255,0.3) ${confidence * 100}%)`
                          }}
                        />
                      )}
                    </Box>
                    
                    <CardContent>
                      {/* Class name field - editable */}
                      <TextField
                        fullWidth
                        size="small"
                        variant="standard"
                        value={userClasses[index] || ""}
                        onChange={(e) => {
                          const newClasses = [...userClasses];
                          newClasses[index] = e.target.value;
                          setUserClasses(newClasses);
                        }}
                        placeholder={commonClass || suggestion.suggested_class || "Unclassified"}
                        onClick={(e) => e.stopPropagation()}
                        InputProps={{
                          disableUnderline: true,
                          style: { 
                            fontSize: '1rem',
                            fontWeight: 'bold'
                          }
                        }}
                        sx={{ mb: 1 }}
                      />
                      
                      {confidenceVisualization && suggestion.confidence && (
                        <Chip 
                          label={`${Math.round(suggestion.confidence * 100)}%`}
                          size="small"
                          color={suggestion.confidence < 0.6 ? 'error' : suggestion.confidence < 0.8 ? 'warning' : 'success'}
                          sx={{ mb: 1 }}
                        />
                      )}
                      
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                        {(videoTags[index] || []).slice(0, 3).map((tag, tagIdx) => (
                          <Chip 
                            key={tagIdx} 
                            label={tag}
                            size="small"
                            sx={{ fontWeight: 'normal', fontSize: '0.75rem' }}
                          />
                        ))}
                        
                        {(videoTags[index] || []).length > 3 && (
                          <Chip 
                            label={`+${(videoTags[index] || []).length - 3} more`}
                            size="small"
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleVideoTagClick(index);
                            }}
                            sx={{ fontWeight: 'normal', fontSize: '0.75rem', cursor: 'pointer' }}
                          />
                        )}
                      </Box>
                      
                      <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end', gap: 1 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<TagIcon fontSize="small" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleVideoTagClick(index);
                          }}
                          sx={{ borderRadius: 4, textTransform: 'none' }}
                        >
                          Edit Tags
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Zoom>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  // Create folder dialog
  const renderCreateFolderDialog = () => {
    return (
      <Dialog
        open={showCreateFolderDialog}
        onClose={() => setShowCreateFolderDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <TextField
              autoFocus
              label="Folder Name"
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              sx={{ mb: 3 }}
            />
            
            <FormControl fullWidth>
              <InputLabel id="parent-folder-label">Parent Folder</InputLabel>
              <Select
                labelId="parent-folder-label"
                value={newFolderParentId === null ? "" : newFolderParentId}
                label="Parent Folder"
                onChange={(e) => setNewFolderParentId(e.target.value === "" ? null : e.target.value)}
              >
                <MenuItem value="">Root</MenuItem>
                {allFolders.map((folder) => (
                  <MenuItem key={folder.id} value={folder.id}>
                    {folder.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select a parent folder or leave as Root
              </FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCreateFolderDialog(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleCreateFolder}
            disabled={!newFolderName.trim() || creatingFolder}
          >
            {creatingFolder ? (
              <>
                <CircularProgress size={16} sx={{ mr: 1 }} />
                Creating...
              </>
            ) : (
              'Create Folder'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // AI chat interface
  const renderAiChat = () => {
    return (
      <Slide direction="up" in={showAIChat} mountOnEnter unmountOnExit>
        <Paper 
          elevation={3}
          sx={{
            position: 'fixed',
            bottom: 0,
            right: 24,
            width: isMobile ? '90vw' : 350,
            height: 500,
            maxHeight: '80vh',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12
          }}
        >
          <Box sx={{ 
            backgroundColor: '#550FCC', 
            color: 'white',
            px: 2,
            py: 1.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SmartToyIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                AI Assistant
              </Typography>
            </Box>
            <IconButton color="inherit" onClick={() => setShowAIChat(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            p: 2,
            gap: 2
          }}>
            {aiChatMessages.map((message, idx) => (
              <Box 
                key={idx}
                sx={{
                  alignSelf: message.from === 'ai' ? 'flex-start' : 'flex-end',
                  maxWidth: '80%'
                }}
              >
                <Paper 
                  elevation={0}
                  sx={{
                    p: 1.5,
                    bgcolor: message.from === 'ai' ? '#f0f0f0' : '#e6f5ff',
                    borderRadius: 2,
                    borderTopLeftRadius: message.from === 'ai' ? 0 : 2,
                    borderTopRightRadius: message.from === 'ai' ? 2 : 0,
                  }}
                >
                  <Typography variant="body2">
                    {message.message}
                  </Typography>
                </Paper>
              </Box>
            ))}
            
            {aiSuggestions.map((suggestion, idx) => (
              <Box 
                key={`suggestion-${idx}`}
                sx={{
                  alignSelf: 'flex-start',
                  maxWidth: '80%'
                }}
              >
                <Paper 
                  elevation={0}
                  sx={{
                    p: 1.5,
                    bgcolor: '#f0f0f0',
                    borderRadius: 2,
                    borderTopLeftRadius: 0,
                  }}
                >
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {suggestion.message}
                  </Typography>
                  
                  <Button
                    variant="contained"
                    size="small"
                    onClick={(e) => suggestion.action(e)}
                    sx={{ 
                      textTransform: 'none',
                      fontWeight: 'normal',
                      boxShadow: 0
                    }}
                  >
                    {suggestion.actionText}
                  </Button>
                </Paper>
              </Box>
            ))}
            
            <div ref={chatEndRef} />
          </Box>
          
          <Box sx={{ 
            p: 2, 
            borderTop: '1px solid rgba(0,0,0,0.1)',
            display: 'flex',
            gap: 1
          }}>
            <TextField
              fullWidth
              placeholder="Ask me anything..."
              value={aiChatInput}
              onChange={(e) => setAiChatInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendAiChat();
                }
              }}
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton 
                      edge="end"
                      onClick={handleSendAiChat}
                      disabled={!aiChatInput.trim()}
                      color="primary"
                      size="small"
                    >
                      <SendIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 4,
                }
              }}
            />
          </Box>
        </Paper>
      </Slide>
    );
  };

  // Advanced tags dialog
  const renderTagsDialog = () => {
    if (activeVideoForTags === null) return null;
    
    // Make sure we're getting tags from the videoTags array, not classSuggestions
    const currVideoTags = videoTags[activeVideoForTags] || [];
    const videoUrl = externalVideos.length > 0 
      ? externalVideos[activeVideoForTags] 
      : files[activeVideoForTags] ? previewUrls[activeVideoForTags] : '';
    const videoClass = classSuggestions[activeVideoForTags]?.suggested_class || 'Unclassified';
      
    return (
      <Dialog 
        open={showTagsDialog} 
        onClose={() => setShowTagsDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Edit Tags</Typography>
            <IconButton onClick={() => setShowTagsDialog(false)} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        
        <DialogContent dividers>
          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <Box sx={{ width: 180, height: 100, position: 'relative', borderRadius: 2, overflow: 'hidden' }}>
              <video 
                src={videoUrl} 
                controls
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover'
                }}
              />
            </Box>
            
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {videoClass}
              </Typography>
              
              <Typography variant="body2" color="textSecondary">
                {externalVideos.length > 0 
                  ? `URL: ${externalVideos[activeVideoForTags]}` 
                  : `File: ${files[activeVideoForTags]?.name}`
                }
              </Typography>
            </Box>
          </Box>
          
          <Box sx={{ mb: 3 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AutoAwesomeIcon />}
              onClick={() => generateCustomTags(activeVideoForTags)}
              disabled={generatingTags}
              fullWidth
              sx={{ borderRadius: 2, mb: 1 }}
            >
              {generatingTags ? (
                <>
                  <CircularProgress size={16} sx={{ mr: 1 }} />
                  Generating Tags...
                </>
              ) : (
                "Generate Additional Tags"
              )}
            </Button>
            
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                size="small"
                label="Number of tags"
                type="number"
                value={tagCount}
                onChange={(e) => setTagCount(Math.max(1, parseInt(e.target.value) || 5))}
                inputProps={{ min: 1, max: 20 }}
                sx={{ width: '30%' }}
              />
              <TextField
                size="small"
                label="Custom prompt (optional)"
                value={tagPrompt}
                onChange={(e) => setTagPrompt(e.target.value)}
                sx={{ width: '70%' }}
              />
            </Box>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Current Tags:</Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
            {currVideoTags.length > 0 ? (
              currVideoTags.map((tag, idx) => (
                <Chip 
                  key={idx}
                  label={tag}
                  onDelete={() => handleRemoveTag(activeVideoForTags, tag)}
                  color="primary"
                  variant="outlined"
                />
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No tags yet. Add some below.
              </Typography>
            )}
          </Box>
          
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              fullWidth
              label="Add new tag"
              variant="outlined"
              size="small"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddTag();
                }
              }}
            />
            
            <Button 
              variant="contained"
              onClick={handleAddTag}
              disabled={!newTag.trim()}
            >
              Add
            </Button>
          </Box>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => setShowTagsDialog(false)}>Done</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Folder selection menu
  const renderFolderMenu = () => {
    return (
      <Menu
        anchorEl={folderMenuAnchor}
        open={Boolean(folderMenuAnchor)}
        onClose={() => setFolderMenuAnchor(null)}
        PaperProps={{
          style: {
            maxHeight: 400,
            width: 300,
          },
        }}
      >
        <MenuItem 
          sx={{ borderBottom: '1px solid', borderColor: 'divider', pb: 2 }}
          onClick={() => {
            setFolderMenuAnchor(null);
            setShowCreateFolderDialog(true);
          }}
        >
          <AddCircleIcon fontSize="small" sx={{ mr: 1, color: 'success.main' }} />
          Create New Folder
        </MenuItem>
        
        <MenuItem onClick={() => handleSelectFolder({ id: null, name: 'Root' })}>
          <FolderIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
          Root
        </MenuItem>
        
        {renderFolderItems(allFolders, null, 0)}
      </Menu>
    );
  };
  
  // Helper function to render folder items with proper hierarchy
  const renderFolderItems = (folders, parentId, depth) => {
    return folders
      .filter(folder => folder.parent_folder_id === parentId)
      .map(folder => (
        <React.Fragment key={folder.id}>
          <MenuItem 
            onClick={() => handleSelectFolder(folder)}
            sx={{ 
              pl: 2 + depth * 2, 
              py: 1,
              borderLeft: depth > 0 ? '1px dashed' : 'none',
              borderColor: 'divider',
              ml: depth > 0 ? 2 : 0
            }}
          >
            <FolderIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
            {folder.name}
          </MenuItem>
          {/* Recursively render children */}
          {renderFolderItems(folders, folder.id, depth + 1)}
        </React.Fragment>
      ));
  };

  // Command palette dialog
  const renderCommandPalette = () => {
    return (
      <Dialog
        open={showCommandPalette}
        onClose={() => setShowCommandPalette(false)}
        PaperProps={{
          sx: {
            width: 400,
            maxWidth: '90vw',
            borderRadius: 2,
            boxShadow: 24
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <KeyboardCommandKeyIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <TextField
              fullWidth
              autoFocus
              placeholder="Search for commands..."
              variant="standard"
              InputProps={{ disableUnderline: true }}
              value={commandInput}
              onChange={(e) => setCommandInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setShowCommandPalette(false);
                }
              }}
            />
          </Box>
          
          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
            {getFilteredCommands().map((command) => (
              <Box
                key={command.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1.5,
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'action.hover'
                  }
                }}
                onClick={() => executeCommand(command.id)}
              >
                <Box sx={{ mr: 2, color: 'text.secondary' }}>
                  {command.icon}
                </Box>
                <Typography variant="body1">{command.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Dialog>
    );
  };
  
  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 4, sm: 8 }, mb: 8 }}>
      {/* Global notifications */}
      <Snackbar 
        open={Boolean(error)} 
        autoHideDuration={6000} 
        onClose={() => setError("")}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError("")}>
          {error}
        </Alert>
      </Snackbar>
      
      <Snackbar 
        open={Boolean(success)} 
        autoHideDuration={6000} 
        onClose={() => setSuccess("")}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={() => setSuccess("")}>
          {success}
        </Alert>
      </Snackbar>
      
      {/* Header section */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        mb: 4
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <EmojiObjectsIcon 
            sx={{ 
              fontSize: 40, 
              color: '#550FCC',
              mr: 2
            }} 
          />
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold', 
              color: '#550FCC',
              fontSize: { xs: '1.5rem', sm: '2rem' }
            }}
          >
            Smart Video Manager
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1, mt: { xs: 2, sm: 0 } }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SmartToyIcon />}
            onClick={() => setShowAIChat(prev => !prev)}
            sx={{ borderRadius: 8, textTransform: 'none' }}
          >
            {showAIChat ? 'Hide Assistant' : 'AI Assistant'}
          </Button>
          
          <Tooltip title="Press ⌘K for commands">
            <IconButton 
              color="primary" 
              onClick={() => setShowCommandPalette(true)}
              sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 2 }}
            >
              <KeyboardCommandKeyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      
      {/* Model selection and tag options - Always visible */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3, 
          borderRadius: 3,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', gap: 3 }}>
          {/* AI Model Selection */}
          <Box sx={{ minWidth: '250px', flex: '1 1 20%' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <AutoAwesomeIcon sx={{ fontSize: 18, mr: 1 }} />
                AI Model Selection
              </Typography>
            </Box>
            
            <FormControl fullWidth size="small">
              <InputLabel id="model-select-label">Classification Model</InputLabel>
              <Select
                labelId="model-select-label"
                value={selectedModelId}
                label="Classification Model"
                onChange={(e) => setSelectedModelId(e.target.value)}
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="">
                  <em>Default Classification</em>
                </MenuItem>
                {models.map((model) => (
                  <MenuItem key={model.id} value={model.id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select a specific model for your content
              </FormHelperText>
            </FormControl>
          </Box>
          
          {/* Tag Generation Options */}
          <Box sx={{ flex: '1 1 40%', minWidth: '300px' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TagIcon sx={{ fontSize: 18, mr: 1 }} />
                Tag Generation Options
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                label="Number"
                variant="outlined"
                type="number"
                value={tagCount}
                onChange={(e) => setTagCount(Math.max(1, parseInt(e.target.value) || 5))}
                inputProps={{ min: 1, max: 20 }}
                size="small"
                sx={{ width: '100px' }}
              />
              <TextField
                fullWidth
                label="Custom tag prompt (optional)"
                variant="outlined"
                size="small"
                value={tagPrompt}
                onChange={(e) => setTagPrompt(e.target.value)}
                placeholder="E.g., Generate action tags, focus on content categories..."
              />
            </Box>
            <FormHelperText>
              Customize how tags are generated by AI
            </FormHelperText>
          </Box>
          
          {/* Auto-classify Toggle */}
          <Box sx={{ flex: '1 1 20%', minWidth: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ visibility: 'hidden', display: 'flex', alignItems: 'center', mb: 1 }}>
                <TagIcon sx={{ fontSize: 18, mr: 1 }} />
                Placeholder
              </Typography>
            </Box>
            
            <FormControl component="fieldset">
              <Typography variant="body1" sx={{ mb: 1 }}>
                Auto-classify on upload
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  checked={autoClassifyOnUpload}
                  onChange={(e) => setAutoClassifyOnUpload(e.target.checked)}
                  color="primary"
                />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {autoClassifyOnUpload ? 'On' : 'Off'}
                </Typography>
              </Box>
            </FormControl>
          </Box>
        </Box>
      </Paper>
      
      {/* Main content container */}
      <Paper 
        elevation={0} 
        sx={{ 
          borderRadius: 4, 
          overflow: 'hidden',
          border: '1px solid',
          borderColor: 'divider'
        }}
      >
        {/* Upload area or gallery */}
        {files.length === 0 && externalVideos.length === 0 ? (
          renderUploadArea()
        ) : (
          <>
            {/* Action bar */}
            <Box sx={{ 
              p: 2, 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'divider',
              flexWrap: 'wrap',
              gap: 1
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <VideoLibraryIcon sx={{ color: 'text.secondary' }} />
                <Typography variant="subtitle1">
                  {externalVideos.length > 0 
                    ? `${externalVideos.length} external videos` 
                    : `${files.length} uploaded videos`
                  }
                  {selectedVideos.length > 0 && ` (${selectedVideos.length} selected)`}
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {isClassified ? (
                  <>
                    <Button
                      id="folderButton"
                      variant="outlined"
                      startIcon={selectedFolder ? <FolderOpenIcon /> : <FolderIcon />}
                      onClick={handleFolderClick}
                      sx={{ borderRadius: 8 }}
                    >
                      {selectedFolder ? selectedFolder.name : 'Select Folder'}
                    </Button>
                    
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveAltIcon />}
                      onClick={saveVideos}
                      disabled={loading}
                      sx={{ 
                        borderRadius: 8,
                        backgroundColor: '#550FCC',
                        '&:hover': {
                          backgroundColor: '#4700B3'
                        }
                      }}
                    >
                      Save Videos
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AutoAwesomeIcon />}
                    onClick={() => classifyVideos()}
                    disabled={loading}
                    sx={{ 
                      borderRadius: 8,
                      backgroundColor: '#550FCC',
                      '&:hover': {
                        backgroundColor: '#4700B3'
                      }
                    }}
                  >
                    Analyze with AI
                  </Button>
                )}
                
                <IconButton
                  onClick={() => {
                    setFiles([]);
                    setExternalVideos([]);
                    resetClassificationStates();
                    // Clean up preview URLs
                    previewUrls.forEach(url => URL.revokeObjectURL(url));
                    setPreviewUrls([]);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            
            {/* Gallery content */}
            <Box sx={{ p: 2 }}>
              {isClassified ? (
                <>
                  {/* Batch Class and Tag Controls */}
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      mb: 3, 
                      p: 2,
                      borderRadius: 3,
                      border: '1px solid',
                      borderColor: theme.palette.primary.light,
                      backgroundColor: alpha(theme.palette.primary.light, 0.05)
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                        <AutoAwesomeIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                        Batch Operations
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 3 }}>
                      {/* Common Class Field */}
                      <TextField
                        label="Common class for all videos (optional)"
                        variant="outlined"
                        value={commonClass}
                        onChange={(e) => setCommonClass(e.target.value)}
                        fullWidth
                        size="small"
                        placeholder="Override classification for all videos"
                      />
                    </Box>
                    
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: { xs: 'column', sm: 'row' }, 
                      gap: 2,
                      alignItems: 'center'
                    }}>
                      <TextField
                        label="Tag prompt"
                        placeholder="E.g., 'Focus on actions' or 'Identify content themes'"
                        value={tagPrompt}
                        onChange={(e) => setTagPrompt(e.target.value)}
                        fullWidth
                        size="small"
                        sx={{ flexGrow: 1 }}
                      />
                      
                      <TextField
                        label="Tag count"
                        type="number"
                        value={tagCount}
                        onChange={(e) => setTagCount(Math.max(1, parseInt(e.target.value) || 5))}
                        inputProps={{ min: 1, max: 20 }}
                        size="small"
                        sx={{ width: { xs: '100%', sm: '150px' } }}
                      />
                      
                      <Button
                        variant="contained"
                        onClick={generateTagsForAllVideos}
                        disabled={generatingTags}
                        startIcon={generatingTags ? <CircularProgress size={16} /> : <AddCircleIcon />}
                        sx={{ 
                          borderRadius: 8,
                          backgroundColor: theme.palette.primary.main,
                          whiteSpace: 'nowrap',
                          minWidth: '160px',
                          px: 2,
                          '& .MuiButton-startIcon': {
                            mr: 1
                          }
                        }}
                      >
                        {generatingTags ? 'Generating...' : 'Generate Tags'}
                      </Button>
                    </Box>
                    
                    <Divider sx={{ my: 3 }} />
                    
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>Annotations</Typography>
                    <TextField
                      label="Add annotations for all videos"
                      value={annotations}
                      onChange={(e) => setAnnotations(e.target.value)}
                      multiline
                      rows={2}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Add notes, context, or additional information..."
                    />
                  </Paper>
                  
                  {renderClassifiedVideos()}
                </>
              ) : (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  flexDirection: 'column',
                  minHeight: '30vh',
                  p: 4
                }}>
                  <AutoAwesomeIcon sx={{ fontSize: 60, color: '#550FCC', mb: 2 }} />
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Ready to Analyze
                  </Typography>
                  <Typography variant="body1" color="textSecondary" align="center" sx={{ mb: 3, maxWidth: 600 }}>
                    Click "Analyze with AI" to classify your videos and generate tags. 
                    Our AI will automatically detect the content and suggest the most accurate categories.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AutoAwesomeIcon />}
                    onClick={() => classifyVideos()}
                    sx={{ 
                      borderRadius: 8,
                      px: 4,
                      backgroundColor: '#550FCC',
                      '&:hover': {
                        backgroundColor: '#4700B3'
                      }
                    }}
                  >
                    Analyze with AI
                  </Button>
                </Box>
              )}
            </Box>
          </>
        )}
      </Paper>
      
      {/* Additional elements */}
      {renderAiChat()}
      {renderProcessingState()}
      {renderTagsDialog()}
      {renderFolderMenu()}
      {renderCommandPalette()}
      {renderCreateFolderDialog()}
      
      {/* Folder browse section - rendered if folder is selected */}
      {selectedFolder && (
        <Paper elevation={0} sx={{ mt: 3, p: 3, borderRadius: 3, border: '1px solid', borderColor: 'divider' }}>
          <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 2 }}>
            Selected Destination Folder
          </Typography>
          
          <FolderBreadcrumb
            path={[
              { id: 'root', name: 'Root' },
              ...(selectedFolder ? getFolderBreadcrumbPath(selectedFolder, allFolders) : [])
            ]}
            onNavigate={(folder) => handleSelectFolder(folder)}
          />
          
          {folderError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {folderError}
            </Alert>
          )}
        </Paper>
      )}
    </Container>
  );
};

export default ClassifyAndSaveVideos;