import React, { useState, useEffect, useMemo, useRef, forwardRef } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  Paper,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
  IconButton,
  Chip,
  Collapse,
  Menu,
  Fade,
  Zoom,
  Grow,
  Tooltip,
  Avatar,
  alpha,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import TuneIcon from "@mui/icons-material/Tune";
import MemoryIcon from "@mui/icons-material/Memory";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import TagIcon from "@mui/icons-material/Tag";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  Folder,
  Move,
  Plus,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  Search,
  Zap,
  MessageSquare,
  Brain,
  Sparkles,
} from "lucide-react";

import FolderBreadcrumb from "./FolderBreadcrumb";
import FolderTree from "./FolderTree";
import FolderActions from "./FolderActions";
import FolderMenuItem from "./FolderMenuItem";
import EnhancedImageList from "./EnhancedImageList";

// =============== STYLED COMPONENTS FOR LAYOUT ===============

// Root container: full screen, flex layout
const RootContainer = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

// Top bar (fixed height, optional brand/heading)
const TopBar = styled("div")(({ theme }) => ({
  height: 56,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 2),
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.background.paper,
  zIndex: 1300,
}));

// Main content wrapper (split into sidebar and main content)
const ContentWrapper = styled("div")(() => ({
  flex: 1,
  display: "flex",
  overflow: "hidden",
}));

// Updated Sidebar container with container-based styling
const SidebarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  position: "relative",
}));

// The actual sidebar content (modified to accept a "collapsed" prop)
const Sidebar = styled("div")(({ theme, collapsed }) => ({
  width: collapsed ? 60 : 300,
  minWidth: collapsed ? 60 : 300,
  overflowY: "auto",
  padding: collapsed ? theme.spacing(1) : theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  backdropFilter: "blur(8px)",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
  borderRadius: "0 16px 16px 0",
  margin: "12px 0",
  position: "relative",
  zIndex: 10,
  transition: "width 0.3s ease, padding 0.3s ease",
}));

// Scrollable main content area with forwardRef to maintain scroll position
const MainContent = forwardRef(function MainContent(props, ref) {
  return (
    <div
      ref={ref}
      style={{
        flex: 1,
        overflowY: "auto",
        padding: "16px",
        position: "relative",
      }}
      {...props}
    />
  );
});

// Fixed bottom action bar (shown when images are selected)
const BottomActionBar = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  height: 56,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 2),
  boxShadow: theme.shadows[4],
  backgroundColor: theme.palette.background.paper,
  zIndex: 1300,
}));

// Simple styled Paper with reduced padding/margins
const AIPaper = styled(Paper)(({ theme }) => ({
  borderRadius: "8px",
  padding: theme.spacing(2),
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
  background: alpha(theme.palette.background.paper, 0.85),
  backdropFilter: "blur(10px)",
  transition: "all 0.3s ease",
  border: `1px solid ${alpha(theme.palette.divider, 0.05)}`,
  overflow: "hidden",
}));

// Minimal heading style
const SectionHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
}));

// Search Bar
const AISearchBar = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    padding: "4px 16px",
    transition: "all 0.3s ease",
    background: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: "blur(8px)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
    "&:hover": {
      boxShadow: "0 6px 24px rgba(0, 0, 0, 0.1)",
    },
    "&.Mui-focused": {
      boxShadow: `0 8px 28px ${alpha(theme.palette.primary.main, 0.2)}`,
      "& fieldset": {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
  },
  "& .MuiInputBase-input": {
    padding: "14px 14px",
  },
}));

// Search mode toggle
const AIToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(8px)",
  borderRadius: "12px",
  padding: "4px",
  "& .MuiToggleButton-root": {
    borderRadius: "8px",
    margin: "0 4px",
    border: "none",
    fontWeight: 500,
    padding: "8px 16px",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

// Animated icon in the search bar
const AnimatedSearchIcon = styled("div")(({ theme }) => ({
  width: "24px",
  height: "24px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": {
    position: "absolute",
    transition: "all 0.5s ease",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.2)",
      opacity: 0.7,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
  "&.searching svg": {
    animation: "pulse 1.5s infinite",
  },
}));

// Suggestions list
const SuggestionsList = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  zIndex: 1000,
  borderRadius: "12px",
  marginTop: "8px",
  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  animation: "fadeIn 0.3s forwards",
  "@keyframes fadeIn": {
    from: { opacity: 0, transform: "translateY(-10px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  },
}));

const SuggestionItem = styled(ListItem)(({ theme }) => ({
  transition: "all 0.2s ease",
  borderLeft: "0px solid transparent",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
  },
}));

// Filter chips
const AIFilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  borderRadius: "12px",
  fontWeight: 500,
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
  transition: "all 0.2s ease",
  "&:hover": {
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.12)",
    transform: "translateY(-2px)",
  },
  "&.active": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

// Simple AI badge
const AIBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "8px",
  right: "8px",
  padding: "4px 12px",
  borderRadius: "12px",
  fontSize: "12px",
  fontWeight: "bold",
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: "white",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
}));

// Folder card in the sidebar
const FolderCard = styled(Paper)(({ theme, isDragOver }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  borderRadius: "8px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
  transition: "all 0.3s ease",
  border: isDragOver
    ? `2px dashed ${theme.palette.primary.main}`
    : `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  background: isDragOver
    ? alpha(theme.palette.primary.main, 0.05)
    : alpha(theme.palette.background.paper, 0.6),
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.12)",
  },
}));

// AI assistant panel (bottom-right)
const AIAssistantPanel = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: theme.spacing(3),
  bottom: theme.spacing(3),
  zIndex: 1100,
}));

const AIAssistantButton = styled(IconButton)(({ theme }) => ({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: theme.palette.primary.contrastText,
  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.25)",
  },
}));

const AIAssistantDialog = styled(Paper)(({ theme }) => ({
  position: "absolute",
  bottom: "60px",
  right: 0,
  width: "300px",
  borderRadius: "12px",
  padding: theme.spacing(2),
  background: theme.palette.background.paper,
  boxShadow: "0 10px 40px rgba(0, 0, 0, 0.2)",
  transition: "all 0.3s ease",
}));

// Info card for the explanatory pop-up
const AIInfoCard = styled(Paper)(({ theme }) => ({
  width: "90%",
  maxWidth: "800px",
  margin: "2rem auto",
  padding: "2rem",
  borderRadius: "16px",
  position: "relative",
  textAlign: "center",
  background: alpha(theme.palette.background.paper, 0.92),
  backdropFilter: "blur(10px)",
  boxShadow: "0 12px 36px rgba(0, 0, 0, 0.1)",
  border: `1px solid ${alpha(theme.palette.divider, 0.05)}`,
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "6px",
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  },
}));

// =============== MAIN COMPONENT ===============

function SearchAndRetrieveImages() {
  const [query, setQuery] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false); // Separate loading state for "Load More"
  const [error, setError] = useState("");
  const [searchMode, setSearchMode] = useState("text");
  const [sortOrder, setSortOrder] = useState("date");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [targetUsername, setTargetUsername] = useState("");
  const [topClasses, setTopClasses] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [classLabel, setClassLabel] = useState("");
  const [minSize, setMinSize] = useState("");
  const [maxSize, setMaxSize] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderLoading, setFolderLoading] = useState(false);
  const [folderError, setFolderError] = useState("");
  const [showFolderPanel, setShowFolderPanel] = useState(false);
  const [currentFolderContents, setCurrentFolderContents] = useState([]);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [shareFolderDialogOpen, setShareFolderDialogOpen] = useState(false);
  const [currentFolderToEdit, setCurrentFolderToEdit] = useState(null);
  const [success, setSuccess] = useState("");
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [expandedFolders, setExpandedFolders] = useState({});
  const [dragOverFolder, setDragOverFolder] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuImage, setContextMenuImage] = useState(null);

  // Additional UI states
  const [showFilters, setShowFilters] = useState(false);
  const [searchAnimation, setSearchAnimation] = useState(false);
  const [viewMode, setViewMode] = useState("grid");
  const [showAssistant, setShowAssistant] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    "What would you like to search for?"
  );
  const [typingEffect, setTypingEffect] = useState(false);
  const [showAIClasses, setShowAIClasses] = useState(false);

  // Tagging
  const [showTagsDialog, setShowTagsDialog] = useState(false);
  const [activeImageForTags, setActiveImageForTags] = useState(null);
  const [imageTags, setImageTags] = useState({});
  const [newTag, setNewTag] = useState("");
  const [generatingTags, setGeneratingTags] = useState(false);
  const [tagCount, setTagCount] = useState(5);
  const [tagPrompt, setTagPrompt] = useState("");
  const [tagGenerationDialogOpen, setTagGenerationDialogOpen] = useState(false);

  // New state for sidebar collapse
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const aiSearchSuggestions = [
    "product images white backgrounds",
    "outdoor photos landscape",
    "people portraits",
    "blue color scheme",
  ];

  const pageSize = 12;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  
  // Ref for main content scroll area
  const mainContentRef = useRef(null);
  
  // Ref to keep track of scroll position
  const scrollPositionRef = useRef(0);

  // =============== FUNCTIONS ===============

  const getFolderPathNames = (folderId, foldersList) => {
    const path = [];
    let current = foldersList.find((f) => f.id === folderId);
    while (current) {
      path.unshift({
        id: current.id,
        name: current.name,
      });
      current = foldersList.find((f) => f.id === current.parent_folder_id);
    }
    return path;
  };

  const deleteSelectedImages = async () => {
    if (!selectedImages.length) {
      setError("Please select at least one image to delete.");
      return;
    }
    try {
      setDeleting(true);
      setError("");
      await api.delete("/images", {
        data: { image_urls: selectedImages },
      });

      if (currentFolder) {
        setCurrentFolderContents((prevResults) =>
          prevResults.filter((result) => !selectedImages.includes(result.image_url))
        );
      } else {
        setResults((prevResults) =>
          prevResults.filter((result) => !selectedImages.includes(result.image_url))
        );
      }

      setSelectedImages([]);
      setSuccess("Images deleted successfully");
    } catch (error) {
      setError("Error deleting images");
      console.error("Delete Images Error:", error);
    } finally {
      setDeleting(false);
    }
  };

  const fetchTopClasses = async () => {
    try {
      const response = await api.get("/classes/top");
      setTopClasses(response.data.top_classes);
    } catch (error) {
      console.error("Error fetching top classes:", error);
    }
  };

  const fetchFolders = async () => {
    try {
      setFolderLoading(true);
      const response = await api.get("/folders", {
        params: {
          recursive: true,
        },
      });
      setFolders(response.data.folders);
    } catch (error) {
      setFolderError("Failed to load folders");
      console.error("Error fetching folders:", error);
    } finally {
      setFolderLoading(false);
    }
  };

  const handleFolderSelect = async (folder) => {
    try {
      setLoading(true);
      setSearchAnimation(true);
      setCurrentFolder(folder);
      await fetchFolders();

      const response = await api.post(
        "/images/search",
        {},
        {
          params: {
            folder_id: folder?.id || null,
            recursive: false,
            page_size: pageSize,
            page_number: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (folder) {
        setCurrentFolderContents(response.data.results);
        setResults([]);
        setExpandedFolders((prev) => ({
          ...prev,
          [folder.id]: true,
        }));
      } else {
        setCurrentFolderContents([]);
        setResults(response.data.results);
      }

      setHasMore(response.data.results.length === pageSize);
      setPageNumber(1);
      setError("");
    } catch (error) {
      console.error("Failed to load folder contents:", error);
      setError("Failed to load folder contents");
    } finally {
      setTimeout(() => {
        setLoading(false);
        setSearchAnimation(false);
      }, 700);
    }
  };

  const handleSearchMode = (event, newMode) => {
    if (newMode !== null) {
      setSearchMode(newMode);
      setQuery("");
      setImageFile(null);
      setResults([]);
      setPageNumber(1);
      setHasMore(true);
      setError("");
      setCurrentFolder(null);

      if (newMode === "text") {
        setSearchPlaceholder("What would you like to search for?");
      } else {
        setSearchPlaceholder("Upload an image to find similar visuals");
      }
      searchImages(false, true);
    }
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
    setResults([]);
    setPageNumber(1);
    setHasMore(true);
    searchImages(false, !isSearching);
  };

  const fetchAutocomplete = async (queryText) => {
    if (queryText.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      setTypingEffect(true);
      const response = await api.get(`/autocomplete`, { params: { query: queryText } });
      setSuggestions(response.data.suggestions);
    } catch (error) {
      console.error("Error fetching autocomplete:", error);
    } finally {
      setTypingEffect(false);
    }
  };

  const handleMoveSelectedImages = async (targetFolderId, imagesToMove = selectedImages) => {
    if (!imagesToMove.length) return;
    try {
      setLoading(true);
      await api.post("/images/move", {
        image_urls: imagesToMove,
        folder_id: targetFolderId,
      });

      if (currentFolder) {
        handleFolderSelect(currentFolder);
      } else {
        searchImages(false, true);
      }
      if (imagesToMove === selectedImages) {
        setSelectedImages([]);
      }
      setError("");
    } catch (error) {
      setError("Failed to move images");
      console.error("Move Images Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFolderDelete = async (folderId) => {
    try {
      await api.delete(`/folders/${folderId}`);
      await fetchFolders();
      if (currentFolder?.id === folderId) {
        handleFolderSelect(null);
      }
    } catch (error) {
      setError("Failed to delete folder");
      console.error("Delete Folder Error:", error);
    }
  };

  const handleContextMenu = (event, imageUrl) => {
    event.preventDefault();
    setContextMenuImage(imageUrl);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;
    try {
      setError("");
      const response = await api.post("/folders", {
        name: newFolderName,
        parent_folder_id: currentFolder?.id || null,
        description: "",
        tags: [],
      });

      setNewFolderName("");
      setCreateDialogOpen(false);
      await fetchFolders();
      if (currentFolder) {
        setExpandedFolders((prev) => ({
          ...prev,
          [currentFolder.id]: true,
        }));
        await handleFolderSelect(currentFolder);
      }
    } catch (error) {
      console.error("Create Folder Error:", error);
      setError(error.response?.data?.detail || "Failed to create folder");
    }
  };

  const displayFolders = useMemo(() => {
    return folders.filter((folder) => {
      const isInCurrentFolder = folder.parent_folder_id === (currentFolder?.id || null);
      const isNotDeleted = !folder.is_deleted;
      return isInCurrentFolder && isNotDeleted;
    });
  }, [folders, currentFolder?.id]);

  // =============== TAGGING FUNCTIONS ===============

  const handleImageTagClick = (imageUrl) => {
    setActiveImageForTags(imageUrl);
    if (!imageTags[imageUrl]) {
      fetchImageTags(imageUrl);
    }
    setShowTagsDialog(true);
  };

  const fetchImageTags = async (imageUrl) => {
    try {
      setLoading(true);
      const response = await api.get(`/images/tags`, {
        params: { image_url: imageUrl },
      });
      setImageTags((prevTags) => ({
        ...prevTags,
        [imageUrl]: response.data.tags || [],
      }));
    } catch (error) {
      console.error("Error fetching image tags:", error);
      setError("Failed to load image tags");
      setImageTags((prevTags) => ({
        ...prevTags,
        [imageUrl]: [],
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleAddTag = () => {
    if (!newTag.trim() || !activeImageForTags) return;
    const currentTags = imageTags[activeImageForTags] || [];
    if (!currentTags.includes(newTag.trim())) {
      const updatedTags = [...currentTags, newTag.trim()];
      setImageTags((prevTags) => ({
        ...prevTags,
        [activeImageForTags]: updatedTags,
      }));
      saveImageTag(activeImageForTags, newTag.trim());
      setNewTag("");
    }
  };

  const handleRemoveTag = (imageUrl, tagToRemove) => {
    const currentTags = imageTags[imageUrl] || [];
    const updatedTags = currentTags.filter((tag) => tag !== tagToRemove);
    setImageTags((prevTags) => ({
      ...prevTags,
      [imageUrl]: updatedTags,
    }));
    removeImageTag(imageUrl, tagToRemove);
  };

  const saveImageTag = async (imageUrl, tag) => {
    try {
      await api.post("/images/tags", {
        image_url: imageUrl,
        tag: tag,
      });
      if (currentFolder) {
        setCurrentFolderContents([...currentFolderContents]);
      } else {
        setResults([...results]);
      }
    } catch (error) {
      console.error("Error saving tag:", error);
      setError("Failed to save tag");
    }
  };

  const removeImageTag = async (imageUrl, tag) => {
    try {
      await api.delete("/images/tags", {
        data: {
          image_url: imageUrl,
          tag: tag,
        },
      });
      if (currentFolder) {
        setCurrentFolderContents([...currentFolderContents]);
      } else {
        setResults([...results]);
      }
    } catch (error) {
      console.error("Error removing tag:", error);
      setError("Failed to remove tag");
    }
  };

  const generateImageTags = async (imageUrl) => {
    if (!imageUrl) return;
    try {
      setGeneratingTags(true);
      setError("");
      const response = await api.post("/images/generate-tags", {
        image_urls: [imageUrl],
        tag_count: tagCount,
        tag_prompt: tagPrompt || undefined,
        existing_tags: imageTags[imageUrl] ? [imageTags[imageUrl]] : [[]],
        exclude_existing: true,
      });
      if (response.data && response.data.results && response.data.results.length > 0) {
        const generatedTags = response.data.results[0].generated_tags || [];
        const currentTags = imageTags[imageUrl] || [];
        const updatedTags = [...currentTags, ...generatedTags];
        setImageTags((prevTags) => ({
          ...prevTags,
          [imageUrl]: updatedTags,
        }));
        for (const tag of generatedTags) {
          await saveImageTag(imageUrl, tag);
        }
        setSuccess(`Generated ${generatedTags.length} new tags`);
      }
    } catch (error) {
      console.error("Error generating tags:", error);
      setError(
        "Failed to generate tags: " + (error.response?.data?.detail || error.message)
      );
    } finally {
      setGeneratingTags(false);
    }
  };

  const generateTagsForSelectedImages = async () => {
    if (selectedImages.length === 0) return;
    try {
      setGeneratingTags(true);
      setError("");
      for (const imageUrl of selectedImages) {
        await generateImageTags(imageUrl);
      }
      if (currentFolder) {
        setCurrentFolderContents([...currentFolderContents]);
      } else {
        setResults([...results]);
      }
      setSuccess(`Generated tags for ${selectedImages.length} images`);
    } catch (error) {
      console.error("Error generating tags:", error);
      setError(
        "Failed to generate tags: " + (error.response?.data?.detail || error.message)
      );
    } finally {
      setGeneratingTags(false);
    }
  };

  const handleOpenTagsForSelected = () => {
    if (selectedImages.length === 1) {
      handleImageTagClick(selectedImages[0]);
    } else {
      setError(
        "Please select a single image to edit tags, or use 'Generate Tags' for multiple images."
      );
    }
  };

  useEffect(() => {
    if (query.length > 2) {
      const debounce = setTimeout(() => fetchAutocomplete(query), 300);
      return () => clearTimeout(debounce);
    }
  }, [query]);

  // Cycling placeholder
  useEffect(() => {
    const placeholders = [
      "What would you like to search for?",
      "Find images by content, style, or color...",
      "Try 'product photos with white background'",
      "Search for images by description or content",
    ];
    const interval = setInterval(() => {
      const currentIndex = placeholders.indexOf(searchPlaceholder);
      const nextIndex = (currentIndex + 1) % placeholders.length;
      setSearchPlaceholder(placeholders[nextIndex]);
    }, 5000);
    return () => clearInterval(interval);
  }, [searchPlaceholder]);

  useEffect(() => {
    const loadInitialData = async () => {
      await fetchFolders();
      await fetchTopClasses();
      await searchImages(false, true);
    };
    loadInitialData();
  }, []);

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
  };

  const handleFolderNavigate = (folder) => {
    setCurrentFolder(folder);
    searchImages(false);
  };

  // Add a function to save scroll position
  const saveScrollPosition = () => {
    if (mainContentRef.current) {
      scrollPositionRef.current = mainContentRef.current.scrollTop;
    }
  };

  // Add a function to restore scroll position
  const restoreScrollPosition = () => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = scrollPositionRef.current;
    }
  };

  // Improved searchImages function with proper scroll handling
  const searchImages = async (loadMore = false, isInitialLoad = false) => {
    // Save scroll position before loading more
    if (loadMore) {
      saveScrollPosition();
      setLoadingMore(true);
    } else {
      setLoading(true);
      setSearchAnimation(true);
      if (
        !isInitialLoad &&
        !query &&
        !classLabel &&
        !imageFile &&
        !currentFolder
      ) {
        setError("Please enter a search query, select a class, or choose a folder");
        setLoading(false);
        setSearchAnimation(false);
        return;
      }
      setResults([]);
      setPageNumber(1);
      setHasMore(true);
    }
    
    setError("");

    let requestData = {};
    let headers = {};
    let endpoint = "/images/search";

    if (searchMode === "image" && imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile);
      if (startDate) formData.append("start_date", startDate.toISOString().split("T")[0]);
      if (endDate) formData.append("end_date", endDate.toISOString().split("T")[0]);
      if (classLabel) formData.append("class_label_filter", classLabel);
      if (minSize) formData.append("min_size", minSize);
      if (maxSize) formData.append("max_size", maxSize);
      if (currentFolder) {
        formData.append("folder_id", currentFolder.id);
        formData.append("recursive", true);
      }
      requestData = formData;
      endpoint = "/images/visual-search";
    } else {
      const data = {
        ...(query && { query_text: query }),
        ...(classLabel && { class_label_filter: classLabel }),
        ...(startDate && { start_date: startDate.toISOString().split("T")[0] }),
        ...(endDate && { end_date: endDate.toISOString().split("T")[0] }),
        ...(minSize && { min_size: minSize }),
        ...(maxSize && { max_size: maxSize }),
        ...(currentFolder && { folder_id: currentFolder.id, recursive: false }),
      };
      requestData = data;
      headers["Content-Type"] = "application/json";
    }

    try {
      const response = await api.post(endpoint, requestData, {
        params: {
          page_size: pageSize,
          page_number: loadMore ? pageNumber : 1,
        },
        headers: headers,
      });

      let fetchedResults = response.data.results;
      const currentSortOrder = isInitialLoad ? "date" : "similarity";
      setSortOrder(currentSortOrder);

      if (currentSortOrder === "date") {
        fetchedResults.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }
      
      if (loadMore) {
        // When loading more, append to existing results
        setResults(prevResults => [...prevResults, ...fetchedResults]);
      } else {
        if (currentFolder) {
          setCurrentFolderContents(fetchedResults);
        } else {
          setResults(fetchedResults);
        }
      }
      
      if (response.data.results.length < pageSize) {
        setHasMore(false);
      }
      
      setPageNumber(prevPage => prevPage + 1);

      // Fetch tags for each image
      fetchedResults.forEach((result) => {
        if (!imageTags[result.image_url]) {
          fetchImageTags(result.image_url);
        }
      });
    } catch (error) {
      setError("Error retrieving images");
      console.error("Search Images Error:", error);
    } finally {
      // Use shorter timeout for "load more" to minimize UI disruption
      setTimeout(() => {
        if (loadMore) {
          setLoadingMore(false);
          // Restore scroll position after state updates
          setTimeout(restoreScrollPosition, 50);
        } else {
          setLoading(false);
          setSearchAnimation(false);
        }
      }, loadMore ? 300 : 800);
    }
  };

  // Clear selected images function
  const clearSelectedImages = () => {
    setSelectedImages([]);
  };

  const handleSelectImage = (imageUrl) => {
    if (selectedImages.includes(imageUrl)) {
      setSelectedImages(selectedImages.filter((url) => url !== imageUrl));
    } else {
      setSelectedImages([...selectedImages, imageUrl]);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const downloadSelectedImages = async () => {
    try {
      setLoading(true);
      const response = await api.post(
        "/images/download-zip",
        { image_urls: selectedImages },
        {
          headers: { "Content-Type": "application/json" },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "selected_images.zip";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      setError("Error downloading images");
      console.error("Download Images Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenShareDialog = () => {
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
    setTargetUsername("");
    setError("");
  };

  const shareSelectedImages = async () => {
    if (!targetUsername) {
      setError("Please enter a username to share with");
      return;
    }
    try {
      setLoading(true);
      await api.post("/images/share", {
        image_urls: selectedImages,
        target_username: targetUsername,
      });
      setSuccess("Images shared successfully");
      handleCloseShareDialog();
    } catch (error) {
      setError("Error sharing images");
      console.error("Share Images Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClassClick = (newClassLabel) => {
    setClassLabel(newClassLabel);
    setQuery("");
    setSearchMode("text");
    setResults([]);
    setPageNumber(1);
    setIsSearching(true);
    setTimeout(() => {
      searchImages(false);
    }, 0);
  };

  const handleMove = async (targetFolderId) => {
    try {
      await api.post("/images/move", {
        image_urls: selectedImages,
        folder_id: targetFolderId,
      });
      if (currentFolder) {
        handleFolderSelect(currentFolder);
      } else {
        searchImages(false, true);
      }
      setSelectedImages([]);
      setMoveDialogOpen(false);
    } catch (error) {
      setError("Failed to move images");
      console.error("Move Images Error:", error);
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    setContextMenuImage(null);
  };

  const handleMoveImage = async (targetFolderId) => {
    if (!contextMenuImage) return;
    await handleMoveSelectedImages(targetFolderId, [contextMenuImage]);
    handleCloseContextMenu();
  };

  const getAIRecommendations = () => {
    return [
      "I notice you often search for product photos with white backgrounds",
      "Would you like to create a smart collection for similar images?",
      "Try refining your search with 'outdoor' to find nature images",
      "Would you like to organize these images by visual similarity?",
    ];
  };

  // =============== RENDER METHODS ===============

  const renderTagsDialog = () => {
    if (activeImageForTags === null) return null;
    const currentTags = imageTags[activeImageForTags] || [];
    return (
      <Dialog
        open={showTagsDialog}
        onClose={() => setShowTagsDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6">Edit Tags</Typography>
            <IconButton onClick={() => setShowTagsDialog(false)} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
            <img
              src={activeImageForTags}
              alt="Selected"
              style={{
                width: 100,
                height: 100,
                objectFit: "cover",
                borderRadius: 8,
              }}
            />
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Image Tags
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {activeImageForTags
                  ? `URL: ${activeImageForTags.substring(0, 50)}...`
                  : ""}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AutoAwesomeIcon />}
              onClick={() => generateImageTags(activeImageForTags)}
              disabled={generatingTags}
              fullWidth
              sx={{ borderRadius: 2, mb: 1 }}
            >
              {generatingTags ? (
                <>
                  <CircularProgress size={16} sx={{ mr: 1 }} />
                  Generating Tags...
                </>
              ) : (
                "Generate Additional Tags"
              )}
            </Button>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                size="small"
                label="Number of tags"
                type="number"
                value={tagCount}
                onChange={(e) => setTagCount(Math.max(1, parseInt(e.target.value) || 5))}
                inputProps={{ min: 1, max: 20 }}
                sx={{ width: "30%" }}
              />
              <TextField
                size="small"
                label="Custom prompt (optional)"
                value={tagPrompt}
                onChange={(e) => setTagPrompt(e.target.value)}
                sx={{ width: "70%" }}
              />
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Current Tags:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 3 }}>
            {currentTags.length > 0 ? (
              currentTags.map((tag, idx) => (
                <Chip
                  key={idx}
                  label={tag}
                  onDelete={() => handleRemoveTag(activeImageForTags, tag)}
                  color="primary"
                  variant="outlined"
                  sx={{ borderRadius: "12px" }}
                />
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No tags yet. Add some below or generate with AI.
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <TextField
              fullWidth
              label="Add new tag"
              variant="outlined"
              size="small"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleAddTag();
                }
              }}
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
            />
            <Button
              variant="contained"
              onClick={handleAddTag}
              disabled={!newTag.trim()}
              sx={{ borderRadius: "12px" }}
            >
              Add
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTagsDialog(false)}>Done</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <RootContainer>
      {/* TOP BAR */}
      

      {/* CONTENT WRAPPER (SIDEBAR + MAIN) */}
      <ContentWrapper>
        {/* SIDEBAR */}
        <SidebarContainer>
          <Sidebar collapsed={sidebarCollapsed}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
                {sidebarCollapsed ? (
                  <Folder size={24} color={theme.palette.primary.main} />
                ) : (
                  <ChevronLeft size={24} color={theme.palette.primary.main} />
                )}
              </IconButton>
            </Box>
            {!sidebarCollapsed && (
              <>
                {folderError && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {folderError}
                  </Alert>
                )}
                {folderLoading && <CircularProgress size={24} sx={{ mb: 2 }} />}

                {/* Folder creation / navigation */}
                <Button
                  startIcon={<Plus size={16} />}
                  onClick={() => setCreateDialogOpen(true)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ borderRadius: "12px", mb: 2 }}
                >
                  Create Folder
                </Button>

                {currentFolder && (
                  <Box sx={{ mb: 2 }}>
                    <FolderBreadcrumb
                      path={[
                        { id: "root", name: "Root" },
                        ...getFolderPathNames(currentFolder.id, folders),
                      ]}
                      onNavigate={(folder) => {
                        setCurrentFolder(folder);
                        handleFolderSelect(folder);
                      }}
                    />
                  </Box>
                )}

                {/* Display subfolders */}
                {displayFolders.map((folder, index) => (
                  <motion.div
                    key={folder.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.05 }}
                    style={{ marginBottom: "10px" }}
                  >
                    <FolderCard
                      isDragOver={dragOverFolder === folder.id}
                      onClick={() => handleFolderSelect(folder)}
                      onDragOver={(e) => {
                        e.preventDefault();
                        setDragOverFolder(folder.id);
                      }}
                      onDragLeave={() => setDragOverFolder(null)}
                      onDrop={(e) => {
                        e.preventDefault();
                        setDragOverFolder(null);
                        // handle drop logic
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 2, flex: 1 }}>
                        <Folder size={24} color={theme.palette.primary.main} />
                        <Typography noWrap sx={{ flex: 1, fontWeight: "medium" }}>
                          {folder.name}
                        </Typography>
                      </Box>
                      <Box>
                        <FolderActions
                          folder={folder}
                          onFolderUpdate={fetchFolders}
                          onFolderDelete={handleFolderDelete}
                        />
                      </Box>
                    </FolderCard>
                  </motion.div>
                ))}
              </>
            )}
          </Sidebar>
        </SidebarContainer>
        {/* MAIN CONTENT - using forwardRef */}
        <MainContent ref={mainContentRef}>
          {/* Display error or success at top */}
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2, borderRadius: "12px" }}
              onClose={() => setError("")}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              sx={{ mb: 2, borderRadius: "12px" }}
              onClose={() => setSuccess("")}
            >
              {success}
            </Alert>
          )}

          {/* Search Controls */}
          <AIPaper sx={{ mb: 2 }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, alignItems: "center" }}>
              <AIToggleButtonGroup
                color="primary"
                value={searchMode}
                exclusive
                onChange={handleSearchMode}
                aria-label="search mode"
                sx={{ flexShrink: 0 }}
              >
                <ToggleButton value="text" aria-label="text search">
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Search size={18} /> Text
                  </Box>
                </ToggleButton>
                <ToggleButton value="image" aria-label="visual search">
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <ImageSearchIcon fontSize="small" /> Visual
                  </Box>
                </ToggleButton>
              </AIToggleButtonGroup>

              {searchMode === "text" && (
                <Box sx={{ position: "relative", flexGrow: 1 }}>
                  <AISearchBar
                    fullWidth
                    placeholder={searchPlaceholder}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    variant="outlined"
                    inputRef={searchInputRef}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AnimatedSearchIcon className={searchAnimation ? "searching" : ""}>
                            {searchAnimation ? (
                              <MemoryIcon color="primary" />
                            ) : (
                              <Search size={20} color={theme.palette.text.secondary} />
                            )}
                          </AnimatedSearchIcon>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Voice Search">
                            <IconButton edge="end" sx={{ mr: 0.5 }}>
                              <KeyboardVoiceIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {suggestions.length > 0 && (
                    <Zoom in={suggestions.length > 0}>
                      <SuggestionsList>
                        <List>
                          {suggestions.map((suggestion, index) => (
                            <SuggestionItem
                              button
                              key={index}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              <ListItemText
                                primary={suggestion}
                                primaryTypographyProps={{ fontSize: "0.95rem" }}
                              />
                            </SuggestionItem>
                          ))}
                        </List>
                      </SuggestionsList>
                    </Zoom>
                  )}
                </Box>
              )}

              {searchMode === "image" && (
                <Box
                  sx={{
                    p: 2,
                    border: `2px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
                    borderRadius: "12px",
                    textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: "medium" }}>
                    Upload an image to find similar content
                  </Typography>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setImageFile(e.target.files[0])}
                    style={{ display: "none" }}
                    id="image-upload"
                  />
                  <label htmlFor="image-upload">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<ImageSearchIcon />}
                      sx={{ mt: 1, borderRadius: "12px", p: "6px 16px" }}
                    >
                      Select Image
                    </Button>
                  </label>
                  {imageFile && (
                    <Box sx={{ mt: 1 }}>
                      <Chip
                        label={imageFile.name}
                        onDelete={() => setImageFile(null)}
                        sx={{ borderRadius: "12px" }}
                      />
                    </Box>
                  )}
                </Box>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={() => searchImages(false, false)}
                disabled={
                  loading ||
                  (searchMode === "text" && !query && !classLabel && !currentFolder) ||
                  (searchMode === "image" && !imageFile)
                }
                sx={{ borderRadius: "12px", height: 40 }}
              >
                {loading ? (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                    <span>Analyzing...</span>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Zap size={18} />
                    <span>Search</span>
                  </Box>
                )}
              </Button>
            </Box>

            {/* Filter / Additional Controls */}
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 2 }}>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel id="sort-order-label">Sort By</InputLabel>
                <Select
                  labelId="sort-order-label"
                  id="sort-order"
                  value={sortOrder}
                  label="Sort By"
                  onChange={handleSortOrderChange}
                  sx={{ borderRadius: "12px" }}
                >
                  <MenuItem value="date">Date</MenuItem>
                  <MenuItem value="similarity">Similarity</MenuItem>
                  <MenuItem value="relevance">AI Relevance</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="outlined"
                startIcon={<TuneIcon />}
                onClick={() => setShowFilters(!showFilters)}
                sx={{ borderRadius: "12px", height: 40 }}
              >
                Filters
              </Button>

              <Button
                variant="outlined"
                startIcon={viewMode === "grid" ? <BubbleChartIcon /> : <ViewModuleIcon />}
                onClick={() => setViewMode(viewMode === "grid" ? "map" : "grid")}
                sx={{ borderRadius: "12px", height: 40 }}
              >
                {viewMode === "grid" ? "Similarity Map" : "Grid View"}
              </Button>

              {(query || classLabel || startDate || endDate || minSize || maxSize) && (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<ClearIcon />}
                  onClick={() => {
                    setQuery("");
                    setClassLabel("");
                    setStartDate(null);
                    setEndDate(null);
                    setMinSize("");
                    setMaxSize("");
                    searchImages(false, true);
                  }}
                  sx={{ borderRadius: "12px", height: 40 }}
                >
                  Clear
                </Button>
              )}
            </Box>

            <Collapse in={showFilters} sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth size="small" sx={{ borderRadius: "12px" }} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth size="small" sx={{ borderRadius: "12px" }} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Min Size (KB)"
                    variant="outlined"
                    type="number"
                    value={minSize}
                    onChange={(e) => setMinSize(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">KB</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Max Size (KB)"
                    variant="outlined"
                    type="number"
                    value={maxSize}
                    onChange={(e) => setMaxSize(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">KB</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Class Label"
                    variant="outlined"
                    value={classLabel}
                    onChange={(e) => setClassLabel(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </AIPaper>

          {/* Top Classes */}
          {topClasses.length > 0 && (
            <AIPaper sx={{ mb: 2 }}>
              <SectionHeader onClick={() => setShowAIClasses(!showAIClasses)}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600", display: "flex", alignItems: "center", gap: 1 }}>
                  <AnalyticsIcon fontSize="small" /> AI-Detected Classes
                </Typography>
                <IconButton size="small">
                  {showAIClasses ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </IconButton>
              </SectionHeader>
              <Collapse in={showAIClasses}>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
                  {topClasses.map((classItem, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.05 }}
                    >
                      <AIFilterChip
                        label={`${classItem.class_label} (${classItem.count})`}
                        onClick={() => handleClassClick(classItem.class_label)}
                        onDelete={
                          classLabel === classItem.class_label
                            ? () => {
                                setClassLabel("");
                                setTimeout(() => {
                                  searchImages(false, true);
                                }, 0);
                              }
                            : undefined
                        }
                        className={classLabel === classItem.class_label ? "active" : ""}
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: `hsl(${(index * 40) % 360}, 70%, 50%)`,
                              width: 24,
                              height: 24,
                              fontSize: "0.8rem",
                            }}
                          >
                            {classItem.class_label.charAt(0).toUpperCase()}
                          </Avatar>
                        }
                      />
                    </motion.div>
                  ))}
                </Box>
              </Collapse>
            </AIPaper>
          )}

          {/* IMAGES SECTION */}
          <AIPaper>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "600", mb: 2, display: "flex", alignItems: "center", gap: 1 }}
            >
              <ImageSearchIcon /> Images
            </Typography>
            {loading && !loadingMore ? (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", py: 6 }}>
                <CircularProgress size={40} />
                <Typography sx={{ mt: 2, color: "text.secondary" }}>
                  AI is analyzing your request...
                </Typography>
              </Box>
            ) : (currentFolder ? currentFolderContents : results).length > 0 ? (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <EnhancedImageList
                    items={currentFolder ? currentFolderContents : results}
                    onImageClick={handleImageClick}
                    onImageSelect={handleSelectImage}
                    selectedImages={selectedImages}
                    isSmallScreen={isSmallScreen}
                    onContextMenu={handleContextMenu}
                    viewMode={viewMode}
                    imageTags={imageTags}
                  />
                </motion.div>
              </AnimatePresence>
            ) : (
              <Box sx={{ textAlign: "center", py: 6 }}>
                <Typography variant="body1" color="text.secondary">
                  No images found in{" "}
                  {currentFolder ? `folder "${currentFolder.name}"` : "this location"}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Try adjusting your search criteria or browse another folder
                </Typography>
              </Box>
            )}
            
            {/* Load More button with better loading indicator */}
            {hasMore && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => searchImages(true, false)}
                  disabled={loadingMore}
                  sx={{ minWidth: 120 }}
                >
                  {loadingMore ? (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CircularProgress size={16} color="inherit" />
                      <span>Loading...</span>
                    </Box>
                  ) : (
                    "Load More"
                  )}
                </Button>
              </Box>
            )}
          </AIPaper>
        </MainContent>
      </ContentWrapper>

      {/* BOTTOM ACTION BAR (only visible when images are selected) */}
      {selectedImages.length > 0 && (
        <BottomActionBar>
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            {selectedImages.length} selected
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button 
              variant="outlined"
              size="small" 
              startIcon={<ClearIcon />}
              onClick={clearSelectedImages}
            >
              Clear Selection
            </Button>
            <Button variant="contained" color="secondary" size="small" onClick={downloadSelectedImages}>
              Download
            </Button>
            <Button variant="contained" color="primary" size="small" onClick={handleOpenShareDialog}>
              Share
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={deleteSelectedImages}
              disabled={deleting}
            >
              {deleting ? <CircularProgress size={20} /> : "Delete"}
            </Button>
            <Button variant="contained" color="primary" size="small" startIcon={<Move size={16} />} onClick={() => setMoveDialogOpen(true)}>
              Move
            </Button>
            <Button variant="contained" color="info" size="small" startIcon={<TagIcon />} onClick={handleOpenTagsForSelected}>
              Tags
            </Button>
            <Button
              variant="contained"
              color="success"
              size="small"
              startIcon={<AutoAwesomeIcon />}
              onClick={() => setTagGenerationDialogOpen(true)}
              disabled={generatingTags}
            >
              {generatingTags ? <CircularProgress size={16} /> : "Generate Tags"}
            </Button>
          </Box>
        </BottomActionBar>
      )}

      {/* AI ASSISTANT BUTTON / PANEL */}
      <AIAssistantPanel>
        <AIAssistantButton onClick={() => setShowAssistant(!showAssistant)} size="large">
          <Brain size={28} />
        </AIAssistantButton>
        {showAssistant && (
          <AIAssistantDialog>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
              AI Assistant
            </Typography>
            <Typography variant="body2" paragraph>
              I can help you find the perfect images. Ask me natural language questions!
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: "italic", color: "text.secondary" }}>
              Examples:
            </Typography>
            <Box component="ul" sx={{ pl: 2, mt: 1 }}>
              <Typography component="li" variant="body2" sx={{ mb: 0.5 }}>
                "Find product photos with white backgrounds"
              </Typography>
              <Typography component="li" variant="body2" sx={{ mb: 0.5 }}>
                "Show me all outdoor photos from last month"
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button size="small" onClick={() => setShowAssistant(false)}>
                Close
              </Button>
            </Box>
          </AIAssistantDialog>
        )}
      </AIAssistantPanel>

      {/* EXPLANATORY CARD */}
      {showExplanatoryCard && (
        <AIInfoCard>
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Avatar
              sx={{
                width: 60,
                height: 60,
                mb: 2,
                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              }}
            >
              <Brain size={32} />
            </Avatar>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              AI-Powered Image Search
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ lineHeight: 1.6, mb: 3 }}>
              Our AI system helps you find exactly what you're looking for with both text and visual
              similarity. Use natural language, upload an image, or let the AI suggest categories.
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "center" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowExplanatoryCard(false);
                  searchInputRef.current?.focus();
                }}
              >
                Get Started
              </Button>
              <Button variant="text" onClick={() => setShowExplanatoryCard(false)}>
                Dismiss
              </Button>
            </Box>
          </Box>
        </AIInfoCard>
      )}

      {/* DIALOGS */}
      <Dialog
        open={Boolean(selectedImage)}
        onClose={() => setSelectedImage(null)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: "90vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogContent
          sx={{
            position: "relative",
            p: 0,
            borderRadius: "16px",
            overflow: "auto",
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedImage && (
            <>
              <IconButton
                aria-label="close"
                onClick={() => setSelectedImage(null)}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: alpha("#000", 0.4),
                  "&:hover": {
                    backgroundColor: alpha("#000", 0.6),
                  },
                  zIndex: 10,
                }}
              >
                <CloseIcon />
              </IconButton>
              <img
                src={selectedImage}
                alt="Selected"
                style={{
                  maxWidth: "100%",
                  maxHeight: "80vh",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button variant="outlined" color="primary" href={selectedImage} download>
            Download
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              handleImageTagClick(selectedImage);
            }}
            startIcon={<TagIcon />}
          >
            Edit Tags
          </Button>
          <Button onClick={() => setSelectedImage(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={shareDialogOpen} onClose={handleCloseShareDialog}>
        <DialogTitle>Share Selected Images</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the username of the user you want to share the selected images with.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={targetUsername}
            onChange={(e) => setTargetUsername(e.target.value)}
            sx={{ mt: 2, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={shareSelectedImages} color="primary" variant="contained">
            Share
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          setError("");
          setSuccess("");
          setNewFolderName("");
        }}
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError("")}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess("")}>
              {success}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleCreateFolder();
              }
            }}
            sx={{ mt: 2, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCreateDialogOpen(false);
              setError("");
              setSuccess("");
              setNewFolderName("");
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleCreateFolder} variant="contained" disabled={!newFolderName.trim()}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={moveDialogOpen}
        onClose={() => setMoveDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Move Items</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" fullWidth onClick={() => handleMove(null)} sx={{ mb: 2 }}>
              Move to Root (No Folder)
            </Button>
            <Typography variant="subtitle2" gutterBottom>
              Or select a destination folder:
            </Typography>
            <Box sx={{ mt: 2 }}>
              <FolderTree
                folders={folders}
                selectedFolder={null}
                onFolderSelect={(folder) => handleMove(folder?.id)}
                onDropInFolder={null}
                sharedFolders={[]}
                handleFolderDelete={() => {}}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMoveDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            if (contextMenuImage) {
              handleImageTagClick(contextMenuImage);
            }
            handleCloseContextMenu();
          }}
        >
          <TagIcon fontSize="small" sx={{ mr: 1 }} />
          Edit Tags
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleMoveImage(null);
          }}
        >
          <Move size={16} style={{ marginRight: 8 }} />
          Move to Root
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setSelectedImages([contextMenuImage]);
            setMoveDialogOpen(true);
            handleCloseContextMenu();
          }}
        >
          <Folder size={16} style={{ marginRight: 8 }} />
          Move to Folder...
        </MenuItem>
      </Menu>

      {/* TAGS DIALOG */}
      {renderTagsDialog()}

      {/* TAG GENERATION DIALOG */}
      <Dialog
        open={tagGenerationDialogOpen}
        onClose={() => setTagGenerationDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Generate Tags with AI</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <Typography variant="body2" paragraph>
              The AI will analyze {selectedImages.length} selected image
              {selectedImages.length !== 1 ? "s" : ""} and generate relevant tags.
            </Typography>
            <TextField
              fullWidth
              label="Tag generation prompt (optional)"
              variant="outlined"
              value={tagPrompt}
              onChange={(e) => setTagPrompt(e.target.value)}
              placeholder="E.g., 'Focus on objects', 'Describe style', 'Identify colors'"
              helperText="Guide the AI with specific instructions for tag generation"
              sx={{ mb: 3 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                label="Number of tags to generate"
                type="number"
                value={tagCount}
                onChange={(e) => setTagCount(Math.max(1, parseInt(e.target.value) || 5))}
                inputProps={{ min: 1, max: 20 }}
                variant="outlined"
                helperText="Maximum 20 tags per image"
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTagGenerationDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={generateTagsForSelectedImages}
            variant="contained"
            disabled={generatingTags}
            startIcon={generatingTags ? <CircularProgress size={16} /> : <AutoAwesomeIcon />}
          >
            {generatingTags ? "Generating..." : "Generate Tags"}
          </Button>
        </DialogActions>
      </Dialog>
    </RootContainer>
  );
}

export default SearchAndRetrieveImages;
