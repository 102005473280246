// ClassifyAndSaveImagesAINative.jsx

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Chip,
  Fade,
  Zoom,
  Slide,
  IconButton,
  Paper,
  Tooltip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  Divider,
  InputAdornment,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  alpha,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Switch,
} from "@mui/material";

// Icons
import {
  AddCircle as AddCircleIcon,
  CloudUpload as CloudUploadIcon,
  Collections as CollectionsIcon,
  Create as CreateIcon,
  AutoAwesome as AutoAwesomeIcon,
  Tag as TagIcon,
  Send as SendIcon,
  SmartToy as SmartToyIcon,
  Close as CloseIcon,
  Folder as FolderIcon,
  FolderOpen as FolderOpenIcon,
  SaveAlt as SaveAltIcon,
  Refresh as RefreshIcon,
  Info as InfoIcon,
  MoreVert as MoreVertIcon,
  Palette as PaletteIcon,
  EmojiObjects as EmojiObjectsIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  KeyboardCommandKey as KeyboardCommandKeyIcon,
  UploadFile as UploadFileIcon,
  FilterNone as FilterNoneIcon,
} from "@mui/icons-material";

import api from "../api";
import FolderSelector from './FolderSelector';

const ClassifyAndSaveImages = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const fileInputRef = useRef(null);
  
  // State for classified images
  const [files, setFiles] = useState([]);
  const [externalImages, setExternalImages] = useState([]);
  const [classificationResults, setClassificationResults] = useState([]);
  const [isClassified, setIsClassified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processingStage, setProcessingStage] = useState(""); // For detailed processing feedback
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModelId, setSelectedModelId] = useState("");
  const [tagPrompt, setTagPrompt] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [showAIChat, setShowAIChat] = useState(false);
  const [aiChatMessages, setAiChatMessages] = useState([
    { from: 'ai', message: 'Hi! I can help classify and tag your images. Upload some images to get started or ask me a question.' }
  ]);
  const [aiChatInput, setAiChatInput] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [showCommandPalette, setShowCommandPalette] = useState(false);
  const [commandInput, setCommandInput] = useState("");
  const [folderMenuAnchor, setFolderMenuAnchor] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [allFolders, setAllFolders] = useState([]);
  const [showTagsDialog, setShowTagsDialog] = useState(false);
  const [activeImageForTags, setActiveImageForTags] = useState(null);
  const [newTag, setNewTag] = useState("");
  const [userClasses, setUserClasses] = useState([]);
  const [commonClass, setCommonClass] = useState("");

  // Advanced states for AI-native experience
  const [aiSuggestions, setAiSuggestions] = useState([]);
  const [confidenceVisualization, setConfidenceVisualization] = useState(false);
  const [batchProcessingProgress, setBatchProcessingProgress] = useState(0);
  const [autoClassifyOnUpload, setAutoClassifyOnUpload] = useState(false); // Changed to false
  const [showFeaturesDialog, setShowFeaturesDialog] = useState(false);
  const [tagCount, setTagCount] = useState(5);
  const [generatingTags, setGeneratingTags] = useState(false);
  
  // State for create folder dialog
  const [showCreateFolderDialog, setShowCreateFolderDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [newFolderParentId, setNewFolderParentId] = useState(null);
  const [creatingFolder, setCreatingFolder] = useState(false);
  
  const chatEndRef = useRef(null);

  useEffect(() => {
    // This effect scrolls the chat to the bottom when new messages are added
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [aiChatMessages]);

  useEffect(() => {
    const initialize = async () => {
      await fetchFolders();
      await fetchModels();

      if (location.state && location.state.images) {
        setExternalImages(location.state.images);
        if (autoClassifyOnUpload) {
          // Auto-classify external images
          setTimeout(() => classifyImages(location.state.images), 500);
        }
      }

      if (location.state && location.state.selectedModelId) {
        setSelectedModelId(location.state.selectedModelId);
      }

      // Check for keyboard shortcut
      document.addEventListener('keydown', handleKeyDown);
    };

    initialize();

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [location.state]);

  const handleKeyDown = (e) => {
    // Command palette shortcut (Cmd+K or Ctrl+K)
    if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
      e.preventDefault();
      setShowCommandPalette(true);
    }
  };

  const fetchModels = async () => {
    try {
      const response = await api.get("/models");
      setModels(response.data);
      
      // Auto-select the best model (in a real implementation, 
      // this would intelligently choose based on content)
      if (response.data.length > 0) {
        // Find a model that might be good for general purpose classification
        const generalModel = response.data.find(model => 
          model.name.toLowerCase().includes('general') || 
          model.name.toLowerCase().includes('default')
        );
        
        if (generalModel) {
          setSelectedModelId(generalModel.id);
          
          // Add an AI suggestion
          setAiSuggestions(prev => [
            ...prev, 
            {
              type: 'model',
              message: `I've selected the ${generalModel.name} model which works well for general image classification.`,
              action: () => {}, // Already applied
              actionText: 'Using this model'
            }
          ]);
        }
      }
    } catch (error) {
      console.error("Failed to fetch models", error);
      setError("Failed to fetch available models");
    }
  };

  const fetchFolders = async () => {
    try {
      const response = await api.get('/folders', {
        params: { recursive: true }
      });
      const foldersData = response.data.folders || [];
      
      setAllFolders(foldersData);
      
      // Update selected folder if needed
      if (selectedFolder) {
        const updatedFolder = foldersData.find(f => f.id === selectedFolder.id);
        if (updatedFolder) {
          setSelectedFolder(updatedFolder);
        }
      }
      
      return foldersData;
    } catch (error) {
      console.error('Error fetching folders:', error);
      setError('Failed to fetch folders');
      return [];
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFiles(Array.from(e.dataTransfer.files));
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFiles(Array.from(e.target.files));
    }
  };

  const handleFiles = (newFiles) => {
    setFiles(newFiles);
    setExternalImages([]);
    resetClassificationStates();
    
    // Add message to AI chat
    setAiChatMessages(prev => [
      ...prev,
      { from: 'user', message: `I've uploaded ${newFiles.length} image${newFiles.length > 1 ? 's' : ''}.` },
      { 
        from: 'ai', 
        message: `Great! I see ${newFiles.length} image${newFiles.length > 1 ? 's' : ''}. ${
          autoClassifyOnUpload 
            ? "I'll classify them for you automatically." 
            : "You can adjust the model and tag options above, then click 'Analyze with AI' when ready."
        }`
      }
    ]);
    
    if (autoClassifyOnUpload) {
      // Auto-classify with slight delay for better UX
      setTimeout(() => classifyImages(newFiles), 800);
    } else {
      // Suggest classification
      setAiSuggestions(prev => [
        ...prev,
        {
          type: 'action',
          message: 'Would you like me to classify these images now?',
          action: () => classifyImages(newFiles),
          actionText: 'Classify Images'
        }
      ]);
    }
  };

  const resetClassificationStates = () => {
    setClassificationResults([]);
    setIsClassified(false);
    setSelectedImages([]);
    setUserClasses([]);
    setCommonClass("");
  };

  const resizeImage = (file, maxWidth = 224, maxHeight = 224) => {
    return new Promise((resolve, reject) => {
      const fileName = file.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const resizedFile = new File([blob], fileName, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            },
            "image/jpeg",
            0.75
          );
        };
        img.onerror = (err) => {
          console.error("Image load error:", err);
          reject(err);
        };
        img.src = event.target.result;
      };
      reader.onerror = (err) => {
        console.error("FileReader error:", err);
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  const classifyImages = async (imagesToClassify = null) => {
    setLoading(true);
    setError("");
    setProcessingStage("initializing");
    setBatchProcessingProgress(0);
    
    // Add message to AI chat
    setAiChatMessages(prev => [
      ...prev,
      { from: 'ai', message: "I'm analyzing your images now. This will just take a moment..." }
    ]);

    try {
      let response;
      
      if (!imagesToClassify) {
        imagesToClassify = externalImages.length > 0 ? externalImages : files;
      }
      
      if (typeof imagesToClassify[0] === 'string') {
        // These are external image URLs
        setProcessingStage("analyzing");
        
        const data = {
          image_urls: imagesToClassify,
          ...(selectedModelId && { model_id: selectedModelId }),
          tag_count: tagCount, // Use the user-selected tag count
          tag_prompt: tagPrompt || undefined
        };

        response = await api.post("/images/classify", data, {
          headers: {
            "Content-Type": "application/json",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setBatchProcessingProgress(percentCompleted);
          }
        });
      } else {
        // These are file objects
        const formData = new FormData();
        const resizedFiles = [];
        
        // Resize stage
        setProcessingStage("resizing");
        for (let i = 0; i < imagesToClassify.length; i++) {
          try {
            const resizedFile = await resizeImage(imagesToClassify[i]);
            resizedFiles.push(resizedFile);
            setBatchProcessingProgress(Math.round(((i + 1) / imagesToClassify.length) * 100));
          } catch (error) {
            console.error(`Error resizing image ${imagesToClassify[i].name}:`, error);
            continue;
          }
        }
        
        // Analysis stage
        setProcessingStage("analyzing");
        setBatchProcessingProgress(0);
        
        for (const resizedFile of resizedFiles) {
          formData.append("files", resizedFile);
        }
        
        if (selectedModelId) {
          formData.append("model_id", selectedModelId);
        }
        
        // Use the user-selected tag count
        formData.append("tag_count", tagCount.toString());
        if (tagPrompt) {
          formData.append("tag_prompt", tagPrompt || "");
        }

        response = await api.post("/images/classify", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setBatchProcessingProgress(percentCompleted);
          }
        });
      }
      
      // Process results
      setProcessingStage("finalizing");
      const results = response.data.classification_results;
      setClassificationResults(results);
      setIsClassified(true);

      // Initialize userClasses with empty strings - will be used for custom class overrides
      setUserClasses(new Array(results.length).fill(""));
      
      // Make sure all results have suggested_tags as an array
      const processedResults = results.map(result => ({
        ...result,
        suggested_tags: result.suggested_tags || []
      }));
      setClassificationResults(processedResults);

      // Group similar images based on AI classification
      const classGroups = {};
      results.forEach((result, index) => {
        const className = result.suggested_class || "Unclassified";
        if (!classGroups[className]) {
          classGroups[className] = [];
        }
        classGroups[className].push(index);
      });
      
      // Add insights to chat
      const classCount = Object.keys(classGroups).length;
      let aiMessage = `I've analyzed your images and found ${classCount} main ${
        classCount === 1 ? 'category' : 'categories'
      }: `;
      
      Object.keys(classGroups).forEach((className, idx) => {
        const count = classGroups[className].length;
        aiMessage += `${className} (${count} ${count === 1 ? 'image' : 'images'})`;
        
        if (idx < Object.keys(classGroups).length - 2) {
          aiMessage += ', ';
        } else if (idx === Object.keys(classGroups).length - 2) {
          aiMessage += ' and ';
        }
      });
      
      aiMessage += '. You can now add custom tags, organize them into folders, or save them directly.';
      
      setAiChatMessages(prev => [
        ...prev,
        { from: 'ai', message: aiMessage }
      ]);
      
      // Check for potential improvement suggestions
      const hasLowConfidence = results.some(r => r.confidence && r.confidence < 0.7);
      if (hasLowConfidence) {
        setAiSuggestions(prev => [
          ...prev,
          {
            type: 'model',
            message: 'Some images have low classification confidence. Would you like me to suggest a more specialized model?',
            action: () => handleModelSuggestion(),
            actionText: 'Suggest better model'
          }
        ]);
      }
      
    } catch (error) {
      console.error("Classification Error:", error);
      setError("Error classifying images: " + (error.response?.data?.detail || error.message));
      
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I encountered an error while analyzing your images. ${error.response?.data?.detail || error.message}. Please try again or upload different images.` 
        }
      ]);
    } finally {
      setLoading(false);
      setProcessingStage("");
      setBatchProcessingProgress(0);
    }
  };

  const handleModelSuggestion = () => {
    // In a real implementation, this would analyze the images and suggest
    // an appropriate model based on content
    const specializedModel = models.find(m => 
      !m.name.toLowerCase().includes('general') && 
      !m.name.toLowerCase().includes('default')
    );
    
    if (specializedModel) {
      setSelectedModelId(specializedModel.id);
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I've switched to the "${specializedModel.name}" model which might work better for your images. Would you like me to re-analyze them?` 
        }
      ]);
      
      setAiSuggestions(prev => [
        ...prev,
        {
          type: 'action',
          message: 'Re-analyze with the new model?',
          action: () => classifyImages(),
          actionText: 'Re-analyze'
        }
      ]);
    }
  };

  const saveImages = async () => {
    // Determine which images to save
    const imagesToSave = selectedImages.length > 0 
      ? selectedImages
      : Array.from({ length: classificationResults.length }, (_, i) => i);
      
    if (imagesToSave.length === 0) {
      setError("Please select at least one image to save");
      return;
    }
    
    setLoading(true);
    setError("");
    setProcessingStage("saving");
    
    try {
      if (externalImages.length > 0) {
        // Save external images
        const filteredUrls = imagesToSave.map(idx => externalImages[idx]);
        const filteredClasses = imagesToSave.map(
          idx => userClasses[idx] || commonClass || classificationResults[idx].suggested_class
        );
        const filteredTags = imagesToSave.map(
          idx => classificationResults[idx].suggested_tags || []
        );
        
        const data = {
          image_urls: filteredUrls,
          common_class: commonClass,
          class_names: filteredClasses,
          suggested_tags: filteredTags,
          ...(selectedFolder?.id && { folder_id: selectedFolder.id })
        };

        await api.post("/images/save-from-urls", data);
      } else {
        // Save uploaded files
        const formData = new FormData();
        
        // Add common class and folder
        formData.append("common_class", commonClass || "");
        if (selectedFolder?.id) {
          formData.append("folder_id", selectedFolder.id);
        }
        
        // Add each file with its class
        imagesToSave.forEach(idx => {
          const className = userClasses[idx] || commonClass || classificationResults[idx].suggested_class;
          formData.append("files", files[idx]);
          formData.append("class_names", className);
        });
        
        // Add tags
        formData.append(
          "suggested_tags_json", 
          JSON.stringify(imagesToSave.map(idx => 
            classificationResults[idx].suggested_tags || []
          ))
        );

        await api.post("/images/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      }

      setSuccess(`Successfully saved ${imagesToSave.length} images`);
      
      // Add message to AI chat
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I've saved ${imagesToSave.length} images${
            selectedFolder 
              ? ` to the "${selectedFolder.name}" folder` 
              : ''
          }. Is there anything else you'd like to do?` 
        }
      ]);
      
      // Reset selection state
      setSelectedImages([]);
      
    } catch (error) {
      console.error("Save Images Error:", error);
      setError("Error saving images: " + (error.response?.data?.detail || error.message));
      
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I encountered an error while saving your images: ${error.response?.data?.detail || error.message}` 
        }
      ]);
    } finally {
      setLoading(false);
      setProcessingStage("");
    }
  };

  const handleSelectFolder = (folder) => {
    setSelectedFolder(folder);
    setFolderMenuAnchor(null);
    
    // Add message to chat
    setAiChatMessages(prev => [
      ...prev,
      { 
        from: 'user', 
        message: `I want to save to the "${folder.name}" folder.` 
      },
      { 
        from: 'ai', 
        message: `I've selected the "${folder.name}" folder for saving your images.` 
      }
    ]);
  };

  const handleImageSelection = (index) => {
    setSelectedImages(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handleImageTagClick = (index) => {
    setActiveImageForTags(index);
    setShowTagsDialog(true);
  };

  const handleAddTag = () => {
    if (!newTag.trim() || activeImageForTags === null) return;
    
    const updatedResults = [...classificationResults];
    const imageTags = updatedResults[activeImageForTags].suggested_tags || [];
    
    if (!imageTags.includes(newTag.trim())) {
      updatedResults[activeImageForTags].suggested_tags = [
        ...imageTags,
        newTag.trim()
      ];
      
      setClassificationResults(updatedResults);
      setNewTag("");
    }
  };

  const handleRemoveTag = (index, tag) => {
    const updatedResults = [...classificationResults];
    updatedResults[index].suggested_tags = updatedResults[index].suggested_tags.filter(
      t => t !== tag
    );
    setClassificationResults(updatedResults);
  };

  const handleFolderClick = (event) => {
    setFolderMenuAnchor(event.currentTarget);
  };

  const handleSendAiChat = () => {
    if (!aiChatInput.trim()) return;
    
    // Add user message to chat
    setAiChatMessages(prev => [
      ...prev, 
      { from: 'user', message: aiChatInput }
    ]);
    
    // Process the command (in a real implementation, this would
    // parse natural language and perform actions)
    processAiCommand(aiChatInput);
    
    setAiChatInput("");
  };

  const processAiCommand = (command) => {
    const normalizedCommand = command.toLowerCase();
    
    // Simple example of command processing
    if (normalizedCommand.includes('classify') || normalizedCommand.includes('analyze')) {
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { from: 'ai', message: 'I\'ll classify your images right away.' }
        ]);
        classifyImages();
      }, 500);
    }
    else if (normalizedCommand.includes('save')) {
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { from: 'ai', message: 'I\'ll save your images now.' }
        ]);
        saveImages();
      }, 500);
    }
    else if (normalizedCommand.includes('folder') || normalizedCommand.includes('directory')) {
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { from: 'ai', message: 'Would you like to select a folder for saving your images?' }
        ]);
        
        setAiSuggestions(prev => [
          ...prev,
          {
            type: 'folder',
            message: 'Choose a folder for your images:',
            action: (e) => handleFolderClick(e),
            actionText: 'Select folder'
          }
        ]);
      }, 500);
    }
    else if (normalizedCommand.includes('tag') || normalizedCommand.includes('label')) {
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { from: 'ai', message: 'You can add custom tags to any image by clicking on the tag icon. Would you like me to help with tagging?' }
        ]);
      }, 500);
    }
    else {
      // Generic response for unrecognized commands
      setTimeout(() => {
        setAiChatMessages(prev => [
          ...prev,
          { 
            from: 'ai', 
            message: "I understand you want to do something with your images. You can ask me to classify them, suggest tags, save them, or select a folder." 
          }
        ]);
      }, 500);
    }
  };

  const executeCommand = (command) => {
    setShowCommandPalette(false);
    
    switch(command) {
      case 'classify':
        classifyImages();
        break;
      case 'save':
        saveImages();
        break;
      case 'selectFolder':
        handleFolderClick({ currentTarget: document.querySelector('#folderButton') });
        break;
      case 'createFolder':
        setShowCreateFolderDialog(true);
        break;
      case 'clearAll':
        setFiles([]);
        setExternalImages([]);
        resetClassificationStates();
        break;
      case 'toggleChat':
        setShowAIChat(prev => !prev);
        break;
      case 'toggleConfidence':
        setConfidenceVisualization(prev => !prev);
        break;
      default:
        break;
    }
  };

  // Filtered commands for the command palette
  const getFilteredCommands = () => {
    const commands = [
      { id: 'classify', name: 'Classify Images', icon: <AutoAwesomeIcon /> },
      { id: 'save', name: 'Save Images', icon: <SaveAltIcon /> },
      { id: 'selectFolder', name: 'Select Folder', icon: <FolderIcon /> },
      { id: 'createFolder', name: 'Create New Folder', icon: <CreateIcon /> },
      { id: 'clearAll', name: 'Clear All', icon: <DeleteIcon /> },
      { id: 'toggleChat', name: `${showAIChat ? 'Hide' : 'Show'} AI Chat`, icon: <SmartToyIcon /> },
      { id: 'toggleConfidence', name: `${confidenceVisualization ? 'Hide' : 'Show'} Confidence Scores`, icon: <InfoIcon /> },
    ];
    
    if (!commandInput) return commands;
    
    return commands.filter(cmd => 
      cmd.name.toLowerCase().includes(commandInput.toLowerCase())
    );
  };
  
  // Create a new folder
  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;
    
    setCreatingFolder(true);
    try {
      const folderData = {
        name: newFolderName.trim(),
        parent_folder_id: newFolderParentId
      };
      
      const response = await api.post('/folders', folderData);
      
      // Close dialog and reset form
      setShowCreateFolderDialog(false);
      setNewFolderName("");
      setNewFolderParentId(null);
      
      // Refresh folders
      await fetchFolders();
      
      // Select the newly created folder
      if (response.data && response.data.id) {
        handleSelectFolder(response.data);
      }
      
      // Show success message
      setSuccess(`Folder "${newFolderName.trim()}" created successfully`);
      
    } catch (error) {
      console.error('Error creating folder:', error);
      setError('Failed to create folder: ' + (error.response?.data?.detail || error.message));
    } finally {
      setCreatingFolder(false);
    }
  };
  
  const renderCreateFolderDialog = () => {
    return (
      <Dialog
        open={showCreateFolderDialog}
        onClose={() => setShowCreateFolderDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <TextField
              autoFocus
              label="Folder Name"
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              sx={{ mb: 3 }}
            />
            
            <FormControl fullWidth>
              <InputLabel id="parent-folder-label">Parent Folder</InputLabel>
              <Select
                labelId="parent-folder-label"
                value={newFolderParentId === null ? "" : newFolderParentId}
                label="Parent Folder"
                onChange={(e) => setNewFolderParentId(e.target.value === "" ? null : e.target.value)}
              >
                <MenuItem value="">Root</MenuItem>
                {allFolders.map((folder) => (
                  <MenuItem key={folder.id} value={folder.id}>
                    {folder.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select a parent folder or leave as Root
              </FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCreateFolderDialog(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleCreateFolder}
            disabled={!newFolderName.trim() || creatingFolder}
          >
            {creatingFolder ? (
              <>
                <CircularProgress size={16} sx={{ mr: 1 }} />
                Creating...
              </>
            ) : (
              'Create Folder'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Render processing state visualization based on current stage
  const renderProcessingState = () => {
    return (
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column' 
        }}
        open={loading}
      >
        <Box sx={{ maxWidth: 400, textAlign: 'center' }}>
          <Box sx={{ position: 'relative', width: 200, height: 200, margin: '0 auto' }}>
            <CircularProgress 
              size={120} 
              thickness={3} 
              sx={{ 
                color: '#fff',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-60px',
                marginLeft: '-60px',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-40px',
                marginLeft: '-40px',
                width: 80,
                height: 80,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {processingStage === "initializing" && <FilterNoneIcon sx={{ fontSize: 40 }} />}
              {processingStage === "resizing" && <FilterNoneIcon sx={{ fontSize: 40 }} />}
              {processingStage === "analyzing" && <AutoAwesomeIcon sx={{ fontSize: 40 }} />}
              {processingStage === "finalizing" && <CheckCircleIcon sx={{ fontSize: 40 }} />}
              {processingStage === "saving" && <SaveAltIcon sx={{ fontSize: 40 }} />}
            </Box>
          </Box>
          
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'medium' }}>
            {processingStage === "initializing" && "Initializing AI..."}
            {processingStage === "resizing" && "Optimizing Images..."}
            {processingStage === "analyzing" && "AI Analyzing Images..."}
            {processingStage === "finalizing" && "Finalizing Results..."}
            {processingStage === "saving" && "Saving Images..."}
          </Typography>
          
          {batchProcessingProgress > 0 && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress 
                variant="determinate" 
                value={batchProcessingProgress} 
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: alpha('#fff', 0.2),
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: '#fff'
                  }
                }}
              />
              <Typography variant="body2" sx={{ mt: 1, color: 'rgba(255,255,255,0.8)' }}>
                {batchProcessingProgress}% Complete
              </Typography>
            </Box>
          )}
        </Box>
      </Backdrop>
    );
  };

  // Main upload area when no images are present
  const renderUploadArea = () => {
    return (
      <Box
        sx={{
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: dragActive ? '3px dashed #550FCC' : '3px dashed #ccc',
          borderRadius: 4,
          p: 4,
          m: 2,
          backgroundColor: dragActive ? alpha('#550FCC', 0.05) : 'transparent',
          transition: 'all 0.3s ease'
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <UploadFileIcon 
          sx={{ 
            fontSize: 80, 
            color: dragActive ? '#550FCC' : '#aaa',
            transition: 'all 0.3s ease',
            animation: dragActive ? 'pulse 1.5s infinite' : 'none',
            '@keyframes pulse': {
              '0%': {
                transform: 'scale(1)',
                opacity: 0.7,
              },
              '50%': {
                transform: 'scale(1.05)',
                opacity: 1,
              },
              '100%': {
                transform: 'scale(1)',
                opacity: 0.7,
              },
            },
          }} 
        />
        
        <Typography variant="h5" sx={{ mt: 2, mb: 1, fontWeight: 'medium' }}>
          Drag & drop images here
        </Typography>
        
        <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
          Or click to browse your files
        </Typography>
        
        <Button
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onClick={() => fileInputRef.current.click()}
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: 3,
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 'medium',
            boxShadow: 3,
            backgroundColor: '#550FCC',
            '&:hover': {
              backgroundColor: '#4700B3'
            }
          }}
        >
          Upload Images
        </Button>
        
        <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: 'none' }}
        />
      </Box>
    );
  };

  // Gallery view of classified images
  const renderClassifiedImages = () => {
    return (
      <Box sx={{ my: 4 }}>
        <Grid container spacing={2}>
          {classificationResults.map((result, index) => {
            const isSelected = selectedImages.includes(index);
            const imageUrl = externalImages.length > 0 
              ? externalImages[index] 
              : URL.createObjectURL(files[index]);
              
            const confidence = result.confidence || Math.random() * 0.5 + 0.5; // Fallback for demo
            
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Zoom in={true} style={{ transitionDelay: `${index * 100}ms` }}>
                  <Card 
                    sx={{ 
                      position: 'relative',
                      height: '100%',
                      cursor: 'pointer',
                      transform: isSelected ? 'scale(0.98)' : 'scale(1)',
                      transition: 'transform 0.2s ease',
                      outline: isSelected ? `3px solid ${theme.palette.primary.main}` : 'none',
                      '&:hover': {
                        boxShadow: 6
                      }
                    }}
                    onClick={() => handleImageSelection(index)}
                  >
                    {isSelected && (
                      <CheckCircleIcon 
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          zIndex: 2,
                          color: theme.palette.primary.main,
                          backgroundColor: 'white',
                          borderRadius: '50%'
                        }}
                      />
                    )}
                    
                    <CardMedia
                      component="img"
                      height="160"
                      image={imageUrl}
                      alt={`Image ${index}`}
                      sx={{ objectFit: 'cover' }}
                    />
                    
                    {confidenceVisualization && (
                      <Box 
                        sx={{ 
                          position: 'absolute', 
                          bottom: 0, 
                          left: 0, 
                          right: 0,
                          height: 6,
                          background: `linear-gradient(90deg, 
                            ${confidence < 0.6 ? 'red' : confidence < 0.8 ? 'orange' : 'green'} 
                            ${confidence * 100}%, 
                            rgba(255,255,255,0.3) ${confidence * 100}%)`
                        }}
                      />
                    )}
                    
                    <CardContent>
                      {/* Class name field - editable */}
                      <TextField
                        fullWidth
                        size="small"
                        variant="standard"
                        value={userClasses[index] || ""}
                        onChange={(e) => {
                          const newClasses = [...userClasses];
                          newClasses[index] = e.target.value;
                          setUserClasses(newClasses);
                        }}
                        placeholder={commonClass || result.suggested_class || "Unclassified"}
                        onClick={(e) => e.stopPropagation()}
                        InputProps={{
                          disableUnderline: true,
                          style: { 
                            fontSize: '1rem',
                            fontWeight: 'bold'
                          }
                        }}
                        sx={{ mb: 1 }}
                      />
                      
                      {confidenceVisualization && (
                        <Chip 
                          label={`${Math.round(confidence * 100)}%`}
                          size="small"
                          color={confidence < 0.6 ? 'error' : confidence < 0.8 ? 'warning' : 'success'}
                          sx={{ mb: 1 }}
                        />
                      )}
                      
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                        {(result.suggested_tags || []).slice(0, 3).map((tag, tagIdx) => (
                          <Chip 
                            key={tagIdx} 
                            label={tag}
                            size="small"
                            sx={{ fontWeight: 'normal', fontSize: '0.75rem' }}
                          />
                        ))}
                        
                        {(result.suggested_tags || []).length > 3 && (
                          <Chip 
                            label={`+${(result.suggested_tags || []).length - 3} more`}
                            size="small"
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleImageTagClick(index);
                            }}
                            sx={{ fontWeight: 'normal', fontSize: '0.75rem' }}
                          />
                        )}
                      </Box>
                      
                      <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end' }}>
                        <IconButton 
                          size="small" 
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageTagClick(index);
                          }}
                        >
                          <TagIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                </Zoom>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  // AI chat interface
  const renderAiChat = () => {
    return (
      <Slide direction="up" in={showAIChat} mountOnEnter unmountOnExit>
        <Paper 
          elevation={3}
          sx={{
            position: 'fixed',
            bottom: 0,
            right: 24,
            width: isMobile ? '90vw' : 350,
            height: 500,
            maxHeight: '80vh',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12
          }}
        >
          <Box sx={{ 
            backgroundColor: '#550FCC', 
            color: 'white',
            px: 2,
            py: 1.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SmartToyIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                AI Assistant
              </Typography>
            </Box>
            <IconButton color="inherit" onClick={() => setShowAIChat(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            p: 2,
            gap: 2
          }}>
            {aiChatMessages.map((message, idx) => (
              <Box 
                key={idx}
                sx={{
                  alignSelf: message.from === 'ai' ? 'flex-start' : 'flex-end',
                  maxWidth: '80%'
                }}
              >
                <Paper 
                  elevation={0}
                  sx={{
                    p: 1.5,
                    bgcolor: message.from === 'ai' ? '#f0f0f0' : '#e6f5ff',
                    borderRadius: 2,
                    borderTopLeftRadius: message.from === 'ai' ? 0 : 2,
                    borderTopRightRadius: message.from === 'ai' ? 2 : 0,
                  }}
                >
                  <Typography variant="body2">
                    {message.message}
                  </Typography>
                </Paper>
              </Box>
            ))}
            
            {aiSuggestions.map((suggestion, idx) => (
              <Box 
                key={`suggestion-${idx}`}
                sx={{
                  alignSelf: 'flex-start',
                  maxWidth: '80%'
                }}
              >
                <Paper 
                  elevation={0}
                  sx={{
                    p: 1.5,
                    bgcolor: '#f0f0f0',
                    borderRadius: 2,
                    borderTopLeftRadius: 0,
                  }}
                >
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {suggestion.message}
                  </Typography>
                  
                  <Button
                    variant="contained"
                    size="small"
                    onClick={(e) => suggestion.action(e)}
                    sx={{ 
                      textTransform: 'none',
                      fontWeight: 'normal',
                      boxShadow: 0
                    }}
                  >
                    {suggestion.actionText}
                  </Button>
                </Paper>
              </Box>
            ))}
            
            <div ref={chatEndRef} />
          </Box>
          
          <Box sx={{ 
            p: 2, 
            borderTop: '1px solid rgba(0,0,0,0.1)',
            display: 'flex',
            gap: 1
          }}>
            <TextField
              fullWidth
              placeholder="Ask me anything..."
              value={aiChatInput}
              onChange={(e) => setAiChatInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendAiChat();
                }
              }}
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton 
                      edge="end"
                      onClick={handleSendAiChat}
                      disabled={!aiChatInput.trim()}
                      color="primary"
                      size="small"
                    >
                      <SendIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 4,
                }
              }}
            />
          </Box>
        </Paper>
      </Slide>
    );
  };

  // Generate additional tags for a specific image
  const generateCustomTags = async (index) => {
    if (!isClassified || !classificationResults[index]) return;
    
    setGeneratingTags(true);
    setError("");
    
    try {
      if (externalImages.length > 0) {
        // For external images, use the URL directly
        const requestData = {
          image_urls: [externalImages[index]],
          tag_count: tagCount,
          tag_prompt: tagPrompt,
          existing_tags: [classificationResults[index].suggested_tags || []],
          exclude_existing: true
        };
        
        const response = await api.post("/images/generate-tags", requestData);
        
        if (response.data && response.data.results && response.data.results.length > 0) {
          const generatedTags = response.data.results[0].generated_tags || [];
          
          // Add new tags to the current image's tags
          const updatedResults = [...classificationResults];
          const currentTags = updatedResults[index].suggested_tags || [];
          updatedResults[index].suggested_tags = [...currentTags, ...generatedTags];
          setClassificationResults(updatedResults);
          
          // Add message to chat
          setAiChatMessages(prev => [
            ...prev,
            { 
              from: 'ai', 
              message: `I've generated ${generatedTags.length} additional tags for this image.` 
            }
          ]);
        }
      } else {
        // For local files, create a FormData and send the file directly
        const formData = new FormData();
        formData.append("file", files[index]);
        formData.append("tag_count", tagCount.toString());
        formData.append("tag_prompt", tagPrompt || "");
        formData.append("existing_tags_json", JSON.stringify(classificationResults[index].suggested_tags || []));
        formData.append("exclude_existing", "true");
        
        const response = await api.post("/images/generate-tags-for-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        
        if (response.data && response.data.generated_tags) {
          const generatedTags = response.data.generated_tags || [];
          
          // Add new tags to the current image's tags
          const updatedResults = [...classificationResults];
          const currentTags = updatedResults[index].suggested_tags || [];
          updatedResults[index].suggested_tags = [...currentTags, ...generatedTags];
          setClassificationResults(updatedResults);
          
          // Add message to chat
          setAiChatMessages(prev => [
            ...prev,
            { 
              from: 'ai', 
              message: `I've generated ${generatedTags.length} additional tags for this image.` 
            }
          ]);
        }
      }
    } catch (error) {
      console.error("Error generating custom tags:", error);
      setError("Failed to generate custom tags: " + (error.response?.data?.detail || error.message));
    } finally {
      setGeneratingTags(false);
    }
  };

  // Generate tags for all images at once
  const generateTagsForAllImages = async () => {
    if (!isClassified || classificationResults.length === 0) return;
    
    setGeneratingTags(true);
    setError("");
    
    try {
      // Add message to chat
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I'm generating additional tags for all images using the prompt: "${tagPrompt || 'general image features'}"...` 
        }
      ]);
      
      if (externalImages.length > 0) {
        // For external images, use the URLs directly
        const requestData = {
          image_urls: externalImages,
          tag_count: tagCount,
          tag_prompt: tagPrompt,
          existing_tags: classificationResults.map(result => result.suggested_tags || []),
          exclude_existing: true
        };
        
        const response = await api.post("/images/generate-tags", requestData);
        
        if (response.data && response.data.results) {
          const updatedResults = [...classificationResults];
          
          response.data.results.forEach((result, index) => {
            if (result.generated_tags && result.generated_tags.length > 0) {
              const currentTags = updatedResults[index].suggested_tags || [];
              updatedResults[index].suggested_tags = [...currentTags, ...result.generated_tags];
            }
          });
          
          setClassificationResults(updatedResults);
          
          // Add success message to chat
          setAiChatMessages(prev => [
            ...prev,
            { 
              from: 'ai', 
              message: `I've added new tags to all images based on your criteria.` 
            }
          ]);
        }
      } else {
        // For local files, use the form data approach
        const formData = new FormData();
        
        for (const file of files) {
          formData.append("files", file);
        }
        
        formData.append("tag_count", tagCount.toString());
        formData.append("tag_prompt", tagPrompt || "");
        formData.append("existing_tags_json", JSON.stringify(classificationResults.map(result => result.suggested_tags || [])));
        formData.append("exclude_existing", "true");
        
        const response = await api.post("/images/generate-tags-for-files", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        
        if (response.data && response.data.results) {
          const updatedResults = [...classificationResults];
          
          response.data.results.forEach((result, index) => {
            if (result.generated_tags && result.generated_tags.length > 0) {
              const currentTags = updatedResults[index].suggested_tags || [];
              updatedResults[index].suggested_tags = [...currentTags, ...result.generated_tags];
            }
          });
          
          setClassificationResults(updatedResults);
          
          // Add success message to chat
          setAiChatMessages(prev => [
            ...prev,
            { 
              from: 'ai', 
              message: `I've added new tags to all images based on your criteria.` 
            }
          ]);
        }
      }
    } catch (error) {
      console.error("Error generating tags for all images:", error);
      setError("Failed to generate tags: " + (error.response?.data?.detail || error.message));
      
      // Add error message to chat
      setAiChatMessages(prev => [
        ...prev,
        { 
          from: 'ai', 
          message: `I encountered an error while generating tags: ${error.response?.data?.detail || error.message}` 
        }
      ]);
    } finally {
      setGeneratingTags(false);
    }
  };

  // Advanced tags dialog
  const renderTagsDialog = () => {
    if (activeImageForTags === null) return null;
    
    const imageTags = classificationResults[activeImageForTags]?.suggested_tags || [];
    const imageUrl = externalImages.length > 0 
      ? externalImages[activeImageForTags] 
      : files[activeImageForTags] ? URL.createObjectURL(files[activeImageForTags]) : '';
    const imageClass = classificationResults[activeImageForTags]?.suggested_class || 'Unclassified';
      
    return (
      <Dialog 
        open={showTagsDialog} 
        onClose={() => setShowTagsDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Edit Tags</Typography>
            <IconButton onClick={() => setShowTagsDialog(false)} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        
        <DialogContent dividers>
          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <img 
              src={imageUrl} 
              alt={`Image ${activeImageForTags}`}
              style={{ 
                width: 100, 
                height: 100, 
                objectFit: 'cover',
                borderRadius: 8
              }}
            />
            
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {imageClass}
              </Typography>
              
              <Typography variant="body2" color="textSecondary">
                {externalImages.length > 0 
                  ? `URL: ${externalImages[activeImageForTags]}` 
                  : `File: ${files[activeImageForTags]?.name}`
                }
              </Typography>
            </Box>
          </Box>
          
          <Box sx={{ mb: 3 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AutoAwesomeIcon />}
              onClick={() => generateCustomTags(activeImageForTags)}
              disabled={generatingTags}
              fullWidth
              sx={{ borderRadius: 2, mb: 1 }}
            >
              {generatingTags ? (
                <>
                  <CircularProgress size={16} sx={{ mr: 1 }} />
                  Generating Tags...
                </>
              ) : (
                "Generate Additional Tags"
              )}
            </Button>
            
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                size="small"
                label="Number of tags"
                type="number"
                value={tagCount}
                onChange={(e) => setTagCount(Math.max(1, parseInt(e.target.value) || 5))}
                inputProps={{ min: 1, max: 20 }}
                sx={{ width: '30%' }}
              />
              <TextField
                size="small"
                label="Custom prompt (optional)"
                value={tagPrompt}
                onChange={(e) => setTagPrompt(e.target.value)}
                sx={{ width: '70%' }}
              />
            </Box>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Current Tags:</Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
            {imageTags.length > 0 ? (
              imageTags.map((tag, idx) => (
                <Chip 
                  key={idx}
                  label={tag}
                  onDelete={() => handleRemoveTag(activeImageForTags, tag)}
                  color="primary"
                  variant="outlined"
                />
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No tags yet. Add some below.
              </Typography>
            )}
          </Box>
          
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              fullWidth
              label="Add new tag"
              variant="outlined"
              size="small"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddTag();
                }
              }}
            />
            
            <Button 
              variant="contained"
              onClick={handleAddTag}
              disabled={!newTag.trim()}
            >
              Add
            </Button>
          </Box>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => setShowTagsDialog(false)}>Done</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Folder selection menu
  const renderFolderMenu = () => {
    return (
      <Menu
        anchorEl={folderMenuAnchor}
        open={Boolean(folderMenuAnchor)}
        onClose={() => setFolderMenuAnchor(null)}
        PaperProps={{
          style: {
            maxHeight: 400,
            width: 300,
          },
        }}
      >
        <MenuItem 
          sx={{ borderBottom: '1px solid', borderColor: 'divider', pb: 2 }}
          onClick={() => {
            setFolderMenuAnchor(null);
            setShowCreateFolderDialog(true);
          }}
        >
          <AddCircleIcon fontSize="small" sx={{ mr: 1, color: 'success.main' }} />
          Create New Folder
        </MenuItem>
        
        <MenuItem onClick={() => handleSelectFolder({ id: null, name: 'Root' })}>
          <FolderIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
          Root
        </MenuItem>
        
        {renderFolderItems(allFolders, null, 0)}
      </Menu>
    );
  };
  
  // Helper function to render folder items with proper hierarchy
  const renderFolderItems = (folders, parentId, depth) => {
    return folders
      .filter(folder => folder.parent_folder_id === parentId)
      .map(folder => (
        <React.Fragment key={folder.id}>
          <MenuItem 
            onClick={() => handleSelectFolder(folder)}
            sx={{ 
              pl: 2 + depth * 2, 
              py: 1,
              borderLeft: depth > 0 ? '1px dashed' : 'none',
              borderColor: 'divider',
              ml: depth > 0 ? 2 : 0
            }}
          >
            <FolderIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
            {folder.name}
          </MenuItem>
          {/* Recursively render children */}
          {renderFolderItems(folders, folder.id, depth + 1)}
        </React.Fragment>
      ));
  };

  // Command palette dialog
  const renderCommandPalette = () => {
    return (
      <Dialog
        open={showCommandPalette}
        onClose={() => setShowCommandPalette(false)}
        PaperProps={{
          sx: {
            width: 400,
            maxWidth: '90vw',
            borderRadius: 2,
            boxShadow: 24
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <KeyboardCommandKeyIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <TextField
              fullWidth
              autoFocus
              placeholder="Search for commands..."
              variant="standard"
              InputProps={{ disableUnderline: true }}
              value={commandInput}
              onChange={(e) => setCommandInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setShowCommandPalette(false);
                }
              }}
            />
          </Box>
          
          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
            {getFilteredCommands().map((command) => (
              <Box
                key={command.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1.5,
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'action.hover'
                  }
                }}
                onClick={() => executeCommand(command.id)}
              >
                <Box sx={{ mr: 2, color: 'text.secondary' }}>
                  {command.icon}
                </Box>
                <Typography variant="body1">{command.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Dialog>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 4, sm: 8 }, mb: 8 }}>
      {/* Global notifications */}
      <Snackbar 
        open={Boolean(error)} 
        autoHideDuration={6000} 
        onClose={() => setError("")}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError("")}>
          {error}
        </Alert>
      </Snackbar>
      
      <Snackbar 
        open={Boolean(success)} 
        autoHideDuration={6000} 
        onClose={() => setSuccess("")}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={() => setSuccess("")}>
          {success}
        </Alert>
      </Snackbar>
      
      {/* Header section */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        mb: 4
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <EmojiObjectsIcon 
            sx={{ 
              fontSize: 40, 
              color: '#550FCC',
              mr: 2
            }} 
          />
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold', 
              color: '#550FCC',
              fontSize: { xs: '1.5rem', sm: '2rem' }
            }}
          >
            Smart Image Manager
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1, mt: { xs: 2, sm: 0 } }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SmartToyIcon />}
            onClick={() => setShowAIChat(prev => !prev)}
            sx={{ borderRadius: 8, textTransform: 'none' }}
          >
            {showAIChat ? 'Hide Assistant' : 'AI Assistant'}
          </Button>
          
          <Tooltip title="Press ⌘K for commands">
            <IconButton 
              color="primary" 
              onClick={() => setShowCommandPalette(true)}
              sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 2 }}
            >
              <KeyboardCommandKeyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      
      {/* Model selection and tag options - Always visible */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3, 
          borderRadius: 3,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', gap: 3 }}>
          {/* AI Model Selection */}
          <Box sx={{ minWidth: '250px', flex: '1 1 20%' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <AutoAwesomeIcon sx={{ fontSize: 18, mr: 1 }} />
                AI Model Selection
              </Typography>
            </Box>
            
            <FormControl fullWidth size="small">
              <InputLabel id="model-select-label">Classification Model</InputLabel>
              <Select
                labelId="model-select-label"
                value={selectedModelId}
                label="Classification Model"
                onChange={(e) => setSelectedModelId(e.target.value)}
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="">
                  <em>Default Classification</em>
                </MenuItem>
                {models.map((model) => (
                  <MenuItem key={model.id} value={model.id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select a specific model for your content
              </FormHelperText>
            </FormControl>
          </Box>
          
          {/* Tag Generation Options */}
          <Box sx={{ flex: '1 1 40%', minWidth: '300px' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TagIcon sx={{ fontSize: 18, mr: 1 }} />
                Tag Generation Options
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                label="Number"
                variant="outlined"
                type="number"
                value={tagCount}
                onChange={(e) => setTagCount(Math.max(1, parseInt(e.target.value) || 5))}
                inputProps={{ min: 1, max: 20 }}
                size="small"
                sx={{ width: '100px' }}
              />
              <TextField
                fullWidth
                label="Custom tag prompt (optional)"
                variant="outlined"
                size="small"
                value={tagPrompt}
                onChange={(e) => setTagPrompt(e.target.value)}
                placeholder="E.g., Generate technical tags, focus on architectural styles..."
              />
            </Box>
            <FormHelperText>
              Customize how tags are generated by AI
            </FormHelperText>
          </Box>
          
          {/* Auto-classify Toggle */}
          <Box sx={{ flex: '1 1 20%', minWidth: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ visibility: 'hidden', display: 'flex', alignItems: 'center', mb: 1 }}>
                <TagIcon sx={{ fontSize: 18, mr: 1 }} />
                Placeholder
              </Typography>
            </Box>
            
            <FormControl component="fieldset">
              <Typography variant="body1" sx={{ mb: 1 }}>
                Auto-classify on upload
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  checked={autoClassifyOnUpload}
                  onChange={(e) => setAutoClassifyOnUpload(e.target.checked)}
                  color="primary"
                />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {autoClassifyOnUpload ? 'On' : 'Off'}
                </Typography>
              </Box>
            </FormControl>
          </Box>
        </Box>
      </Paper>
      
      {/* Main content container */}
      <Paper 
        elevation={0} 
        sx={{ 
          borderRadius: 4, 
          overflow: 'hidden',
          border: '1px solid',
          borderColor: 'divider'
        }}
      >
        {/* Upload area or gallery */}
        {files.length === 0 && externalImages.length === 0 ? (
          renderUploadArea()
        ) : (
          <>
            {/* Action bar */}
            <Box sx={{ 
              p: 2, 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'divider',
              flexWrap: 'wrap',
              gap: 1
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CollectionsIcon sx={{ color: 'text.secondary' }} />
                <Typography variant="subtitle1">
                  {externalImages.length > 0 
                    ? `${externalImages.length} external images` 
                    : `${files.length} uploaded files`
                  }
                  {selectedImages.length > 0 && ` (${selectedImages.length} selected)`}
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {isClassified ? (
                  <>
                    <Button
                      id="folderButton"
                      variant="outlined"
                      startIcon={selectedFolder ? <FolderOpenIcon /> : <FolderIcon />}
                      onClick={handleFolderClick}
                      sx={{ borderRadius: 8 }}
                    >
                      {selectedFolder ? selectedFolder.name : 'Select Folder'}
                    </Button>
                    
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveAltIcon />}
                      onClick={saveImages}
                      disabled={loading}
                      sx={{ 
                        borderRadius: 8,
                        backgroundColor: '#550FCC',
                        '&:hover': {
                          backgroundColor: '#4700B3'
                        }
                      }}
                    >
                      Save Images
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AutoAwesomeIcon />}
                    onClick={() => classifyImages()}
                    disabled={loading}
                    sx={{ 
                      borderRadius: 8,
                      backgroundColor: '#550FCC',
                      '&:hover': {
                        backgroundColor: '#4700B3'
                      }
                    }}
                  >
                    Analyze with AI
                  </Button>
                )}
                
                <IconButton
                  onClick={() => {
                    setFiles([]);
                    setExternalImages([]);
                    resetClassificationStates();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            
            {/* Gallery content */}
            <Box sx={{ p: 2 }}>
              {isClassified ? (
                <>
                  {/* Batch Class and Tag Controls */}
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      mb: 3, 
                      p: 2,
                      borderRadius: 3,
                      border: '1px solid',
                      borderColor: theme.palette.primary.light,
                      backgroundColor: alpha(theme.palette.primary.light, 0.05)
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                        <AutoAwesomeIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                        Batch Operations
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 3 }}>
                      {/* Common Class Field */}
                      <TextField
                        label="Common class for all images (optional)"
                        variant="outlined"
                        value={commonClass}
                        onChange={(e) => setCommonClass(e.target.value)}
                        fullWidth
                        size="small"
                        placeholder="Override classification for all images"
                      />
                    </Box>
                    
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: { xs: 'column', sm: 'row' }, 
                      gap: 2,
                      alignItems: 'center'
                    }}>
                      <TextField
                        label="Tag prompt"
                        placeholder="E.g., 'Focus on architectural details' or 'Identify design elements'"
                        value={tagPrompt}
                        onChange={(e) => setTagPrompt(e.target.value)}
                        fullWidth
                        size="small"
                        sx={{ flexGrow: 1 }}
                      />
                      
                      <TextField
                        label="Tag count"
                        type="number"
                        value={tagCount}
                        onChange={(e) => setTagCount(Math.max(1, parseInt(e.target.value) || 5))}
                        inputProps={{ min: 1, max: 20 }}
                        size="small"
                        sx={{ width: { xs: '100%', sm: '150px' } }}
                      />
                      
                      <Button
                        variant="contained"
                        onClick={generateTagsForAllImages}
                        disabled={generatingTags}
                        startIcon={generatingTags ? <CircularProgress size={16} /> : <AddCircleIcon />}
                        sx={{ 
                          borderRadius: 8,
                          backgroundColor: theme.palette.primary.main,
                          whiteSpace: 'nowrap',
                          minWidth: '160px',
                          px: 2,
                          '& .MuiButton-startIcon': {
                            mr: 1
                          }
                        }}
                      >
                        {generatingTags ? 'Generating...' : 'Generate Tags'}
                      </Button>
                    </Box>
                  </Paper>
                  
                  {renderClassifiedImages()}
                </>
              ) : (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  flexDirection: 'column',
                  minHeight: '30vh',
                  p: 4
                }}>
                  <AutoAwesomeIcon sx={{ fontSize: 60, color: '#550FCC', mb: 2 }} />
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Ready to Analyze
                  </Typography>
                  <Typography variant="body1" color="textSecondary" align="center" sx={{ mb: 3, maxWidth: 600 }}>
                    Click "Analyze with AI" to classify your images and generate tags. 
                    Our AI will automatically detect the content and suggest the most accurate categories.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AutoAwesomeIcon />}
                    onClick={() => classifyImages()}
                    sx={{ 
                      borderRadius: 8,
                      px: 4,
                      backgroundColor: '#550FCC',
                      '&:hover': {
                        backgroundColor: '#4700B3'
                      }
                    }}
                  >
                    Analyze with AI
                  </Button>
                </Box>
              )}
            </Box>
          </>
        )}
      </Paper>
      
      {/* Additional elements */}
      {renderAiChat()}
      {renderProcessingState()}
      {renderTagsDialog()}
      {renderFolderMenu()}
      {renderCommandPalette()}
      {renderCreateFolderDialog()}
    </Container>
  );
};

export default ClassifyAndSaveImages;