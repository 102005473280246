import React, { useState } from 'react';
import { 
  ImageList, 
  ImageListItem, 
  Box, 
  Typography, 
  Chip,
  Popover,
  IconButton
} from '@mui/material';
import { Folder, Maximize, PlayCircle } from 'lucide-react';

const EnhancedVideoList = ({ 
  items, 
  onVideoClick, 
  onVideoSelect, 
  selectedVideos, 
  isSmallScreen,
  onContextMenu
}) => {
  const [expandedTags, setExpandedTags] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  // Helper function to format file size
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Helper function to format video duration
  const formatDuration = (seconds) => {
    if (!seconds) return '0:00';
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleTagsExpand = (event, tags) => {
    setExpandedTags(tags);
    setAnchorEl(event.currentTarget);
  };

  const handleTagsClose = () => {
    setExpandedTags(null);
    setAnchorEl(null);
  };

  return (
    <>
      <ImageList cols={isSmallScreen ? 2 : 4} gap={16}>
        {items.map((result) => (
          <ImageListItem 
            key={result.video_url}
            sx={{ 
              '&:hover': {
                '.video-info': {
                  opacity: 1,
                },
                '.video-actions': {
                  opacity: 1
                }
              }
            }}
            onContextMenu={(e) => onContextMenu(e, result.video_url)}
          >
            {/* Video thumbnail container with overlay */}
            <Box 
              sx={{ 
                position: 'relative',
                borderRadius: '8px',
                overflow: 'hidden',
                aspectRatio: '16/9',
                mb: 1,
                bgcolor: 'grey.100',
                cursor: 'pointer'
              }}
              onClick={(e) => {
                e.stopPropagation();
                onVideoSelect(result.video_url);
              }}
            >
              <img
                src={result.thumbnail_url}
                alt={result.class_label || 'Video'}
                loading="lazy"
                style={{ 
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />

              {/* Duration overlay in top-right */}
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  bgcolor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  fontSize: '0.7rem'
                }}
              >
                {formatDuration(result.duration)}
              </Typography>

              {/* Resolution indicator */}
              {result.resolution && (
                <Typography
                  variant="caption"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    bgcolor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    fontSize: '0.7rem'
                  }}
                >
                  {result.resolution[0]}x{result.resolution[1]}
                </Typography>
              )}

              {/* Play button icon overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: 'rgba(0, 0, 0, 0.1)',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                  '&:hover': {
                    opacity: 1
                  }
                }}
              >
                <PlayCircle size={48} color="#fff" />
              </Box>

              {/* View/expand button */}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onVideoClick(result.video_url);
                }}
                className="video-actions"
                sx={{
                  position: 'absolute',
                  right: 8,
                  bottom: 8,
                  color: 'white',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  p: '6px',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                  },
                  zIndex: 2
                }}
                size="small"
              >
                <Maximize size={18} />
              </IconButton>

              {/* Selection overlay - visual indicator only */}
              {selectedVideos.includes(result.video_url) && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: 'rgba(25, 118, 210, 0.2)',
                    border: '2px solid',
                    borderColor: 'primary.main',
                    borderRadius: '7px'
                  }}
                />
              )}
            </Box>

            {/* Video information */}
            <Box className="video-info" sx={{ px: 1 }}>
              {/* Title */}
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  fontWeight: 500,
                  mb: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {result.class_label || 'Untitled'}
              </Typography>

              {/* File size if available */}
              {result.size && (
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ display: 'block', mb: 0.5 }}
                >
                  {formatFileSize(result.size)}
                </Typography>
              )}

              {/* Tags */}
              {result.tags && result.tags.length > 0 && (
                <Box sx={{ mb: 1, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                  {result.tags.slice(0, 3).map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      size="small"
                      sx={{ 
                        height: '20px',
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.75rem'
                        },
                        bgcolor: 'grey.100'
                      }}
                    />
                  ))}
                  {result.tags.length > 3 && (
                    <Typography
                      variant="caption"
                      color="primary"
                      sx={{ 
                        alignSelf: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                      onClick={(e) => handleTagsExpand(e, result.tags)}
                    >
                      +{result.tags.length - 3}
                    </Typography>
                  )}
                </Box>
              )}

              {/* Folder Info */}
              {result.folder_name && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Folder size={16} strokeWidth={1.5} />
                  <Typography 
                    variant="caption" 
                    color="text.secondary"
                    sx={{
                      maxWidth: '90%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {result.folder_name}
                  </Typography>
                </Box>
              )}
            </Box>
          </ImageListItem>
        ))}
      </ImageList>

      {/* Tags Popover */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleTagsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 300 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>All Tags</Typography>
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            {expandedTags?.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                size="small"
                sx={{ 
                  height: '24px',
                  '& .MuiChip-label': {
                    px: 1.5
                  }
                }}
              />
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default EnhancedVideoList;