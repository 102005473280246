import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Paper,
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  Grid,
  Slide,
  Zoom,
  Fade,
  Collapse,
  Stack,
  Tab,
  Tabs,
  ButtonBase,
  alpha,
  LinearProgress,
  Dialog,
  ListItemButton,
  styled,
  useTheme,
  useMediaQuery,
  keyframes,
} from "@mui/material";
import {
  Send as SendIcon,
  AttachFile as AttachFileIcon,
  Search as SearchIcon,
  Image as ImageIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  OpenInNew as OpenInNewIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PictureAsPdf as PictureAsPdfIcon,
  History as HistoryIcon,
  Add as AddIcon,
  ChatBubbleOutline,
  Close,
} from "@mui/icons-material";
import { Sparkles, Wand2 } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import api from "../api";
import AuthContext from "../context/AuthContext";
import ReactMarkdown from "react-markdown";
import ConversationHistory from "./ConversationHistory"; // Make sure this component is defined
import DesignActionPanel from "./DesignActionPanel";
import QuickActionsPanel from "./QuickActionsPanel"; // Imported as per instruction

// Styled Components
const RootContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(4),
  height: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  gap: theme.spacing(2),
  height: "100%",
  overflow: "hidden",
}));

const MessageBubble = styled(Paper)(({ isUser, theme }) => ({
  padding: theme.spacing(2),
  maxWidth: "70%",
  borderRadius: isUser ? "20px 20px 4px 20px" : "20px 20px 20px 4px",
  backgroundColor: isUser ? theme.palette.primary.light : theme.palette.grey[100],
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
  position: "relative",
  "&:hover": {
    "& .message-actions": {
      opacity: 1,
    },
  },
}));

const ScrollButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  borderRadius: "50%",
  minWidth: "auto",
  width: 40,
  height: 40,
  padding: 0,
}));

const FilePreviewCarousel = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  overflowX: "auto",
  padding: theme.spacing(1),
  "&::-webkit-scrollbar": {
    height: 6,
  },
  "&::-webkit-scrollbar-track": {
    background: theme.palette.grey[200],
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey[400],
    borderRadius: 3,
  },
}));

// Enhanced Thinking Indicator Component
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
`;

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

// Styled components
const ThinkingWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '500px',
  margin: theme.spacing(1, 0),
}));

const ThinkingCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  borderRadius: 24,
  overflow: 'hidden',
  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.08)} 0%, ${alpha(theme.palette.primary.light, 0.08)} 100%)`,
  backdropFilter: 'blur(8px)',
  boxShadow: theme.shadows[2],
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.light, 0.1)} 100%)`,
    opacity: 0.8,
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,
  color: theme.palette.primary.contrastText,
}));

const ContentWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  position: 'relative',
  zIndex: 1,
});

const TextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
});

const DotsWrapper = styled(Box)({
  display: 'flex',
  gap: 4,
  marginLeft: 8,
});

const LoadingDot = styled(Box)(({ theme, delay = 0 }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  animation: `${bounce} 1s infinite`,
  animationDelay: `${delay}ms`,
}));

const ThinkingIndicator = () => {
  const messages = [
    "Generating design insights...",
    "Exploring creative possibilities...",
    "Analyzing design elements...",
    "Processing visual patterns..."
  ];

  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prev) => (prev + 1) % messages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ThinkingWrapper>
      <ThinkingCard>
        <ContentWrapper>
          <IconWrapper>
            <Wand2 size={24} />
          </IconWrapper>
          
          <TextWrapper>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600, 
                  color: 'primary.main',
                  letterSpacing: '0.015em',
                }}
              >
                Design Assistant
              </Typography>
              <Typography 
                component="span" 
                sx={{ 
                  color: 'primary.main',
                  animation: `${pulse} 2s infinite ease-in-out`,
                }}
              >
                ✨
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography 
                variant="body2" 
                sx={{ color: (theme) => alpha(theme.palette.primary.main, 0.8) }}
              >
                {messages[messageIndex]}
              </Typography>
              <DotsWrapper>
                {[0, 1, 2].map((i) => (
                  <LoadingDot key={i} delay={i * 150} />
                ))}
              </DotsWrapper>
            </Box>
          </TextWrapper>
        </ContentWrapper>
      </ThinkingCard>
    </ThinkingWrapper>
  );
};


// File Preview Component
const FilePreview = ({ file, preview, onRemove }) => {
  const isImage = file.type.startsWith("image/");
  const theme = useTheme();

  return (
    <Zoom in={true}>
      <Paper
        elevation={2}
        sx={{
          position: "relative",
          width: 100,
          height: 100,
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        {isImage ? (
          <img
            src={preview}
            alt={file.name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: theme.palette.grey[100],
            }}
          >
            <PictureAsPdfIcon color="error" />
            <Typography variant="caption" sx={{ mt: 0.5 }}>
              PDF
            </Typography>
          </Box>
        )}
        <IconButton
          size="small"
          onClick={() => onRemove(file)}
          sx={{
            position: "absolute",
            top: 4,
            right: 4,
            bgcolor: "rgba(255,255,255,0.8)",
            "&:hover": {
              bgcolor: "rgba(255,255,255,0.9)",
            },
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Paper>
    </Zoom>
  );
};

// Helper function to resize an image
const resizeImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const MAX_DIMENSION = 2048;
        let width = img.width;
        let height = img.height;

        if (width <= MAX_DIMENSION && height <= MAX_DIMENSION) {
          resolve(file);
          return;
        }

        if (width > height) {
          if (width > MAX_DIMENSION) {
            height = Math.round((height * MAX_DIMENSION) / width);
            width = MAX_DIMENSION;
          }
        } else {
          if (height > MAX_DIMENSION) {
            width = Math.round((width * MAX_DIMENSION) / height);
            height = MAX_DIMENSION;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const resizedFile = new File([blob], file.name, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          },
          "image/jpeg",
          0.9
        );
      };
    };
  });
};

// Function to render attachments
const renderAttachment = (att) => {
  if (att.type.startsWith("image")) {
    const imageUrl = att.url || att.original_url || att.processed_url;
    return (
      <img
        src={imageUrl}
        alt="Attached"
        style={{
          maxWidth: "200px",
          maxHeight: "200px",
          borderRadius: "4px",
        }}
      />
    );
  } else if (att.type === "pdf") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: 1,
          border: "1px solid #ccc",
          borderRadius: "4px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <PictureAsPdfIcon color="error" />
        <Typography variant="body2" color="textPrimary">
          PDF Document
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          href={att.url}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<OpenInNewIcon />}
        >
          View
        </Button>
      </Box>
    );
  }
  return null;
};

// Message content component
const MessageContent = ({ content, onSearch, onGenerate, processing }) => {
  const { text, searchSuggestions, generationPrompts, enhancedImages } = parseModelResponse(content);

  return (
    <Box>
      <ReactMarkdown>{text}</ReactMarkdown>
      {processing && <ProcessingStatus {...processing} />}
      {(searchSuggestions?.length > 0 || generationPrompts?.length > 0) && (
        <Box sx={{ mt: 2 }}>
          {searchSuggestions?.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="primary" sx={{ mb: 1 }}>
                Reference Images:
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {searchSuggestions.map((query, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    size="small"
                    onClick={() => onSearch(query)}
                    startIcon={<SearchIcon />}
                    sx={{ textTransform: "none" }}
                  >
                    {query}
                  </Button>
                ))}
              </Box>
            </Box>
          )}
          {generationPrompts?.length > 0 && (
            <Box>
              <Typography variant="subtitle2" color="primary" sx={{ mb: 1 }}>
                Generate Elements:
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {generationPrompts.map((prompt, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    size="small"
                    onClick={() => onGenerate(prompt)}
                    startIcon={<ImageIcon />}
                    sx={{ textTransform: "none" }}
                  >
                    {prompt}
                  </Button>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

// Message Component
const Message = ({ message, onSearch, onGenerate }) => {
  const theme = useTheme();
  const isUser = message.role === "user";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: isUser ? "flex-end" : "flex-start",
        mb: 2,
      }}
    >
      <MessageBubble isUser={isUser}>
        {message.attachments && message.attachments.length > 0 && (
          <FilePreviewCarousel>
            {message.attachments.map((att, idx) => (
              <Box key={idx} sx={{ minWidth: 100 }}>
                {renderAttachment(att)}
              </Box>
            ))}
          </FilePreviewCarousel>
        )}
        {isUser ? (
          <Typography>{message.content}</Typography>
        ) : (
          <MessageContent
            content={message.content}
            onSearch={onSearch}
            onGenerate={onGenerate}
            processing={message.processing}
          />
        )}
      </MessageBubble>
    </Box>
  );
};

// Parse model response helper function
const parseModelResponse = (response) => {
  const searchSuggestionsSet = new Set();
  const generationPromptsSet = new Set();
  const enhancedImages = [];
  let cleanText = response;

  // Parse Search Suggestions with quotes
  const searchPattern = /Search Suggestion:\s*["']([^"']+)["']/gi;
  let match;
  while ((match = searchPattern.exec(response))) {
    searchSuggestionsSet.add(match[1]);
  }

  // Also handle search suggestions in a list format
  const searchListPattern = /Search Suggestions?:\s*([\s\S]*?)(?=\n\n|$)/i;
  const searchListMatch = response.match(searchListPattern);
  if (searchListMatch) {
    const suggestions = searchListMatch[1]
      .split("\n")
      .map((line) => {
        const quoted = line.match(/["']([^"']+)["']/);
        if (quoted) return quoted[1];
        return line.replace(/^[-•*\s]+/, "").trim();
      })
      .filter(Boolean);

    suggestions.forEach((s) => searchSuggestionsSet.add(s));
    cleanText = cleanText.replace(searchListMatch[0], "");
  }

  // Format search results
  const searchResults = Array.from(searchSuggestionsSet).map((query) => ({
    query,
    results: [], // This will be populated when the search API is called
  }));

  return {
    text: cleanText,
    searchSuggestions: Array.from(searchSuggestionsSet),
    generationPrompts: Array.from(generationPromptsSet),
    enhancedImages,
    searchResults,
  };
};

// Processing Status Component
const ProcessingStatus = ({ progress, qualityScore, batchStatus }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
        Enhancement Progress:
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 8,
          borderRadius: 4,
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          "& .MuiLinearProgress-bar": {
            borderRadius: 4,
          },
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <Typography variant="caption" color="textSecondary">
          {progress}%
        </Typography>
        {qualityScore !== undefined && (
          <Typography variant="caption" color="textSecondary">
            Quality Score: {qualityScore}
          </Typography>
        )}
      </Box>
      {batchStatus && (
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          Batch Status: {batchStatus}
        </Typography>
      )}
    </Box>
  );
};

// Suggestions Panel Components
const SuggestionsPanel = ({ suggestions, onSearch, onGenerate, isMobile }) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState("reference");

  if (isMobile) {
    return (
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
          sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
        >
          <Tab
            icon={<SearchIcon />}
            label="References"
            value="reference"
            sx={{ minHeight: "auto", py: 1 }}
          />
          <Tab
            icon={<ImageIcon />}
            label="Generation"
            value="generation"
            sx={{ minHeight: "auto", py: 1 }}
          />
        </Tabs>

        <Box sx={{ flex: 1, overflow: "auto", px: 1 }}>
          {activeTab === "reference" && suggestions.searchResults?.length > 0 ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {suggestions.searchResults.map((searchResult, index) => (
                <SearchSuggestionCard key={index} searchResult={searchResult} onSearch={onSearch} />
              ))}
            </Box>
          ) : activeTab === "generation" && suggestions.generationPrompts?.length > 0 ? (
            <Stack spacing={1}>
              {suggestions.generationPrompts.map((prompt, index) => (
                <GenerationPromptCard key={index} prompt={prompt} onGenerate={onGenerate} />
              ))}
            </Stack>
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {activeTab === "reference"
                  ? "No reference images available yet. Start a conversation to get suggestions."
                  : "No generation prompts available yet. Continue the conversation to get ideas."}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // Desktop view
  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      {suggestions.searchResults?.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Reference Images
          </Typography>
          {suggestions.searchResults.map((searchResult, index) => (
            <SearchSuggestionCard key={index} searchResult={searchResult} onSearch={onSearch} />
          ))}
        </Box>
      )}

      {suggestions.generationPrompts?.length > 0 && (
        <Box>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Generation Ideas
          </Typography>
          <Stack spacing={1}>
            {suggestions.generationPrompts.map((prompt, index) => (
              <GenerationPromptCard key={index} prompt={prompt} onGenerate={onGenerate} />
            ))}
          </Stack>
        </Box>
      )}

      {!suggestions.searchResults?.length && !suggestions.generationPrompts?.length && (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" color="textSecondary">
            No suggestions available yet. Start a conversation to get recommendations.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

// Search Suggestion Card Component
const SearchSuggestionCard = ({ searchResult, onSearch }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  return (
    <Paper
      elevation={1}
      sx={{
        mb: 2,
        overflow: "hidden",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          boxShadow: theme.shadows[3],
        },
      }}
    >
      <ButtonBase
        onClick={() => setExpanded(!expanded)}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {/* Preview Grid - Always visible */}
        <Grid container spacing={0.5} sx={{ p: 1 }}>
          {searchResult.results.slice(0, 2).map((result, imgIndex) => (
            <Grid item xs={6} key={imgIndex}>
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "100%",
                  borderRadius: 1,
                  overflow: "hidden",
                  backgroundColor: theme.palette.grey[100],
                }}
              >
                <img
                  src={result.thumbnailUrl || "/placeholder-image.jpg"}
                  alt={result.name || "Search result"}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onError={(e) => {
                    e.target.src = "/placeholder-image.jpg";
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "linear-gradient(transparent, rgba(0,0,0,0.6))",
                    height: "50%",
                    pointerEvents: "none",
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Query and Action Area */}
        <Box
          sx={{
            p: 1.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: 1,
            borderColor: "divider",
            backgroundColor: theme.palette.grey[50],
          }}
        >
          <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 1 }}>
            <SearchIcon color="primary" fontSize="small" />
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.text.primary,
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              {searchResult.query}
            </Typography>
          </Box>
          <IconButton
            size="small"
            sx={{
              transform: expanded ? "rotate(180deg)" : "none",
              transition: "transform 0.3s ease",
              color: theme.palette.text.secondary,
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      </ButtonBase>

      {/* Expanded Content */}
      <Collapse in={expanded}>
        <Box sx={{ p: 1, borderTop: 1, borderColor: "divider" }}>
          <Grid container spacing={0.5}>
            {searchResult.results.slice(2).map((result, imgIndex) => (
              <Grid item xs={6} key={imgIndex + 2}>
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: "100%",
                    borderRadius: 1,
                    overflow: "hidden",
                    backgroundColor: theme.palette.grey[100],
                    cursor: "pointer",
                    "&:hover": {
                      "& .image-overlay": {
                        opacity: 1,
                      },
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(result.contentUrl || result.thumbnailUrl, "_blank");
                  }}
                >
                  <img
                    src={result.thumbnailUrl || "/placeholder-image.jpg"}
                    alt={result.name || "Search result"}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.target.src = "/placeholder-image.jpg";
                    }}
                  />
                  {/* Hover Overlay */}
                  <Box
                    className="image-overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      opacity: 0,
                      transition: "opacity 0.2s ease",
                    }}
                  >
                    <OpenInNewIcon sx={{ color: "white" }} />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Button
            fullWidth
            variant="outlined"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onSearch(searchResult.query);
            }}
            startIcon={<SearchIcon />}
            sx={{
              mt: 2,
              textTransform: "none",
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
              },
            }}
          >
            Search More Like This
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
};

// Generation Prompt Card Component
const GenerationPromptCard = ({ prompt, onGenerate }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={1}
      sx={{
        overflow: "hidden",
        transition: "transform 0.2s, box-shadow 0.2s",
        "&:hover": {
          boxShadow: theme.shadows[3],
          transform: "translateY(-2px)",
        },
      }}
    >
      <ButtonBase
        onClick={() => onGenerate(prompt)}
        sx={{
          width: "100%",
          p: 1.5,
          display: "flex",
          alignItems: "center",
          gap: 1.5,
          textAlign: "left",
        }}
      >
        <Box
          sx={{
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            borderRadius: "50%",
            p: 1,
          }}
        >
          <ImageIcon color="primary" fontSize="small" />
        </Box>
        <Typography variant="body2" sx={{ flex: 1 }}>
          {prompt}
        </Typography>
        <OpenInNewIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
      </ButtonBase>
    </Paper>
  );
};

// Enhanced Drag and Drop Area
const DropZone = styled(Box)(({ theme, isDragActive }) => ({
  border: `2px dashed ${isDragActive ? theme.palette.primary.main : theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: "center",
  backgroundColor: isDragActive ? alpha(theme.palette.primary.main, 0.05) : theme.palette.grey[50],
  transition: theme.transitions.create(["border-color", "background-color"]),
  cursor: "pointer",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    borderColor: theme.palette.primary.main,
  },
}));

// Main Component
const DesignGenerator = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showScrollButton, setShowScrollButton] = useState(false);

  // Conversation persistence and history states
  const [showHistory, setShowHistory] = useState(false);

  // State management
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedPreviews, setUploadedPreviews] = useState([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isThinking, setIsThinking] = useState(false);

  // Add this with other state declarations
  const [recentChats, setRecentChats] = useState([]);

  const fetchRecentChats = async () => {
    try {
      const response = await api.get('/design-copilot/recent-chats');
      if (response.data.success) {
        setRecentChats(response.data.recent_chats);
      }
    } catch (error) {
      console.error('Error fetching recent chats:', error);
    }
  };

  // Navigation functions
  const executeSearch = (query) => {
    navigate("/web-search-images", {
      state: {
        searchQuery: query,
        from: "design-copilot",
      },
    });
  };

  const executeGeneration = (prompt) => {
    navigate("/generate-images", {
      state: {
        generationPrompt: prompt,
        from: "design-copilot",
      },
    });
  };

  // Panel states with localStorage persistence
  const [showAttachmentPanel, setShowAttachmentPanel] = useState(() => {
    return JSON.parse(localStorage.getItem("showAttachmentPanel")) ?? true;
  });

  // Scroll button visibility handler
  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const bottomThreshold = 100;
    setShowScrollButton(scrollHeight - scrollTop - clientHeight > bottomThreshold);
  };

  // Scroll to bottom
  const scrollToBottom = (smooth = true) => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  };

  // Load chat history function
  const loadChatHistory = async (sessionId) => {
    try {
      setLoading(true);
      const response = await api.get(`/design-copilot/chat/${sessionId}/history`);
      
      if (response.data.messages) {
        // Map the messages to include all necessary properties
        const formattedMessages = response.data.messages.map(msg => ({
          role: msg.role,
          content: msg.content,
          attachments: msg.attachments
            ? msg.attachments.map((att) => {
                if (att.type === "enhanced_image") {
                  return {
                    type: att.type,
                    beforeUrl: att.before_url,
                    afterUrl: att.after_url,
                    qualityScore: att.quality_score,
                  };
                }
                if (att.type.startsWith("image/") || att.type === "pdf") {
                  return {
                    type: att.type.startsWith("image/") ? "image" : "pdf",
                    url: att.url || URL.createObjectURL(new Blob([att.content], { type: att.type })),
                  };
                }
                return att;
              })
            : [],
          processing: msg.processing_status || null
        }));

        setMessages(formattedMessages);
        setSessionId(sessionId);
        scrollToBottom(false);
        
        // Save suggestions if they exist in the response
        if (response.data.suggestions) {
          // Removed activeSuggestions as per instruction
          // If QuickActionsPanel needs suggestions, handle accordingly here
        }
      }
    } catch (error) {
      console.error("Error loading chat history:", error);
      setError("Failed to load conversation history");
      localStorage.removeItem("designChatSessionId");
      setSessionId(null);
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  // Initial load to check for saved session
  useEffect(() => {
    const initialLoad = async () => {
      const savedSessionId = localStorage.getItem("designChatSessionId");
      if (savedSessionId) {
        await loadChatHistory(savedSessionId);
      }
    };
    
    initialLoad();
  }, []); // Empty dependency array for initial load only

  // Save session on component unmount
  useEffect(() => {
    return () => {
      if (sessionId) {
        localStorage.setItem("designChatSessionId", sessionId);
      }
    };
  }, [sessionId]);

  // Start new conversation
  const startNewConversation = () => {
    setMessages([]);
    setSessionId(null);
    localStorage.removeItem("designChatSessionId");
  };

  // Enhanced file upload handling
  const handleFileUpload = async (files) => {
    try {
      const maxFileSize = 10 * 1024 * 1024; // 10MB
      const validFiles = Array.from(files).filter((file) => {
        if (file.size > maxFileSize) {
          setError(`File ${file.name} is too large. Maximum size is 10MB.`);
          return false;
        }
        return file.type.startsWith("image/") || file.type === "application/pdf";
      });

      if (validFiles.length === 0) return;

      const processedFiles = await Promise.all(
        validFiles.map(async (file) => {
          if (file.type.startsWith("image/")) {
            return await resizeImage(file);
          }
          return file;
        })
      );

      setUploadedFiles((prev) => [...prev, ...processedFiles]);
      const newPreviews = processedFiles.map((file) => URL.createObjectURL(file));
      setUploadedPreviews((prev) => [...prev, ...newPreviews]);
    } catch (error) {
      console.error("Error processing files:", error);
      setError("Failed to process uploaded files");
    }
  };

  // Enhanced drag and drop handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    const { files } = e.dataTransfer;
    handleFileUpload(files);
  };

  // Send message with progress tracking
  const sendMessage = async () => {
    if (!inputMessage.trim() && uploadedFiles.length > 0) {
      setError("Please add a message describing what you'd like to do with the image(s)");
      return;
    }
    if (!inputMessage.trim() && uploadedFiles.length === 0) return;

    try {
      setLoading(true);
      setIsThinking(true);
      setError("");

      const formData = new FormData();
      formData.append("message", inputMessage);

      if (sessionId) {
        formData.append("session_id", sessionId);
      }

      uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });

      // Add user message immediately
      const newUserMessage = {
        role: "user",
        content: inputMessage,
        attachments: uploadedPreviews.map((preview, index) => ({
          type: uploadedFiles[index].type.startsWith("image/") ? "image" : "pdf",
          url: preview,
        })),
      };

      setMessages((prev) => [...prev, newUserMessage]);
      setInputMessage("");
      setUploadedFiles([]);
      setUploadedPreviews([]);
      scrollToBottom(false);

      const response = await api.post("/design-copilot/chat", formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Upload Progress: ${percentCompleted}%`);
        },
        timeout: 5 * 60 * 1000, // 5 minutes
      });

      // Parse the response and extract suggestions
      const parsedResponse = parseModelResponse(response.data.response);

      // Fetch search results for each suggestion
      const searchResults = await Promise.all(
        parsedResponse.searchSuggestions.map(async (query) => {
          try {
            const searchFormData = new FormData();
            searchFormData.append("query_text", query);

            const searchResponse = await api.post("/web-images/search", searchFormData, {
              params: {
                page_size: 4,
                page_number: 1,
              },
            });

            return {
              query,
              results: searchResponse.data.results || [],
            };
          } catch (error) {
            console.error(`Error fetching search results for "${query}":`, error);
            return {
              query,
              results: [],
            };
          }
        })
      );

      const newAssistantMessage = {
        role: "assistant",
        content: response.data.response,
        attachments:
          response.data.enhanced_images?.map((img) => ({
            type: "enhanced_image",
            beforeUrl: img.before_url,
            afterUrl: img.after_url,
            qualityScore: img.quality_score,
          })) || [],
        processing: response.data.processing_status || null,
      };

      // Update messages and suggestions
      setMessages((prev) => [...prev, newAssistantMessage]);
      // Removed activeSuggestions as per instruction

      // Save session ID if provided
      if (response.data.session_id) {
        setSessionId(response.data.session_id);
        localStorage.setItem("designChatSessionId", response.data.session_id);
      }

      // Scroll to the new message
      scrollToBottom();
    } catch (err) {
      console.error("Error sending message:", err);
      const errorMessage =
        err.code === "ECONNABORTED"
          ? "The operation is taking longer than expected. Please try with fewer images."
          : err.message === "Network Error"
          ? "Connection error. Please check your internet connection."
          : err.response?.data?.detail || "Failed to send message. Please try again.";

      setError(errorMessage);
    } finally {
      setLoading(false);
      setIsThinking(false);
    }
  };

  return (
    <>
      <RootContainer maxWidth="xl">
        {/* Top Navigation with New Chat and Past Conversations */}
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="primary">
            Design Copilot
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button startIcon={<AddIcon />} onClick={startNewConversation} variant="contained">
              New Chat
            </Button>
            <Button
              startIcon={<HistoryIcon />}
              onClick={() => {
                setShowHistory(true);
                fetchRecentChats();
              }}
              variant="outlined"
            >
              Past Conversations
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ height: "100%" }}>
          {/* Left Panel - Attachments */}
          {(!isMobile || showAttachmentPanel) && (
            <Grid item xs={12} md={2.5}>
              <Paper elevation={3} sx={{ height: "100%", p: 2, overflow: "hidden" }}>
                <Typography variant="h6" gutterBottom>
                  Attachments
                </Typography>
                <DropZone
                  isDragActive={isDragActive}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  onClick={() => fileInputRef.current?.click()}
                >
                  <AttachFileIcon sx={{ fontSize: 40, color: "text.secondary", mb: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    Drop files here, paste from clipboard, or click to upload
                  </Typography>
                </DropZone>

                <Box sx={{ mt: 2, overflow: "auto", maxHeight: "calc(100% - 200px)" }}>
                  <FilePreviewCarousel>
                    {uploadedFiles.map((file, index) => (
                      <FilePreview
                        key={index}
                        file={file}
                        preview={uploadedPreviews[index]}
                        onRemove={() => {
                          setUploadedFiles((files) => files.filter((_, i) => i !== index));
                          setUploadedPreviews((previews) => previews.filter((_, i) => i !== index));
                        }}
                      />
                    ))}
                  </FilePreviewCarousel>
                </Box>
              </Paper>
            </Grid>
          )}

          {/* Main Chat Area */}
          <Grid item xs={12} md={showAttachmentPanel ? 7 : 9}>
            <Paper elevation={3} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
              {/* Chat Messages */}
              <Box
                ref={chatContainerRef}
                onScroll={handleScroll}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {messages.map((message, index) => (
                  <Message
                    key={index}
                    message={message}
                    onSearch={executeSearch}
                    onGenerate={executeGeneration}
                  />
                ))}
                {isThinking && <ThinkingIndicator />}
              </Box>

              {/* Scroll to Bottom Button */}
              <Fade in={showScrollButton}>
                <ScrollButton variant="contained" color="primary" onClick={() => scrollToBottom()}>
                  <KeyboardArrowDownIcon />
                </ScrollButton>
              </Fade>

              {/* Input Area */}
              <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
                {error && (
                  <Box sx={{ mb: 2 }}>
                    <Alert severity="error" onClose={() => setError("")}>
                      {error}
                    </Alert>
                  </Box>
                )}
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs>
                    <TextField
                      fullWidth
                      multiline
                      maxRows={4}
                      value={inputMessage}
                      onChange={(e) => setInputMessage(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                      placeholder="Ask about your design..."
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" onClick={sendMessage} disabled={loading}>
                      {loading ? <CircularProgress size={24} /> : <SendIcon />}
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>

          {/* Right Panel - Quick Actions */}
          <Grid item xs={12} md={2.5}>
            <Paper elevation={3} sx={{ height: "100%", overflow: "hidden" }}>
              <QuickActionsPanel 
                onActionClick={(action) => {
                  setInputMessage(action.placeholder);
                  document.querySelector('textarea')?.focus();
                }}
              />
            </Paper>
          </Grid>
        </Grid>

        {/* Hidden File Input */}
        <input
          type="file"
          ref={fileInputRef}
          hidden
          multiple
          accept="image/*,application/pdf"
          onChange={(e) => handleFileUpload(e.target.files)}
        />

        {/* Conversation History Dialog */}
        <Dialog
          open={showHistory}
          onClose={() => setShowHistory(false)}
          maxWidth="sm"
          fullWidth
        >
          <ConversationHistory
            chats={recentChats} // Add this prop to pass the chats data
            onSelectConversation={(sessionId) => {
              loadChatHistory(sessionId);
              setShowHistory(false);
            }}
            onClose={() => setShowHistory(false)}
          />
        </Dialog>
      </RootContainer>
      {/* Removed DesignActionPanel as per instruction */}
    </>
  );
};

export default DesignGenerator;
