import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { 
  TextField, 
  Button, 
  Container, 
  Typography, 
  Box, 
  Alert, 
  Paper, 
  Card, 
  CardContent, 
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Tooltip  // Added for help text
} from "@mui/material";
import ModelIcon from '@mui/icons-material/Category';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';  // Added for video icon
import ImageIcon from '@mui/icons-material/Image';  // Added for image icon
import CloseIcon from "@mui/icons-material/Close";

const CreateModel = ({ setModelId, setModelName }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [modelType, setModelType] = useState("image"); // New state for model type
  const [isModelCreated, setIsModelCreated] = useState(false);
  const [error, setError] = useState("");
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);

      // Choose endpoint based on model type
      const endpoint = modelType === "video" ? "/video-models" : "/models";
      const response = await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { id, name: modelName } = response.data;
      setModelId(id);
      setModelName(modelName);
      setIsModelCreated(true);
      setError("");

      // Navigate to appropriate add class page
      navigate(modelType === "video" ? "/add-class-video" : "/add-class");
    } catch (error) {
      setError("Error creating model");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ 
      display: "flex", 
      flexDirection: "column",
      justifyContent: "center", 
      alignItems: "center", 
      minHeight: "100vh",
      padding: "1rem",
      paddingTop: '100px'
    }}>
      <Paper elevation={3} sx={{ padding: "2rem", width: "100%", borderRadius: "12px", mb: 8 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center", color: "#550FCC", mb: 2 }}>
          Create a New Model
        </Typography>

        {error && (
          <Box mt={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        <form onSubmit={handleSubmit}>
          {/* Model Type Selection */}
          <FormControl component="fieldset" sx={{ mb: 3, width: "100%" }}>
            <FormLabel component="legend" sx={{ mb: 1 }}>Model Type</FormLabel>
            <RadioGroup
              row
              value={modelType}
              onChange={(e) => setModelType(e.target.value)}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                '& .MuiFormControlLabel-root': {
                  margin: 0,
                  flex: 1,
                }
              }}
            >
              <Tooltip title="Create a model for image classification">
                <FormControlLabel 
                  value="image" 
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ImageIcon />
                      <span>Image Model</span>
                    </Box>
                  }
                />
              </Tooltip>
              <Tooltip title="Create a model for video classification">
                <FormControlLabel 
                  value="video" 
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <VideoLibraryIcon />
                      <span>Video Model</span>
                    </Box>
                  }
                />
              </Tooltip>
            </RadioGroup>
          </FormControl>

          <TextField
            fullWidth
            label="Model Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            InputProps={{
              sx: { borderRadius: "8px" },
            }}
          />

          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            required
            multiline
            rows={3}
            InputProps={{
              sx: { borderRadius: "8px" },
            }}
          />

          <Box mt={3} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                padding: "10px 20px",
                borderRadius: "8px",
                backgroundColor: "#550FCC",
                '&:hover': {
                  backgroundColor: "#8338ec",
                },
              }}
            >
              Create {modelType.charAt(0).toUpperCase() + modelType.slice(1)} Model
            </Button>
          </Box>
        </form>

        {isModelCreated && (
          <Box mt={2}>
            <Alert severity="success">
              {modelType.charAt(0).toUpperCase() + modelType.slice(1)} Model Created: {name}
            </Alert>
          </Box>
        )}
      </Paper>

      {/* Updated Explanatory Card */}
      {showExplanatoryCard && (
        <Card sx={{
          width: "90%",
          maxWidth: "800px",
          margin: "2rem auto",
          padding: "2rem",
          borderRadius: "12px",
          backgroundColor: "#f9f9f9",
          position: "relative",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}>
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: "center" }}>
            <ModelIcon sx={{ fontSize: "4rem", color: "#550FCC" }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              What does "Creating a Model" mean?
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ lineHeight: 1.6 }}>
              Creating a model is the first step in building your AI solution. You can choose between:
              <Box component="ul" sx={{ textAlign: 'left', mt: 2 }}>
                <li><strong>Image Model:</strong> For classifying and recognizing different types of images</li>
                <li><strong>Video Model:</strong> For analyzing and categorizing video content</li>
              </Box>
              After creating your model, you'll be able to add classes and train it with examples.
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default CreateModel;