import React, { useState } from 'react';
import { 
  ImageList, 
  ImageListItem, 
  Box, 
  Typography, 
  Chip,
  Popover,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Folder, Maximize } from 'lucide-react';

const EnhancedImageList = ({ 
  items, 
  onImageClick, 
  onImageSelect, 
  selectedImages, 
  isSmallScreen,
  onContextMenu,
  viewMode // Add support for different view modes
}) => {
  const [expandedTags, setExpandedTags] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Helper function to format file size
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleTagsExpand = (event, tags) => {
    setExpandedTags(tags);
    setAnchorEl(event.currentTarget);
  };

  const handleTagsClose = () => {
    setExpandedTags(null);
    setAnchorEl(null);
  };

  // Calculate the number of columns based on view mode and screen size
  const getColumnCount = () => {
    if (viewMode === 'map') {
      return isMobile ? 2 : 5; // More columns for similarity map view
    }
    return isMobile ? 2 : 4; // Default grid view
  };

  return (
    <>
      <ImageList cols={getColumnCount()} gap={viewMode === 'map' ? 8 : 16}>
        {items.map((result) => (
          <ImageListItem 
            key={result.image_url}
            sx={{ 
              '&:hover': {
                '.image-info': {
                  opacity: 1,
                },
                '.image-actions': {
                  opacity: 1
                }
              }
            }}
            onContextMenu={(e) => onContextMenu(e, result.image_url)}
          >
            {/* Image container with overlay */}
            <Box 
              sx={{ 
                position: 'relative',
                borderRadius: '8px',
                overflow: 'hidden',
                aspectRatio: viewMode === 'map' ? '1/1' : '4/3',
                mb: 1,
                bgcolor: 'grey.100',
                cursor: 'pointer'
              }}
              onClick={(e) => {
                e.stopPropagation();
                onImageSelect(result.image_url);
              }}
            >
              <img
                src={result.image_url}
                alt={result.class_label || 'Image'}
                loading="lazy"
                style={{ 
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />

              {/* File size overlay in top-right */}
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  bgcolor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  fontSize: '0.7rem'
                }}
              >
                {formatFileSize(result.size || 0)}
              </Typography>

              {/* View/expand button */}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onImageClick(result.image_url);
                }}
                className="image-actions"
                sx={{
                  position: 'absolute',
                  right: 8,
                  bottom: 8,
                  color: 'white',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  p: '6px',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                  },
                  zIndex: 2
                }}
                size="small"
              >
                <Maximize size={18} />
              </IconButton>

              {/* Selection overlay - visual indicator only */}
              {selectedImages.includes(result.image_url) && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: 'rgba(25, 118, 210, 0.2)',
                    border: '2px solid',
                    borderColor: 'primary.main',
                    borderRadius: '7px'
                  }}
                />
              )}
            </Box>

            {/* Only show image info in grid view or if explicitly needed */}
            {(viewMode !== 'map' || result.tags?.length > 0 || result.folder_name) && (
              <Box className="image-info" sx={{ 
                px: 1,
                display: viewMode === 'map' ? 'none' : 'block' 
              }}>
                {/* Title */}
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: 500,
                    mb: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {result.class_label || 'Untitled'}
                </Typography>

                {/* Tags */}
                {result.tags && result.tags.length > 0 && (
                  <Box sx={{ mb: 1, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                    {result.tags.slice(0, 3).map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        size="small"
                        sx={{ 
                          height: '20px',
                          '& .MuiChip-label': {
                            px: 1,
                            fontSize: '0.75rem'
                          },
                          bgcolor: 'grey.100'
                        }}
                      />
                    ))}
                    {result.tags.length > 3 && (
                      <Typography
                        variant="caption"
                        color="primary"
                        sx={{ 
                          alignSelf: 'center',
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                        onClick={(e) => handleTagsExpand(e, result.tags)}
                      >
                        +{result.tags.length - 3}
                      </Typography>
                    )}
                  </Box>
                )}

                {/* Folder Info */}
                {result.folder_name && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Folder size={16} strokeWidth={1.5} />
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{
                        maxWidth: '90%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {result.folder_name}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </ImageListItem>
        ))}
      </ImageList>

      {/* Tags Popover */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleTagsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 300 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>All Tags</Typography>
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            {expandedTags?.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                size="small"
                sx={{ 
                  height: '24px',
                  '& .MuiChip-label': {
                    px: 1.5
                  }
                }}
              />
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default EnhancedImageList;