import React, { useState, useEffect } from 'react';
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchAndRetrieveImages from './SearchAndRetrieveImages';
import SearchAndRetrieveVideos from './SearchAndRetrieveVideos';

const ContentSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [contentType, setContentType] = useState('images');

  useEffect(() => {
    // Initialize content type from location state if available
    if (location.state?.contentType) {
      setContentType(location.state.contentType);
    }
  }, [location.state]);

  const handleContentTypeChange = (event, newValue) => {
    if (newValue !== null) {
      setContentType(newValue);
      // Update the URL and state without losing other state properties
      navigate(location.pathname, {
        state: {
          ...location.state,
          contentType: newValue
        },
        replace: true
      });
    }
  };

  return (
    <Box sx={{ width: '100%', pt: '80px', pb: '80px' }}>
      {/* Toggle container */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
         // marginBottom: '20px'
        }}
      >
        <ToggleButtonGroup
          value={contentType}
          exclusive
          onChange={handleContentTypeChange}
          aria-label="content type"
          sx={{
            '& .MuiToggleButton-root': {
              padding: '8px 32px',
              borderRadius: 0,
              fontWeight: 500,
              fontSize: '14px',
              transition: 'all 0.2s ease',
              '&.Mui-selected': {
                backgroundColor: '#550FCC',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#4a0ab0',
                },
              },
              '&:not(.Mui-selected)': {
                backgroundColor: 'transparent',
                color: '#1a1a1a',
              }
            },
            border: '1px solid #ddd',
            borderRadius: '4px',
            overflow: 'hidden',
            backgroundColor: '#f5f5f5'
          }}
        >
          <ToggleButton value="images" aria-label="images">
            <ImageIcon sx={{ marginRight: 1 }} />
            IMAGES
          </ToggleButton>
          <ToggleButton value="videos" aria-label="videos">
            <VideoFileIcon sx={{ marginRight: 1 }} />
            VIDEOS
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      
      {/* Main content */}
      <Box>
        {contentType === 'images' ? (
          <SearchAndRetrieveImages />
        ) : (
          <SearchAndRetrieveVideos />
        )}
      </Box>
    </Box>
  );
};

export default ContentSearch;