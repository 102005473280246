import React, { useState, useEffect } from 'react';
import { Box, Container, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { useLocation, useNavigate } from 'react-router-dom';

import ClassifyAndSaveImages from './ClassifyAndSaveImages';
import ClassifyAndSaveVideos from './ClassifyAndSaveVideos';

const MediaClassifierWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [mediaType, setMediaType] = useState('image');

  useEffect(() => {
    // Initialize media type from location state if available
    if (location.state?.mediaType) {
      setMediaType(location.state.mediaType);
    }
  }, [location.state]);

  const handleMediaTypeChange = (event, newValue) => {
    if (newValue !== null) {
      setMediaType(newValue);
      // Update the URL and state without losing other state properties
      navigate(location.pathname, {
        state: {
          ...location.state,
          mediaType: newValue,
          // Clear media-specific state when switching
          media: undefined,
          selectedModelId: undefined
        },
        replace: true
      });
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: "8rem", marginBottom: "4rem" }}>
      <Typography 
        variant="h4" 
        sx={{ 
          fontWeight: "bold", 
          marginBottom: "2rem", 
          color: "#550FCC",
          textAlign: "center"
        }}
      >
        Media Classification
      </Typography>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        marginBottom: '2rem',
        '& .MuiToggleButton-root': {
          padding: '8px 24px',
          '&.Mui-selected': {
            backgroundColor: '#550FCC',
            color: 'white',
            '&:hover': {
              backgroundColor: '#4a0ab0',
            },
          },
        }
      }}>
        <ToggleButtonGroup
          value={mediaType}
          exclusive
          onChange={handleMediaTypeChange}
          aria-label="media type"
        >
          <ToggleButton value="image" aria-label="image">
            <ImageIcon sx={{ marginRight: 1 }} />
            Images
          </ToggleButton>
          <ToggleButton value="video" aria-label="video">
            <VideoFileIcon sx={{ marginRight: 1 }} />
            Videos
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Render appropriate component based on media type */}
      {mediaType === 'image' ? (
        <ClassifyAndSaveImages />
      ) : (
        <ClassifyAndSaveVideos />
      )}
    </Container>
  );
};

export default MediaClassifierWrapper;