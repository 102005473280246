import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { 
  Button, 
  Container, 
  Typography, 
  Box, 
  Card, 
  CardContent, 
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Collapse,
  Divider,
  Grid,
  LinearProgress,
  Tooltip,
  CircularProgress
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';

const PredictVideo = ({ modelId, modelName }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true);
  const [expandedExplanations, setExpandedExplanations] = useState({});
  const [loadingExplanations, setLoadingExplanations] = useState({});
  const [processingStatus, setProcessingStatus] = useState({});
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
    setPredictions(predictions.filter((_, i) => i !== index));
    setUploadProgress(prev => {
      const newProgress = { ...prev };
      delete newProgress[index];
      return newProgress;
    });
    setProcessingStatus(prev => {
      const newStatus = { ...prev };
      delete newStatus[index];
      return newStatus;
    });
  };

  const predict = async () => {
    if (!modelId) {
      navigate('/list-models', {
        state: {
          returnTo: '/predict-video',
          message: 'Please select a model first'
        }
      });
      return;
    }

    if (files.length === 0) {
      alert("Please upload at least one video file.");
      return;
    }

    setLoading(true);
    setPredictions([]);

    try {
      const results = await Promise.all(files.map(async (file, index) => {
        const formData = new FormData();
        formData.append("file", file);

        setProcessingStatus(prev => ({
          ...prev,
          [index]: "Uploading video..."
        }));

        try {
          const response = await api.post(`/video-models/${modelId}/predict`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const progress = (progressEvent.loaded / progressEvent.total) * 100;
              setUploadProgress(prev => ({
                ...prev,
                [index]: progress
              }));
            }
          });

          setProcessingStatus(prev => ({
            ...prev,
            [index]: "Processing complete"
          }));

          return {
            fileName: file.name,
            predictions: response.data.predictions,
            metadata: response.data.metadata,
            error: null,
            explanation: null
          };
        } catch (error) {
          setProcessingStatus(prev => ({
            ...prev,
            [index]: "Error occurred"
          }));

          return {
            fileName: file.name,
            predictions: [],
            error: error.message,
            explanation: null
          };
        }
      }));

      setPredictions(results);
    } catch (error) {
      alert("Error during predictions: " + error.message);
    } finally {
      setLoading(false);
      setUploadProgress({});
    }
  };

  const getExplanation = async (index) => {
    if (!predictions[index] || !predictions[index].predictions.length) {
      return;
    }

    setLoadingExplanations(prev => ({ ...prev, [index]: true }));

    const [predictedClass, confidence] = predictions[index].predictions[0];
    const formData = new FormData();
    formData.append("predicted_class", predictedClass);
    formData.append("confidence", confidence);
    formData.append("file", files[index]);

    try {
      const response = await api.post(`/video-models/${modelId}/predict/explanation`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setPredictions(prev =>
        prev.map((pred, i) =>
          i === index ? { ...pred, explanation: response.data.explanation } : pred
        )
      );
    } catch (error) {
      alert("Error getting explanation: " + error.message);
    } finally {
      setLoadingExplanations(prev => ({ ...prev, [index]: false }));
    }
  };

  const toggleExplanation = (index) => {
    setExpandedExplanations(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const resetAll = () => {
    setFiles([]);
    setPredictions([]);
    setExpandedExplanations({});
    setUploadProgress({});
    setProcessingStatus({});
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: "5rem", padding: "1rem" }}>
      <Paper elevation={3} sx={{ padding: "2rem", width: "100%", borderRadius: "12px", mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#550FCC", mb: 4 }}>
          Video Classification for Model: {modelName}
        </Typography>

        <Box sx={{ mb: 3 }}>
          <input
            type="file"
            multiple
            accept="video/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />

          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Button
              variant="outlined"
              startIcon={<VideoFileIcon />}
              onClick={() => fileInputRef.current.click()}
            >
              Select Videos
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={predict}
              disabled={loading || files.length === 0}
              startIcon={<PlayCircleIcon />}
              sx={{
                backgroundColor: "#550FCC",
                '&:hover': {
                  backgroundColor: "#8338ec",
                },
              }}
            >
              {loading ? "Processing..." : "Analyze Videos"}
            </Button>

            {files.length > 0 && (
              <Button
                variant="outlined"
                color="error"
                onClick={resetAll}
                disabled={loading}
                startIcon={<DeleteIcon />}
              >
                Reset All
              </Button>
            )}
          </Box>

          {/* File List */}
          {files.length > 0 && (
            <List>
              {files.map((file, index) => (
                <Paper elevation={1} sx={{ mb: 2, borderRadius: '8px' }} key={`${file.name}-${index}`}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <VideoFileIcon color="primary" />
                          <Typography variant="subtitle1">{file.name}</Typography>
                        </Box>
                      }
                      secondary={
                        <>
                          {uploadProgress[index] !== undefined && (
                            <Box sx={{ mt: 1 }}>
                              <LinearProgress 
                                variant="determinate" 
                                value={uploadProgress[index]} 
                              />
                              <Typography variant="caption" color="textSecondary">
                                {processingStatus[index]}
                              </Typography>
                            </Box>
                          )}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => removeFile(index)}
                        disabled={loading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Paper>
              ))}
            </List>
          )}
        </Box>

        {/* Predictions */}
        {predictions.length > 0 && (
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {predictions.map((result, index) => (
              <Grid item xs={12} key={`prediction-${index}`}>
                <Card sx={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6">
                        Results for: {result.fileName}
                      </Typography>
                      {result.metadata && (
                        <Tooltip title="Video Information">
                          <Chip
                            icon={<InfoIcon />}
                            label={`${formatDuration(result.metadata.duration)} | ${result.metadata.resolution[0]}x${result.metadata.resolution[1]}`}
                            variant="outlined"
                          />
                        </Tooltip>
                      )}
                    </Box>

                    {result.error ? (
                      <Typography color="error" sx={{ mt: 2 }}>
                        Error: {result.error}
                      </Typography>
                    ) : (
                      <>
                        <List>
                          {result.predictions.map(([className, confidence], predIndex) => (
                            <ListItem key={predIndex}>
                              <ListItemText
                                primary={
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Chip
                                      label={`${(confidence * 100).toFixed(2)}%`}
                                      color={predIndex === 0 ? "primary" : "default"}
                                      size="small"
                                    />
                                    <Typography>
                                      {className}
                                    </Typography>
                                    {predIndex === 0 && (
                                      <Chip
                                        label="Top Prediction"
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        sx={{ ml: 'auto' }}
                                      />
                                    )}
                                  </Box>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>

                        {result.predictions.length > 0 && (
                          <>
                            <Divider sx={{ my: 2 }} />
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => getExplanation(index)}
                                startIcon={loadingExplanations[index] ? 
                                  <CircularProgress size={20} /> : 
                                  <QuestionAnswerIcon />
                                }
                                disabled={loading || loadingExplanations[index]}
                              >
                                {loadingExplanations[index] ? 
                                  "Getting Explanation..." : 
                                  "Get Explanation"
                                }
                              </Button>

                              {result.explanation && (
                                <IconButton
                                  onClick={() => toggleExplanation(index)}
                                  sx={{
                                    transform: expandedExplanations[index] ? 
                                      'rotate(180deg)' : 'none',
                                    transition: 'transform 0.2s'
                                  }}
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              )}
                            </Box>

                            <Collapse in={expandedExplanations[index]}>
                              {result.explanation && (
                                <Paper sx={{ p: 2, mt: 2, bgcolor: 'grey.50' }}>
                                  <Typography variant="body2">
                                    {result.explanation}
                                  </Typography>
                                </Paper>
                              )}
                            </Collapse>
                          </>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>

      {/* Help Card */}
      {showExplanatoryCard && (
        <Card sx={{
          width: "90%",
          maxWidth: "800px",
          margin: "2rem auto",
          padding: "2rem",
          borderRadius: "12px",
          backgroundColor: "#f9f9f9",
          position: "relative",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}>
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: "center" }}>
            <VideoFileIcon sx={{ fontSize: "3rem", color: "#550FCC" }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              How Does Video Classification Work?
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ lineHeight: 1.6 }}>
              Upload one or more videos to analyze them using your trained model.
              The system will process each video and provide predictions with confidence scores.
              For the top prediction, you can request an explanation of why the model
              classified the video in that way. The analysis considers the entire video
              content, including movement, scenes, and temporal relationships.
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default PredictVideo;