import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  CircularProgress,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Search, Sparkles, Image, Clock } from "lucide-react";
import AuthContext from "../context/AuthContext";

// Replace with your own background image
import heroBackgroundImage from "../assets/monet1.png";

// Add this ThreeDBackground component at the top of your file
// Replace your ThreeDBackground component with this image-based version
const ThreeDBackground = () => {
  const canvasRef = useRef(null);
  
  useEffect(() => {
    // Canvas setup
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    
    // Set canvas to full size of its container
    const resizeCanvas = () => {
      const container = canvas.parentElement;
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
    };
    
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    
    // Create image objects instead of simple particles
    const imageObjects = [];
    const objectCount = 35; // Fewer objects since they're more detailed
    
    // Mini image shapes/types
    const shapes = [
      // Small polaroid/image frame
      (x, y, size, color) => {
        // Draw frame
        ctx.fillStyle = 'white';
        ctx.fillRect(x - size/2, y - size/2, size, size);
        
        // Draw inner image area
        ctx.fillStyle = color;
        ctx.fillRect(x - size/2 + size/10, y - size/2 + size/10, size * 0.8, size * 0.7);
        
        // Draw frame bottom
        ctx.fillStyle = 'white';
        ctx.fillRect(x - size/2, y + size/2 - size/4, size, size/4);
      },
      
      // Photo/thumbnail with rounded corners
      (x, y, size, color) => {
        const radius = size / 5;
        
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.moveTo(x - size/2 + radius, y - size/2);
        ctx.lineTo(x + size/2 - radius, y - size/2);
        ctx.quadraticCurveTo(x + size/2, y - size/2, x + size/2, y - size/2 + radius);
        ctx.lineTo(x + size/2, y + size/2 - radius);
        ctx.quadraticCurveTo(x + size/2, y + size/2, x + size/2 - radius, y + size/2);
        ctx.lineTo(x - size/2 + radius, y + size/2);
        ctx.quadraticCurveTo(x - size/2, y + size/2, x - size/2, y + size/2 - radius);
        ctx.lineTo(x - size/2, y - size/2 + radius);
        ctx.quadraticCurveTo(x - size/2, y - size/2, x - size/2 + radius, y - size/2);
        ctx.closePath();
        ctx.fill();
        
        // Inner image area
        ctx.fillStyle = color;
        const innerSize = size * 0.9;
        const innerRadius = innerSize / 6;
        
        ctx.beginPath();
        ctx.moveTo(x - innerSize/2 + innerRadius, y - innerSize/2);
        ctx.lineTo(x + innerSize/2 - innerRadius, y - innerSize/2);
        ctx.quadraticCurveTo(x + innerSize/2, y - innerSize/2, x + innerSize/2, y - innerSize/2 + innerRadius);
        ctx.lineTo(x + innerSize/2, y + innerSize/2 - innerRadius);
        ctx.quadraticCurveTo(x + innerSize/2, y + innerSize/2, x + innerSize/2 - innerRadius, y + innerSize/2);
        ctx.lineTo(x - innerSize/2 + innerRadius, y + innerSize/2);
        ctx.quadraticCurveTo(x - innerSize/2, y + innerSize/2, x - innerSize/2, y + innerSize/2 - innerRadius);
        ctx.lineTo(x - innerSize/2, y - innerSize/2 + innerRadius);
        ctx.quadraticCurveTo(x - innerSize/2, y - innerSize/2, x - innerSize/2 + innerRadius, y - innerSize/2);
        ctx.closePath();
        ctx.fill();
      },
      
      // Image card with slight tilt
      (x, y, size, color) => {
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(Math.PI * 0.05); // Slight tilt
        
        // Shadow
        ctx.shadowColor = 'rgba(0,0,0,0.3)';
        ctx.shadowBlur = size/4;
        ctx.shadowOffsetX = size/20;
        ctx.shadowOffsetY = size/15;
        
        // Card
        ctx.fillStyle = 'white';
        ctx.fillRect(-size/2, -size/2, size, size);
        
        // Image area
        ctx.shadowColor = 'transparent';
        ctx.fillStyle = color;
        ctx.fillRect(-size/2 + size/15, -size/2 + size/15, size * 0.9, size * 0.7);
        
        ctx.restore();
      },
      
      // Tag/label shape
      (x, y, size, color) => {
        ctx.save();
        ctx.translate(x, y);
        
        // Shadow
        ctx.shadowColor = 'rgba(0,0,0,0.2)';
        ctx.shadowBlur = size/4;
        
        // Tag shape
        ctx.beginPath();
        ctx.moveTo(-size/2, -size/2);
        ctx.lineTo(size/2, -size/2);
        ctx.lineTo(size/2, size/2);
        ctx.lineTo(0, size/3);
        ctx.lineTo(-size/2, size/2);
        ctx.closePath();
        
        ctx.fillStyle = 'white';
        ctx.fill();
        
        // Color area
        ctx.beginPath();
        ctx.arc(-size/4, -size/4, size/5, 0, Math.PI * 2);
        ctx.fillStyle = color;
        ctx.fill();
        
        ctx.restore();
      }
    ];
    
    // Create image objects with 3D positions
    for (let i = 0; i < objectCount; i++) {
      imageObjects.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        z: Math.random() * 2000 + 1000, // Further back for more dramatic effect
        size: Math.random() * 30 + 25, // Base size (will be affected by perspective)
        shapeIndex: Math.floor(Math.random() * shapes.length),
        color: i % 3 === 0 ? 
          `hsl(330, 100%, ${70 + Math.random() * 20}%)` : // Pink
          i % 3 === 1 ?
          `hsl(260, 85%, ${60 + Math.random() * 20}%)` : // Purple
          `hsl(195, 85%, ${60 + Math.random() * 20}%)`, // Blue
        vx: (Math.random() - 0.5) * 0.2,
        vy: (Math.random() - 0.5) * 0.2,
        vz: Math.random() * 0.5 + 0.5, // Moving toward viewer
        rotation: Math.random() * Math.PI * 2,
        rotationSpeed: (Math.random() - 0.5) * 0.01
      });
    }
    
    // Animation variables
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    
    // Animation loop
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Sort objects by z-position for proper layering
      imageObjects.sort((a, b) => b.z - a.z);
      
      // Update and draw each image object
      for (let i = 0; i < imageObjects.length; i++) {
        const obj = imageObjects[i];
        
        // Update position with perspective
        obj.x += obj.vx;
        obj.y += obj.vy;
        obj.z -= obj.vz; // Move toward viewer
        obj.rotation += obj.rotationSpeed;
        
        // Reset objects that go out of bounds or too close
        if (obj.x < -obj.size || obj.x > canvas.width + obj.size || 
            obj.y < -obj.size || obj.y > canvas.height + obj.size ||
            obj.z < 50) {
          obj.x = Math.random() * canvas.width;
          obj.y = Math.random() * canvas.height;
          obj.z = 2000 + Math.random() * 1000; // Reset depth
          obj.rotation = Math.random() * Math.PI * 2;
          continue;
        }
        
        // Calculate perspective size based on z-position
        const scale = 600 / obj.z;
        const projectedSize = obj.size * scale;
        
        // Skip drawing very small objects
        if (projectedSize < 3) continue;
        
        // Project 3D to 2D with perspective
        const projectedX = (obj.x - centerX) * scale + centerX;
        const projectedY = (obj.y - centerY) * scale + centerY;
        
        // Draw the image object with its specific shape function
        ctx.save();
        ctx.translate(projectedX, projectedY);
        ctx.rotate(obj.rotation);
        ctx.translate(-projectedX, -projectedY);
        
        // Apply opacity based on z-depth
        ctx.globalAlpha = Math.min(1, Math.max(0.1, 800/obj.z));
        
        // Draw the shape
        shapes[obj.shapeIndex](projectedX, projectedY, projectedSize, obj.color);
        
        ctx.restore();
      }
      
      // Continue animation loop
      animationFrameId = requestAnimationFrame(animate);
    };
    
    // Start animation
    animate();
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);
  
  return (
    <canvas 
      ref={canvasRef} 
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        opacity: 0.9,
      }}
    />
  );
};

const Home = ({ setMode }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Typing animation state
  const [displayText, setDisplayText] = useState("");
  const words = ["Search", "Generate", "Classify", "Tag", "Organize"];
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  // Typing animation effect (faster typing/deleting)
  useEffect(() => {
    const currentWord = words[wordIndex];
    const updateText = () => {
      setDisplayText((current) => {
        if (!isDeleting && current === currentWord) {
          // Word is complete, wait before deleting
          setTimeout(() => setIsDeleting(true), 800); // Reduced wait time
          return current;
        }
        if (isDeleting && current === "") {
          // Word is deleted, move to next word
          setIsDeleting(false);
          setWordIndex((prev) => (prev + 1) % words.length);
          return "";
        }

        if (isDeleting) {
          return current.slice(0, -1);
        }
        return currentWord.slice(0, current.length + 1);
      });
    };

    // Faster typing speed: 50ms for deleting, 80ms for typing
    const timeout = setTimeout(updateText, isDeleting ? 50 : 80);
    return () => clearTimeout(timeout);
  }, [displayText, isDeleting, wordIndex]);

  const handleTryNetraAI = () => {
    setLoading(true);
    navigate("/public-features");
    setLoading(false);
  };

  // State for the new hero section
const [activeFeature, setActiveFeature] = useState(0);

// Features for the hero section
const features = [
  {
    title: "Search",
    description: "Find any image in your library in seconds",
    icon: <Search className="w-6 h-6" />,
    color: "#ff006e"
  },
  {
    title: "Generate",
    description: "Create new visuals with simple text prompts",
    icon: <Sparkles className="w-6 h-6" />,
    color: "#8338ec"
  },
  {
    title: "Classify",
    description: "Auto-categorize your entire image library",
    icon: <Image className="w-6 h-6" />,
    color: "#3a86ff"
  },
  {
    title: "Organize",
    description: "Save hours with AI-powered organization",
    icon: <Clock className="w-6 h-6" />,
    color: "#00b4d8"
  }
];

// Auto-rotate through features
useEffect(() => {
  const interval = setInterval(() => {
    setActiveFeature((prev) => (prev + 1) % features.length);
  }, 3000);
  return () => clearInterval(interval);
}, []);

  const colors = {
    primary: "#550FCC",
    secondary: "#8338ec",
    accent: "#ff006e",
    text: "#2b2d42",
  };

  // Applications data
  const applications = [
    {
      icon: "📸",
      title: "Photographers",
      description:
        "Seamlessly tag and organize shoots. AI helps you find any photo in seconds and even create new visuals.",
    },
    {
      icon: "🛍️",
      title: "Retail & E-commerce",
      description:
        "Automatically tag, classify, and enhance product visuals, and generate marketing images that convert.",
    },
    {
      icon: "🏠",
      title: "Real Estate",
      description:
        "Sort property images, remove backgrounds, and generate virtual tours—all from a single AI-driven solution.",
    },
    {
      icon: "🎨",
      title: "Creative Agencies",
      description:
        "Focus on creativity while AI manages your asset library and instantly generates social-ready visuals.",
    },
  ];

  // Use cases data
  const useCases = [
    {
      title: "E-commerce",
      description:
        "Auto-tagging & classification of product images for frictionless customer discovery.",
    },
    {
      title: "Real Estate",
      description:
        "Identify property features & auto-generate curated galleries for clients.",
    },
    {
      title: "Advertising",
      description:
        "Quickly generate campaign visuals, tailor images for social media, and drive engagement.",
    },
    {
      title: "Social Media Marketing",
      description:
        "Instantly produce platform-optimized images & captions to boost brand visibility.",
    },
  ];

  return (
    <Container maxWidth={false} disableGutters>
      {/* Hero Section */}
      <Box
      sx={{
        width: "100%",
        height: { xs: "auto", sm: "90vh", md: "100vh" }, // Auto height on mobile allows content to breathe
        minHeight: "100vh", // Ensures minimum full height on all devices
        position: "relative",
        overflow: "hidden",
        paddingTop: { xs: "70px", sm: 0 }, // Add top padding on mobile for header space
      }}
    >
      {/* Dynamic gradient background - more modern than static image */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(135deg, #0f0c29, #302b63, #24243e)`,
          zIndex: 0,
        }}
      />
      
      {/* 3D Animated Background */}
      <ThreeDBackground />

      {/* Content overlay */}
      <Container
        maxWidth="lg"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: { xs: "flex-start", md: "center" }, // Align to top on mobile
          position: "relative",
          zIndex: 2,
          paddingTop: { xs: 4, sm: 0 }, // Add padding only on extra small devices
        }}
      >
        <Box 
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            gap: { xs: 6, md: 4 },
          }}
        >
          {/* Text content */}
          <Box 
            sx={{
              width: { xs: "100%", md: "50%" },
              textAlign: { xs: "center", md: "left" },
              animation: "fadeIn 1s ease-out",
              "@keyframes fadeIn": {
                from: { opacity: 0, transform: "translateY(20px)" },
                to: { opacity: 1, transform: "translateY(0)" },
              },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 800,
                fontSize: { xs: "2rem", sm: "3rem", md: "4.5rem" }, // Smaller on mobile
                lineHeight: 1.1,
                background: "linear-gradient(135deg, #fff, #ccc)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                mb: 3,
              }}
            >
              Your AI-Powered
              <br />
              Visual Content Hub
            </Typography>

            <Typography
              variant="h5"
              sx={{
                mb: 4,
                fontSize: { xs: "0.95rem", sm: "1.1rem", md: "1.4rem" }, // Smaller on mobile
                opacity: 0.9,
                fontWeight: 300,
                lineHeight: 1.6,
                color: "#fff",
              }}
            >
              Effortlessly tag, search, and enhance your images – say goodbye to manual organization.
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                mt: 4,
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <Button
                variant="contained"
                onClick={handleTryNetraAI}
                sx={{
                  fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" }, // Smaller on mobile
                  px: { xs: 3, md: 4 }, // Less padding on mobile
                  py: 1.75,
                  borderRadius: "50px",
                  background: "linear-gradient(45deg, #ff006e, #ff4d4d)",
                  fontWeight: 600,
                  textTransform: "none",
                  boxShadow: "0 4px 15px rgba(255, 0, 110, 0.3)",
                  "&:hover": {
                    background: "linear-gradient(45deg, #ff4d4d, #ff006e)",
                    transform: "translateY(-2px)",
                    boxShadow: "0 6px 20px rgba(255, 0, 110, 0.4)",
                  },
                  transition: "all 0.3s ease",
                  maxWidth: { xs: "100%", sm: "none" }, // Control max width on small devices
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Start Free Trial – No Credit Card Required"
                )}
              </Button>
            </Box>
            
            {/* Pricing teaser */}
            <Typography variant="body2" sx={{ mt: 2, color: "white", textAlign: { xs: "center", md: "left" } }}>
              Plans starting at just $19/month
            </Typography>
          </Box>

          {/* Interactive showcase on the right */}
          <Box 
            sx={{
              width: { xs: "100%", md: "50%" },
              position: "relative",
              height: { xs: "240px", sm: "320px", md: "400px" }, // Smaller height on mobile
              mt: { xs: 2, md: 0 }, // Add margin top on mobile
            }}
          >
            {/* Animated Feature Showcase */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                borderRadius: "24px",
                overflow: "hidden",
                boxShadow: "0 20px 50px rgba(0,0,0,0.3)",
                background: "rgba(0,0,0,0.2)",
                backdropFilter: "blur(10px)",
                border: "1px solid rgba(255,255,255,0.1)",
              }}
            >
              {/* Feature cards */}
              {features.map((feature, idx) => (
                <Box
                  key={idx}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: { xs: 2, sm: 3, md: 4 }, // Less padding on mobile
                    opacity: activeFeature === idx ? 1 : 0,
                    transform: `translateY(${activeFeature === idx ? 0 : 20}px)`,
                    transition: "all 0.5s ease-in-out",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "50px", sm: "60px", md: "80px" }, // Smaller on mobile
                      height: { xs: "50px", sm: "60px", md: "80px" }, // Smaller on mobile
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: feature.color,
                      color: "white",
                      mb: { xs: 2, md: 4 }, // Less margin on mobile
                      fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2rem" }, // Smaller on mobile
                      boxShadow: `0 10px 20px ${feature.color}40`,
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" }, // Smaller on mobile
                      fontWeight: 700,
                      mb: { xs: 1, md: 2 }, // Less margin on mobile
                      color: "white",
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "0.9rem", sm: "1rem", md: "1.25rem" }, // Smaller on mobile
                      textAlign: "center",
                      color: "rgba(255,255,255,0.8)",
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              ))}

              {/* Navigation dots */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  gap: 1,
                }}
              >
                {features.map((_, idx) => (
                  <Box
                    key={idx}
                    onClick={() => setActiveFeature(idx)}
                    sx={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      backgroundColor: activeFeature === idx ? "white" : "rgba(255,255,255,0.3)",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 6, md: 10 } }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: colors.primary,
            mb: 6,
            textAlign: "center",
            fontSize: { xs: "2rem", md: "2.5rem" },
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "60px",
              height: "4px",
              backgroundColor: colors.accent,
              borderRadius: "2px",
            },
          }}
        >
          All-in-One AI Image Platform
        </Typography>

        <Grid container spacing={4}>
          {/* Feature 1: AI-Powered Tagging & Search */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                p: 3,
                borderRadius: "16px",
                boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                },
              }}
            >
              <Box
                sx={{
                  background: "linear-gradient(135deg, #8338ec, #550FCC)",
                  borderRadius: "12px",
                  width: "fit-content",
                  p: 2,
                  mb: 3,
                }}
              >
                <Search className="w-10 h-10 text-white" />
              </Box>
              <Typography variant="h4" sx={{ fontWeight: 700, mb: 1, color: colors.text }}>
                AI-Powered Tagging & Search
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: colors.text }}>
                Let AI automatically tag, classify, and index your images so you can find any asset in seconds.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text, lineHeight: 1.6 }}>
                • Instant auto-tagging <br />
                • Smart text & visual search <br />
                • Zero manual effort
              </Typography>
            </Card>
          </Grid>

          {/* Feature 2: AI Design Copilot */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                p: 3,
                borderRadius: "16px",
                boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                },
              }}
            >
              <Box
                sx={{
                  background: "linear-gradient(135deg, #ff4d4d, #ff006e)",
                  borderRadius: "12px",
                  width: "fit-content",
                  p: 2,
                  mb: 3,
                }}
              >
                <Sparkles className="w-10 h-10 text-white" />
              </Box>
              <Typography variant="h4" sx={{ fontWeight: 700, mb: 1, color: colors.text }}>
                AI Design Copilot
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: colors.text }}>
                Enhance, resize, and transform your visuals with one click – no design skills required.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text, lineHeight: 1.6 }}>
                • One-click enhancements <br />
                • Bulk editing & resizing <br />
                • Instant social-ready outputs
              </Typography>
            </Card>
          </Grid>

          {/* Feature 3: Custom Tagging */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                p: 3,
                borderRadius: "16px",
                boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                },
              }}
            >
              <Box
                sx={{
                  background: "linear-gradient(135deg, #00b4d8, #0077b6)",
                  borderRadius: "12px",
                  width: "fit-content",
                  p: 2,
                  mb: 3,
                }}
              >
                <Box className="w-10 h-10 text-white">🎯</Box>
              </Box>
              <Typography variant="h4" sx={{ fontWeight: 700, mb: 1, color: colors.text }}>
                Custom Tagging
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: colors.text }}>
                Train custom AI models to recognize industry-specific assets – perfect for growing brands.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text, lineHeight: 1.6 }}>
                • Custom class management <br />
                • Tailored to your business <br />
                • Advanced accuracy
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Design Copilot Highlight Section */}
      <Box sx={{ backgroundColor: "#fff", py: { xs: 6, md: 10 } }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  color: colors.primary,
                  mb: 3,
                  fontSize: { xs: "2rem", md: "2.5rem" },
                }}
              >
                Meet Your AI Design Copilot
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text, mb: 3 }}>
                An AI-powered consultant that elevates your visuals. Collaborate via simple chat commands to optimize, generate, or enhance your creative assets instantly.
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Box
                      sx={{
                        backgroundColor: colors.accent,
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        mr: 2,
                      }}
                    >
                      💡
                    </Box>
                    <Box>
                      <Typography variant="h6" sx={{ mb: 0.5, color: colors.text }}>
                        Intelligent Conversation
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Get design feedback & suggestions within a chat interface.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Box
                      sx={{
                        backgroundColor: colors.secondary,
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        mr: 2,
                      }}
                    >
                      ✨
                    </Box>
                    <Box>
                      <Typography variant="h6" sx={{ mb: 0.5, color: colors.text }}>
                        One-Click Enhancements
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Automate resizing, cropping, and color adjustments for perfect images.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        backgroundColor: colors.primary,
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        mr: 2,
                      }}
                    >
                      🎯
                    </Box>
                    <Box>
                      <Typography variant="h6" sx={{ mb: 0.5, color: colors.text }}>
                        Social-Ready Variants
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Automatically generate platform-optimized versions for Instagram, Facebook, Twitter, and more.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* Testimonial snippet */}
              <Typography variant="body2" sx={{ mt: 3, fontStyle: "italic", color: colors.text }}>
                “Our marketing team saved hours every week by letting Netra’s AI handle repetitive image edits.”
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -20,
                    right: -20,
                    width: "100%",
                    height: "100%",
                    borderRadius: "16px",
                    background: "linear-gradient(135deg, #550FCC, #8338ec)",
                    zIndex: 0,
                  },
                }}
              >
                <Card
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    borderRadius: "16px",
                    p: 3,
                    boxShadow: "0 15px 35px rgba(0,0,0,0.1)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      backgroundColor: "#f8f9fa",
                      borderRadius: "8px",
                      p: 2,
                      mb: 2,
                    }}
                  >
                    <Typography variant="body1" sx={{ color: colors.text }}>
                      “I need a professional version of this product shot, plus a quick social media teaser.”
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      backgroundColor: colors.primary,
                      borderRadius: "8px",
                      p: 2,
                      color: "white",
                    }}
                  >
                    <Typography variant="body1">
                      “Sure! I'll enhance the lighting, remove the background, and create an Instagram-ready version. Ready in 10 seconds.”
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Applications Section */}
      <Box sx={{ backgroundColor: "#f8f9fa", py: { xs: 6, md: 10 } }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              color: colors.primary,
              mb: 6,
              textAlign: "center",
              fontSize: { xs: "2rem", md: "2.5rem" },
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "60px",
                height: "4px",
                backgroundColor: colors.accent,
                borderRadius: "2px",
              },
            }}
          >
            Transform Your Industry with AI
          </Typography>
          <Grid container spacing={4}>
            {applications.map((app, idx) => (
              <Grid item xs={12} sm={6} md={3} key={idx}>
                <Card
                  sx={{
                    height: "100%",
                    borderRadius: "16px",
                    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "translateY(-8px)",
                    },
                  }}
                >
                  <CardContent sx={{ p: 3, textAlign: "center" }}>
                    <Typography
                      variant="h2"
                      sx={{
                        mb: 2,
                        fontSize: "3rem",
                      }}
                    >
                      {app.icon}
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 700, mb: 1, color: colors.text }}>
                      {app.title}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.6 }}>
                      {app.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Use Cases Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 6, md: 10 } }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: colors.primary,
            mb: 6,
            textAlign: "center",
            fontSize: { xs: "2rem", md: "2.5rem" },
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "60px",
              height: "4px",
              backgroundColor: colors.accent,
              borderRadius: "2px",
            },
          }}
        >
          Real-World Use Cases
        </Typography>
        <Grid container spacing={4}>
          {useCases.map((useCase, idx) => (
            <Grid item xs={12} sm={6} md={3} key={idx}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: "16px",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "translateY(-8px)",
                  },
                }}
              >
                <CardContent sx={{ p: 3, textAlign: "center" }}>
                  <Typography variant="h5" sx={{ fontWeight: 700, mb: 1, color: colors.text }}>
                    {useCase.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.6 }}>
                    {useCase.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Final CTA Section */}
      <Box
        sx={{
          background: 'linear-gradient(135deg, #550FCC, #8338ec)',
          py: { xs: 8, md: 12 },
          color: "white",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'url("data:image/svg+xml,%3Csvg width=\\"20\\" height=\\"20\\" xmlns=\\"http://www.w3.org/2000/svg\\"%3E%3Cpath d=\\"M0 0h20v20H0z\\" fill=\\"%23ffffff\\" fill-opacity=\\"0.05\\"/%3E%3C/svg%3E")',
            opacity: 0.1,
          },
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ maxWidth: "800px", mx: "auto", position: "relative", zIndex: 1 }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 700,
                mb: 3,
                fontSize: { xs: "2rem", md: "2.5rem" },
              }}
            >
              Ready to Transform Your Image Workflow with AI?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                opacity: 0.9,
                fontSize: { xs: "1.1rem", md: "1.25rem" },
                lineHeight: 1.6,
              }}
            >
              Sign up today and experience the power of Netra.AI for your visual content. No credit card required, cancel anytime.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/signup")}
              sx={{
                px: 3,
                py: 1.5,
                fontSize: { xs: "1rem", md: "1.2rem" },
                borderRadius: "50px",
                backgroundColor: "white",
                color: colors.primary,
                textTransform: "none",
                fontWeight: 600,
                boxShadow: "0 4px 15px rgba(255, 255, 255, 0.2)",
                "&:hover": {
                  backgroundColor: colors.accent,
                  color: "white",
                  transform: "translateY(-2px)",
                  boxShadow: "0 6px 20px rgba(255, 255, 255, 0.3)",
                },
                transition: "all 0.3s ease",
              }}
            >
              Start Free Trial
            </Button>
            <Typography variant="body2" sx={{ mt: 2, opacity: 0.8 }}>
              No commitments. Cancel anytime.
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid rgba(0,0,0,0.1)",
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant="body2" sx={{ color: colors.text, opacity: 0.8 }}>
              © {new Date().getFullYear()} Netra.AI. All rights reserved.
            </Typography>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Link
                href="/privacy"
                sx={{
                  color: colors.text,
                  opacity: 0.8,
                  textDecoration: "none",
                  "&:hover": {
                    opacity: 1,
                    color: colors.primary,
                  },
                }}
              >
                Privacy Policy
              </Link>
              <Link
                href="/terms"
                sx={{
                  color: colors.text,
                  opacity: 0.8,
                  textDecoration: "none",
                  "&:hover": {
                    opacity: 1,
                    color: colors.primary,
                  },
                }}
              >
                Terms of Service
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Home;
