import React from "react";
import { Container, Typography, Box, Link, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const colors = {
  primary: "#550FCC",
  secondary: "#8338ec",
  accent: "#ff006e",
  text: "#2b2d42",
  background: "#fafafa",
  codeBackground: "#eaeaea",
};

const APIDocumentation = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        py: { xs: 6, md: 10 },
      }}
    >
      {/* Main Heading */}
      <Box sx={{ mb: 6, textAlign: "center" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: colors.primary,
            mb: 2,
            fontSize: { xs: "2rem", md: "2.5rem" },
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "60px",
              height: "4px",
              backgroundColor: colors.accent,
              borderRadius: "2px",
            },
          }}
        >
          Netra.AI API Documentation
        </Typography>
        <Typography variant="body1" sx={{ color: colors.text, opacity: 0.8 }}>
          A comprehensive reference for Netra.AI’s RESTful backend API. Below you'll find detailed information on endpoints, parameters, example requests, and responses.
        </Typography>
      </Box>

      {/* Overview Section */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: 700, mb: 2, color: colors.primary }}>
          Overview
        </Typography>
        <Typography variant="body1" sx={{ color: colors.text, lineHeight: 1.7 }}>
          Netra.AI provides a robust RESTful API for managing, discovering, and generating images with advanced AI functionalities. The API is divided into several main categories:
        </Typography>
        <Box sx={{ pl: 2, mt: 2 }}>
          <Typography variant="body1" sx={{ color: colors.text }}>
            • <strong>Organize & Discover</strong>: Upload, classify, search your image library, and perform web searches.<br />
            • <strong>Create & Generate</strong>: Perform image operations like background removal, resizing, format conversion, and utilize the Design Copilot for creative tasks.<br />
            • <strong>Custom Models</strong>: Create, train, manage, and use your own custom classification models.
          </Typography>
        </Box>
      </Box>

      {/* Authentication Section */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          1. Authentication
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 2, color: colors.text, lineHeight: 1.7 }}
        >
          Most API endpoints require a valid JSON Web Token (JWT) for authentication. To access these endpoints, follow the authentication process below.
        </Typography>

        {/* Register Endpoint */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="register-content" id="register-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              Register
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /register</code><br />
              <strong>Description:</strong> Register a new user account.
            </Typography>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>username (string, required)</code><br />
                • <code>password (string, required)</code><br />
                • <code>email (string, required)</code>
              </Typography>
            </Box>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`POST /register
Content-Type: multipart/form-data

username: johndoe
password: securepassword123
email: johndoe@example.com`}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`{
  "message": "User registered successfully"
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Login Endpoint */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="login-content" id="login-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              Login
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /login</code><br />
              <strong>Description:</strong> Authenticate a user and obtain a JWT.
            </Typography>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>username (string, required)</code><br />
                • <code>password (string, required)</code>
              </Typography>
            </Box>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`POST /login
Content-Type: multipart/form-data

username: johndoe
password: securepassword123`}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`{
  "access_token": "<JWT_TOKEN>",
  "token_type": "bearer"
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Current User Endpoint */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="me-content" id="me-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              Current User
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>GET /me</code><br />
              <strong>Description:</strong> Retrieve the profile of the currently authenticated user.
            </Typography>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Headers:
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>Authorization: Bearer &lt;JWT_TOKEN&gt;</code>
              </Typography>
            </Box>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`GET /me
Authorization: Bearer <JWT_TOKEN>`}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`{
  "id": "user123",
  "username": "johndoe",
  "email": "johndoe@example.com",
  "created_at": "2024-01-01T12:00:00Z"
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* 2. Organize & Discover */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          2. Organize & Discover
        </Typography>

        {/* A. Upload & Classify Images */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="upload-classify-content" id="upload-classify-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              A. Upload & Classify Images
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /images/classify</code><br />
              <strong>Description:</strong> Upload images or provide image URLs to classify them using a custom model or default GPT-based image captioning.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Request Types:
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <strong>multipart/form-data</strong><br />
                • <strong>application/json</strong>
              </Typography>
            </Box>

            {/* 1. multipart/form-data */}
            <Box sx={{ pl: 4, mt: 2 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: colors.text }}>
                1) multipart/form-data
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                Parameters:
              </Typography>
              <Box sx={{ pl: 2, mt: 1 }}>
                <Typography variant="body2">
                  • <code>model_id (string, optional)</code> – Use a specific custom model for classification.<br />
                  • <code>files (File[])</code> – One or more image files to classify.
                </Typography>
              </Box>
            </Box>

            {/* 2. application/json */}
            <Box sx={{ pl: 4, mt: 2 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: colors.text }}>
                2) application/json
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                Request Body Example:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "model_id": "optional_custom_model_id",
  "image_urls": [
    "https://example.com/image1.jpg",
    "https://example.com/image2.png"
  ]
}`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "classification_results": [
    {
      "filename": "cat.jpg",
      "image_url": null,
      "suggested_class": "Cat",
      "suggested_tags": ["animal", "cat", "pet"],
      "error": null
    },
    {
      "filename": null,
      "image_url": "https://example.com/dog.png",
      "suggested_class": "Dog",
      "suggested_tags": ["animal", "dog", "pet"],
      "error": null
    }
  ]
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* B. Save Images to Library */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="save-library-content" id="save-library-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              B. Save Images to Library
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /images/save</code><br />
              <strong>Description:</strong> Save classified images to your personal library with optional organization parameters.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>files (File[])</code> – The images to upload.<br />
                • <code>folder_id (string, optional)</code> – The folder to place images in.<br />
                • <code>class_names (string[])</code> – Classification labels for each file.<br />
                • <code>common_class (string, optional)</code> – A single common classification label for all files.<br />
                • <code>tags (string[])</code> – Optional list of tags.<br />
                • <code>annotations (string, optional)</code> – Optional text annotations.
              </Typography>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "save_results": [
    {
      "filename": "product.jpg",
      "message": "Image saved successfully",
      "class_label": "Shoes",
      "tags": ["footwear", "sports"],
      "folder": "SummerCollection"
    },
    {
      "filename": "hat.jpg",
      "message": "Image saved successfully",
      "class_label": "Accessories",
      "tags": ["fashion", "hat"],
      "folder": "SummerCollection"
    }
  ]
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* C. Search Your Image Library */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="search-library-content" id="search-library-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              C. Search Your Image Library
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /images/search</code><br />
              <strong>Description:</strong> Search your image library using text queries, date ranges, class labels, or by uploading an image for visual similarity.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters:
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>query_text (string, optional)</code> – Text query for searching.<br />
                • <code>start_date (string, optional)</code> – Start date in YYYY-MM-DD format.<br />
                • <code>end_date (string, optional)</code> – End date in YYYY-MM-DD format.<br />
                • <code>class_label_filter (string, optional)</code> – Filter by class label.<br />
                • <code>folder_id (string, optional)</code> – Filter by folder.<br />
                • <code>file (File, optional)</code> – Upload an image for visual similarity search (multipart/form-data).
              </Typography>
            </Box>

            {/* Example Request (JSON) */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request (application/json):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "query_text": "sunset beach",
  "start_date": "2024-01-01",
  "end_date": "2024-12-31",
  "class_label_filter": "Nature",
  "folder_id": "folder123"
}`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "results": [
    {
      "image_url": "https://storage.googleapis.com/netra-images/folder123/img1.jpg",
      "class_label": "Nature",
      "tags": ["sunset", "beach", "ocean"],
      "similarity": 0.92
    },
    {
      "image_url": "https://storage.googleapis.com/netra-images/folder123/img2.jpg",
      "class_label": "Nature",
      "tags": ["sunset", "forest", "trees"],
      "similarity": 0.89
    }
  ],
  "total_results": 2,
  "page_number": 1,
  "page_size": 10
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* D. Web Image Search */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="web-image-search-content" id="web-image-search-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              D. Web Image Search
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /web-images/search</code><br />
              <strong>Description:</strong> Search public images on the web using Bing Image Search. You can provide a text prompt or upload an image to enhance the search query.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data or JSON):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>query_text (string, optional)</code> – Text prompt for searching.<br />
                • <code>file (File, optional)</code> – Upload an image to generate an enhanced query.<br />
                • <code>page_size (number, optional)</code> – Number of results per page.<br />
                • <code>page_number (number, optional)</code> – Page number for pagination.<br />
                • <code>img_size (string, optional)</code> – Filter by image size (e.g., large, medium, small).<br />
                • <code>img_license (string, optional)</code> – Filter by image license.
              </Typography>
            </Box>

            {/* Example Request (JSON) */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request (application/json):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "query_text": "modern art",
  "page_size": 15,
  "page_number": 1,
  "img_size": "large",
  "img_license": "public"
}`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "results": [
    {
      "contentUrl": "https://example.com/image1.jpg",
      "similarity": 0.88,
      "thumbnailUrl": "https://example.com/thumb1.jpg",
      "title": "Modern Art Piece 1"
    },
    {
      "contentUrl": "https://example.com/image2.jpg",
      "similarity": 0.85,
      "thumbnailUrl": "https://example.com/thumb2.jpg",
      "title": "Modern Art Piece 2"
    }
  ],
  "total_results": 1000,
  "page_number": 1,
  "page_size": 15
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* 3. Create & Generate */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          3. Create & Generate
        </Typography>

        <Typography variant="body1" sx={{ mb: 4, color: colors.text, lineHeight: 1.7 }}>
          This category includes endpoints for various image manipulation tasks such as background removal, image generation, resizing, format conversion, color palette generation, and more. Additionally, the Design Copilot provides advanced AI-driven design assistance.
        </Typography>

        {/* A. Remove Background */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="remove-background-content" id="remove-background-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              A. Remove Background
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /remove-background</code><br />
              <strong>Description:</strong> Remove the background from an image, returning a transparent or white background image.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>image (File, required)</code> – The image file to process.<br />
                • <code>output_format (string, required)</code> – Desired output format: "png" or "jpeg".
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`POST /remove-background
Content-Type: multipart/form-data

image: <File>
output_format: "png"`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response:
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text, mt: 1 }}>
                Returns the processed image file with the background removed.
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* B. Bulk Resize Images */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="bulk-resize-content" id="bulk-resize-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              B. Bulk Resize Images
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /images/bulk-resize</code><br />
              <strong>Description:</strong> Resize multiple images based on specified dimensions and output formats.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Request Body (application/json):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "image_urls": [
    "https://example.com/image1.jpg",
    "https://example.com/image2.png"
  ],
  "width": 800,
  "height": null,
  "mode": "fit",
  "output_format": "jpeg",
  "quality": 90,
  "prefix": "resized_"
}`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "resized_images": [
    {
      "original_url": "https://example.com/image1.jpg",
      "resized_url": "https://storage.googleapis.com/netra-resized/resized_image1.jpeg",
      "original_size": [1920, 1080],
      "new_size": [800, 450],
      "file_size": 123456
    },
    {
      "original_url": "https://example.com/image2.png",
      "resized_url": "https://storage.googleapis.com/netra-resized/resized_image2.jpeg",
      "original_size": [1280, 720],
      "new_size": [800, 450],
      "file_size": 78901
    }
  ],
  "total_processed": 2,
  "failed_urls": [],
  "storage_path": "https://storage.googleapis.com/netra-resized/"
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* C. Design Copilot */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="design-copilot-content" id="design-copilot-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              C. Design Copilot (Chat)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /design-copilot/chat</code><br />
              <strong>Description:</strong> Interact with an AI assistant that can chain multiple image operations in a single conversation. Useful for complex image processing tasks.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data or application/json):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>message (string, required)</code> – The user's message or request.<br />
                • <code>session_id (string, optional)</code> – To continue an existing conversation.<br />
                • <code>files (File[], optional)</code> – Any images or PDFs to reference or process.
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request (application/json):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "message": "Enhance the uploaded images for social media",
  "session_id": "design_chat_12345678",
  "files": ["<File uploads>"] // optional images or PDFs
}`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "session_id": "design_chat_12345678",
  "response": "Sure! I'll enhance the lighting, remove the background, and create an Instagram-ready version. Ready in 10 seconds.",
  "messages": [
    {
      "role": "user",
      "content": "Enhance the uploaded images for social media",
      "attachments": []
    },
    {
      "role": "assistant",
      "content": "Sure! I'll enhance the lighting, remove the background, and create an Instagram-ready version. Ready in 10 seconds.",
      "attachments": ["https://storage.googleapis.com/netra-enhanced/img1.png"],
      "processing_results": {}
    }
  ],
  "processing_results": {},
  "suggestions": {
    "search_queries": ["Best Instagram image sizes", "How to enhance lighting in photos"],
    "generation_prompts": ["Generate a high-resolution Instagram post image with enhanced lighting."]
  }
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* 4. Custom Models */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          4. Custom Models
        </Typography>

        <Typography variant="body1" sx={{ mb: 4, color: colors.text, lineHeight: 1.7 }}>
          Manage your own custom image classification models. This includes creating, listing, updating, deleting models, managing classes within models, and performing predictions.
        </Typography>

        {/* A. Models CRUD */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="models-crud-content" id="models-crud-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              A. Models CRUD
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              Perform Create, Read, Update, and Delete operations on your custom models.
            </Typography>

            {/* Create Model */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="create-model-content" id="create-model-header">
                <Typography variant="subtitle1" sx={{ fontWeight: 700, color: colors.text }}>
                  Create Model
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ color: colors.text }}>
                  <strong>Endpoint:</strong> <code>POST /models</code><br />
                  <strong>Description:</strong> Create a new custom image classification model.
                </Typography>

                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Parameters (multipart/form-data or application/json):
                  </Typography>
                  <Typography variant="body2" sx={{ color: colors.text }}>
                    • <code>name (string, required)</code> – Name of the model.<br />
                    • <code>description (string, required)</code> – Description of the model.
                  </Typography>
                </Box>

                {/* Example Request */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Request (application/json):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "monospace",
                        whiteSpace: "pre-wrap",
                      }}
                    >
{`{
  "name": "My Custom Classifier",
  "description": "A model to classify product images."
}`}
                    </Typography>
                  </Box>
                </Box>

                {/* Example Response */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Response (201):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "monospace",
                        whiteSpace: "pre-wrap",
                      }}
                    >
{`{
  "id": "model123",
  "name": "My Custom Classifier",
  "description": "A model to classify product images.",
  "class_count": 0,
  "shared": false,
  "is_owner": true,
  "class_details": {}
}`}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            {/* List Models */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="list-models-content" id="list-models-header">
                <Typography variant="subtitle1" sx={{ fontWeight: 700, color: colors.text }}>
                  List Models
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ color: colors.text }}>
                  <strong>Endpoint:</strong> <code>GET /models</code><br />
                  <strong>Description:</strong> Retrieve a list of all models owned or shared with the current user.
                </Typography>

                {/* Example Response */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Response (200):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "monospace",
                        whiteSpace: "pre-wrap",
                      }}
                    >
{`{
  "models": [
    {
      "id": "model123",
      "name": "My Custom Classifier",
      "description": "A model to classify product images.",
      "class_count": 3,
      "shared": false,
      "is_owner": true
    },
    {
      "id": "model456",
      "name": "Shared Classifier",
      "description": "Shared model for general classification.",
      "class_count": 5,
      "shared": true,
      "is_owner": false
    }
  ]
}`}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            {/* Get Model Details */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="get-model-content" id="get-model-header">
                <Typography variant="subtitle1" sx={{ fontWeight: 700, color: colors.text }}>
                  Get Model Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ color: colors.text }}>
                  <strong>Endpoint:</strong> <code>GET /models/:model_id</code><br />
                  <strong>Description:</strong> Retrieve detailed information about a specific model.
                </Typography>

                {/* Example Request */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Request:
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`GET /models/model123
Authorization: Bearer <JWT_TOKEN>`}
                    </Typography>
                  </Box>
                </Box>

                {/* Example Response */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Response (200):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`{
  "id": "model123",
  "name": "My Custom Classifier",
  "description": "A model to classify product images.",
  "class_count": 3,
  "shared": false,
  "is_owner": true,
  "classes": [
    {
      "name": "Shoes",
      "reasoning": "Footwear products",
      "embedding_choice": "image"
    },
    {
      "name": "Accessories",
      "reasoning": "Fashion accessories",
      "embedding_choice": "combined"
    },
    {
      "name": "Clothing",
      "reasoning": "Apparel items",
      "embedding_choice": "image"
    }
  ]
}`}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            {/* Update Model */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="update-model-content" id="update-model-header">
                <Typography variant="subtitle1" sx={{ fontWeight: 700, color: colors.text }}>
                  Update Model
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ color: colors.text }}>
                  <strong>Endpoint:</strong> <code>PUT /models/:model_id</code><br />
                  <strong>Description:</strong> Update the name, description, or classes of a specific model.
                </Typography>

                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Parameters (application/json):
                  </Typography>
                  <Typography variant="body2" sx={{ color: colors.text }}>
                    • <code>name (string, optional)</code> – New name for the model.<br />
                    • <code>description (string, optional)</code> – New description for the model.<br />
                    • <code>classes (object, optional)</code> – Updated class details.
                  </Typography>
                </Box>

                {/* Example Request */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Request (application/json):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "monospace",
                        whiteSpace: "pre-wrap",
                      }}
                    >
{`{
  "name": "Updated Classifier",
  "description": "An updated description for the model.",
  "classes": {
    "Shoes": {
      "reasoning": "Updated reasoning for Shoes.",
      "embedding_choice": "combined"
    }
  }
}`}
                    </Typography>
                  </Box>
                </Box>

                {/* Example Response */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Response (200):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "monospace",
                        whiteSpace: "pre-wrap",
                      }}
                    >
{`{
  "id": "model123",
  "name": "Updated Classifier",
  "description": "An updated description for the model.",
  "class_count": 3,
  "shared": false,
  "is_owner": true,
  "classes": [
    {
      "name": "Shoes",
      "reasoning": "Updated reasoning for Shoes.",
      "embedding_choice": "combined"
    },
    {
      "name": "Accessories",
      "reasoning": "Fashion accessories",
      "embedding_choice": "combined"
    },
    {
      "name": "Clothing",
      "reasoning": "Apparel items",
      "embedding_choice": "image"
    }
  ]
}`}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            {/* Delete Model */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="delete-model-content" id="delete-model-header">
                <Typography variant="subtitle1" sx={{ fontWeight: 700, color: colors.text }}>
                  Delete Model
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ color: colors.text }}>
                  <strong>Endpoint:</strong> <code>DELETE /models/:model_id</code><br />
                  <strong>Description:</strong> Delete a specific model and remove associated data from storage.
                </Typography>

                {/* Example Request */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Request:
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`DELETE /models/model123
Authorization: Bearer <JWT_TOKEN>`}
                    </Typography>
                  </Box>
                </Box>

                {/* Example Response */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Response (200):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`{
  "message": "Model deleted successfully."
}`}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            {/* Share Model */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="share-model-content" id="share-model-header">
                <Typography variant="subtitle1" sx={{ fontWeight: 700, color: colors.text }}>
                  Share Model
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ color: colors.text }}>
                  <strong>Endpoint:</strong> <code>POST /models/:model_id/share</code><br />
                  <strong>Description:</strong> Share a specific model with another user.
                </Typography>

                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Parameters (application/json):
                  </Typography>
                  <Typography variant="body2" sx={{ color: colors.text }}>
                    • <code>target_username (string, required)</code> – The username of the user to share the model with.
                  </Typography>
                </Box>

                {/* Example Request */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Request (application/json):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "monospace",
                        whiteSpace: "pre-wrap",
                      }}
                    >
{`{
  "target_username": "janedoe"
}`}
                    </Typography>
                  </Box>
                </Box>

                {/* Example Response */}
                <Box sx={{ pl: 2, mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                    Example Response (200):
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.codeBackground,
                      borderRadius: 1,
                      p: 2,
                      mt: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`{
  "message": "Model shared successfully with janedoe."
}`}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* 5. Custom Models - Classes & Inference */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          5. Classes &amp; Inference
        </Typography>

        {/* A. Add Class */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="add-class-content" id="add-class-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              A. Add Class to Model
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /models/:model_id/classes</code><br />
              <strong>Description:</strong> Add a new classification class to a specific model, including example images.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>name (string, required)</code> – Name of the new class.<br />
                • <code>reasoning (string, optional)</code> – Reasoning for the class.<br />
                • <code>embedding_choice (string, required)</code> – "image" or "combined".<br />
                • <code>files (File[], required)</code> – Example images for the class.
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`POST /models/model123/classes
Content-Type: multipart/form-data

name: "Shoes"
reasoning: "Footwear products"
embedding_choice: "image"
files: [shoe1.jpg, shoe2.jpg]`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (201):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`{
  "message": "Class added successfully",
  "class_name": "Shoes"
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* B. Predict */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="predict-content" id="predict-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              B. Predict with Model
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /models/:model_id/predict</code><br />
              <strong>Description:</strong> Perform a prediction on a single image using a specified model.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>file (File, required)</code> – The image file to predict.
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`POST /models/model123/predict
Content-Type: multipart/form-data

file: product_image.jpg`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
{`{
  "predictions": [
    ["Shoes", 0.85],
    ["Clothing", 0.15]
  ]
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* C. Inference Logs */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="inference-logs-content" id="inference-logs-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              C. Inference Logs
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>GET /models/:model_id/inferences</code><br />
              <strong>Description:</strong> Retrieve a list of all predictions made using a specific model.
            </Typography>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                  overflow: "auto",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "inferences": [
    {
      "id": "infer123",
      "image_url": "https://example.com/image1.jpg",
      "predictions": [
        ["Shoes", 0.85],
        ["Clothing", 0.15]
      ],
      "timestamp": "2024-04-01T12:00:00Z"
    },
    {
      "id": "infer124",
      "image_url": "https://example.com/image2.png",
      "predictions": [
        ["Clothing", 0.70],
        ["Shoes", 0.30]
      ],
      "timestamp": "2024-04-01T12:05:00Z"
    }
  ]
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* 6. Additional Endpoints */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          6. Additional Endpoints
        </Typography>

        {/* A. Generate Image (Text to Image) */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="generate-image-content" id="generate-image-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              A. Generate Image (Text to Image)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /generate-image</code><br />
              <strong>Description:</strong> Generate images based on text prompts using Stability AI.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data or application/json):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>prompt (string, required)</code> – Text prompt for image generation.<br />
                • <code>negative_prompt (string, optional)</code> – Negative prompts to avoid certain elements.<br />
                • <code>aspect_ratio (string, optional)</code> – Desired aspect ratio (e.g., "16:9").<br />
                • <code>seed (number, optional)</code> – Seed for random number generator.<br />
                • <code>output_format (string, required)</code> – "png" or "jpeg".<br />
                • <code>image_count (number, optional)</code> – Number of images to generate.<br />
                • <code>weight (number, optional)</code> – Weighting for prompt influence.
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request (application/json):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "prompt": "A serene sunset over a mountain range",
  "negative_prompt": "no people, no buildings",
  "aspect_ratio": "16:9",
  "seed": 12345,
  "output_format": "png",
  "image_count": 3,
  "weight": 0.8
}`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response:
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text, mt: 1 }}>
                Returns a stream of generated image files.
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* B. Image-to-Image Control */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="control-image-content" id="control-image-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              B. Image-to-Image Control
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /control-image</code><br />
              <strong>Description:</strong> Generate an image based on a text prompt and a control image to guide the generation process.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>prompt (string, required)</code> – Text prompt for image generation.<br />
                • <code>control_strength (number, required)</code> – Strength of control image influence.<br />
                • <code>image (File, required)</code> – The control image file.
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`POST /control-image
Content-Type: multipart/form-data

prompt: "A futuristic cityscape at night"
control_strength: 0.7
image: <File: city_control.jpg>`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response:
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text, mt: 1 }}>
                Returns a single generated image file influenced by the control image.
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* C. Download Generated Images */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="download-generated-images-content" id="download-generated-images-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              C. Download Generated Images
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /download-generated-images</code><br />
              <strong>Description:</strong> Download multiple generated images by passing their URLs. Returns a ZIP archive containing the requested images.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (application/json):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>image_urls (string[], required)</code> – Array of image URLs to download.
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "image_urls": [
    "https://storage.googleapis.com/netra-images/generated/img1.png",
    "https://storage.googleapis.com/netra-images/generated/img2.png"
  ]
}`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response:
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text, mt: 1 }}>
                Returns a ZIP file stream containing the requested images.
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* 7. Custom Models - Classes & Inference */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          7. Custom Models - Classes &amp; Inference
        </Typography>

        {/* A. Add Class */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="add-class-content" id="add-class-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              A. Add Class to Model
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /models/:model_id/classes</code><br />
              <strong>Description:</strong> Add a new classification class to a specific model, including example images.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>name (string, required)</code> – Name of the new class.<br />
                • <code>reasoning (string, optional)</code> – Reasoning for the class.<br />
                • <code>embedding_choice (string, required)</code> – "image" or "combined".<br />
                • <code>files (File[], required)</code> – Example images for the class.
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`POST /models/model123/classes
Content-Type: multipart/form-data

name: "Shoes"
reasoning: "Footwear products"
embedding_choice: "image"
files: [shoe1.jpg, shoe2.jpg]`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (201):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "message": "Class added successfully",
  "class_name": "Shoes"
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* B. Predict */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="predict-content" id="predict-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              B. Predict with Model
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>POST /models/:model_id/predict</code><br />
              <strong>Description:</strong> Perform a prediction on a single image using a specified model.
            </Typography>

            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Parameters (multipart/form-data):
              </Typography>
              <Typography variant="body2" sx={{ color: colors.text }}>
                • <code>file (File, required)</code> – The image file to predict.
              </Typography>
            </Box>

            {/* Example Request */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Request:
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`POST /models/model123/predict
Content-Type: multipart/form-data

file: product_image.jpg`}
                </Typography>
              </Box>
            </Box>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "predictions": [
    ["Shoes", 0.85],
    ["Clothing", 0.15]
  ]
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* C. Inference Logs */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="inference-logs-content" id="inference-logs-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              C. Inference Logs
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <strong>Endpoint:</strong> <code>GET /models/:model_id/inferences</code><br />
              <strong>Description:</strong> Retrieve a list of all predictions made using a specific model.
            </Typography>

            {/* Example Response */}
            <Box sx={{ pl: 2, mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
                Example Response (200):
              </Typography>
              <Box
                sx={{
                  backgroundColor: colors.codeBackground,
                  borderRadius: 1,
                  p: 2,
                  mt: 1,
                  overflow: "auto",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                  }}
                >
{`{
  "inferences": [
    {
      "id": "infer123",
      "image_url": "https://example.com/image1.jpg",
      "predictions": [
        ["Shoes", 0.85],
        ["Clothing", 0.15]
      ],
      "timestamp": "2024-04-01T12:00:00Z"
    },
    {
      "id": "infer124",
      "image_url": "https://example.com/image2.png",
      "predictions": [
        ["Clothing", 0.70],
        ["Shoes", 0.30]
      ],
      "timestamp": "2024-04-01T12:05:00Z"
    }
  ]
}`}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* 8. Error Handling */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          8. Error Handling
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, color: colors.text, lineHeight: 1.7 }}>
          The API uses standard HTTP status codes to indicate the success or failure of an API request. Below are common error responses you may encounter.
        </Typography>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="error-handling-content" id="error-handling-header">
            <Typography variant="h6" sx={{ fontWeight: 700, color: colors.text }}>
              Common Error Responses
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: colors.text }}>
              <ul style={{ marginLeft: "1.2rem", lineHeight: 1.7 }}>
                <li><strong>400 Bad Request</strong> – Invalid input or missing parameters.</li>
                <li><strong>401 Unauthorized</strong> – Missing or invalid JWT token.</li>
                <li><strong>403 Forbidden</strong> – User not allowed to access the resource.</li>
                <li><strong>404 Not Found</strong> – Resource does not exist.</li>
                <li><strong>500 Internal Server Error</strong> – Server or external API error.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Example Usage Flow */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: colors.primary }}
        >
          9. Example Usage Flow
        </Typography>
        <Typography variant="body1" sx={{ color: colors.text, lineHeight: 1.7 }}>
          Here's a typical workflow for using the Netra.AI API:
        </Typography>
        <Box sx={{ pl: 2, mt: 2 }}>
          <Typography variant="body1" sx={{ color: colors.text }}>
            1. <strong>Register</strong> a new user account via <code>POST /register</code>.<br />
            2. <strong>Login</strong> using <code>POST /login</code> to obtain a JWT.<br />
            3. <strong>Upload & Classify</strong> images using <code>POST /images/classify</code>.<br />
            4. <strong>Save</strong> classified images to your library with <code>POST /images/save</code>.<br />
            5. <strong>Search</strong> your image library using <code>POST /images/search</code>.<br />
            6. <strong>Generate</strong> new images or manipulate existing ones using endpoints like <code>POST /remove-background</code> or <code>POST /generate-image</code>.<br />
            7. <strong>Manage Custom Models</strong> by creating, updating, and deleting models using the Custom Models endpoints.<br />
            8. <strong>Use Design Copilot</strong> via <code>POST /design-copilot/chat</code> for advanced image processing tasks.<br />
            9. <strong>Retrieve Inference Logs</strong> with <code>GET /models/:model_id/inferences</code> to monitor predictions.<br />
            10. <strong>Handle Errors</strong> appropriately based on the error responses.
          </Typography>
        </Box>
      </Box>

      {/* Footer with Last Updated */}
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "italic",
            color: colors.text,
            opacity: 0.8,
          }}
        >
          Documentation last updated: {new Date().toLocaleDateString()}
        </Typography>
      </Box>
    </Container>
  );
};

export default APIDocumentation;
