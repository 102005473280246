import React from 'react';
import { Wand2, ImagePlus, Palette, Crop, FileImage, Replace, Eraser } from 'lucide-react';
import { styled } from '@mui/material/styles';

// Styled components
const PanelContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '8px'
});

const Title = styled('div')({
  fontWeight: 600,
  color: '#1a1a1a',
  padding: '0 4px',
  fontSize: '14px'
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  overflowY: 'auto',
  paddingRight: '4px'
});

const ActionButton = styled('button')(({ gradient }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '8px',
  background: gradient,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease-out',
  cursor: 'pointer',
  
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    '& .shine': {
      transform: 'translateX(100%)',
    },
    '& .overlay': {
      opacity: 1,
    }
  },
  
  '&:active': {
    transform: 'scale(0.98)',
  }
}));

const ButtonContent = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px'
});

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '6px',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  '& svg': {
    width: '16px',
    height: '16px',
    color: '#fff'
  }
});

const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
});

const ButtonTitle = styled('span')({
  fontWeight: 500,
  fontSize: '12px',
  color: '#fff'
});

const ButtonSubtitle = styled('span')({
  fontSize: '11px',
  color: 'rgba(255, 255, 255, 0.8)'
});

const ShineEffect = styled('div')({
  position: 'absolute',
  top: '-100%',
  bottom: '-100%',
  left: '-100%',
  right: '-100%',
  background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent)',
  transform: 'translateX(-100%)',
  transition: 'transform 1s ease-out',
  opacity: 0
});

const Overlay = styled('div')({
  position: 'absolute',
  inset: 0,
  background: 'linear-gradient(rgba(255, 255, 255, 0.05), transparent)',
  opacity: 0,
  transition: 'opacity 0.3s'
});

const QuickActionsPanel = ({ onActionClick }) => {
  const actions = [
    {
      icon: <Wand2 />,
      title: "Enhance Images",
      subtitle: "Improve quality with AI",
      gradient: 'linear-gradient(135deg, #4F46E5, #9333EA, #EC4899)',
      placeholder: "Please enhance these images to improve their quality, adjusting brightness, contrast, and sharpness as needed."
    },
    {
      icon: <ImagePlus />,
      title: "Social Media Variants",
      subtitle: "Create platform-specific sizes",
      gradient: 'linear-gradient(135deg, #06B6D4, #3B82F6, #9333EA)',
      placeholder: "Generate social media variants optimized for Instagram, Facebook, Twitter, and LinkedIn while maintaining quality and composition."
    },
    {
      icon: <Palette />,
      title: "Color Palette",
      subtitle: "Extract color schemes",
      gradient: 'linear-gradient(135deg, #10B981, #059669, #0D9488)',
      placeholder: "Generate a comprehensive color palette analysis including dominant, accent, and complementary colors."
    },
    {
      icon: <Crop />,
      title: "Bulk Resize",
      subtitle: "Resize multiple images",
      gradient: 'linear-gradient(135deg, #F59E0B, #F97316, #EF4444)',
      placeholder: "Resize these images while maintaining aspect ratio and quality. Please optimize them for web use."
    },
    {
      icon: <FileImage />,
      title: "Convert Format",
      subtitle: "Change image formats",
      gradient: 'linear-gradient(135deg, #F43F5E, #EC4899, #9333EA)',
      placeholder: "Convert these images to WebP format while maintaining quality. Please optimize them for web use."
    },
    {
      icon: <Eraser />,
      title: "Remove Background",
      subtitle: "Create transparent versions",
      gradient: 'linear-gradient(135deg, #8B5CF6, #9333EA, #D946EF)',
      placeholder: "Remove the background from these images creating clean, transparent versions."
    },
    {
      icon: <Replace />,
      title: "Replace Background",
      subtitle: "Add new backgrounds",
      gradient: 'linear-gradient(135deg, #2563EB, #4F46E5, #7C3AED)',
      placeholder: "Replace the background of these images with a clean, minimal studio background."
    }
  ];

  return (
    <PanelContainer>
      <Title>Quick Actions</Title>
      <ButtonsContainer>
        {actions.map((action, index) => (
          <ActionButton
            key={index}
            gradient={action.gradient}
            onClick={() => onActionClick(action)}
          >
            <ButtonContent>
              <IconContainer>
                {action.icon}
              </IconContainer>
              <TextContainer>
                <ButtonTitle>{action.title}</ButtonTitle>
                <ButtonSubtitle>{action.subtitle}</ButtonSubtitle>
              </TextContainer>
            </ButtonContent>
            <ShineEffect className="shine" />
            <Overlay className="overlay" />
          </ActionButton>
        ))}
      </ButtonsContainer>
    </PanelContainer>
  );
};

export default QuickActionsPanel;