import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  Paper,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
  IconButton,
  Chip,
  Collapse,
  Menu,
  Fade,
  Zoom,
  Grow,
  Tooltip,
  Avatar,
  Badge,
  alpha,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import TuneIcon from "@mui/icons-material/Tune";
import MemoryIcon from "@mui/icons-material/Memory";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Folder, Move, Plus, ChevronLeft, ChevronDown, ChevronUp, Search, Zap, MessageSquare, Brain, Sparkles, Film } from "lucide-react";

import FolderBreadcrumb from "./FolderBreadcrumb";
import FolderTree from "./FolderTree";
import FolderActions from "./FolderActions";
import EnhancedVideoList from "./EnhancedVideoList"; // Assuming this component exists

// =============== STYLED COMPONENTS ===============

const AIContainer = styled(Container)(({ theme }) => ({
  marginTop: "2rem",
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.9)}, ${alpha(
    theme.palette.background.paper,
    0.95
  )})`,
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.07)",
  padding: theme.spacing(4),
  transition: "all 0.3s ease-in-out",
  overflow: "hidden",
}));

const AIHeader = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: "1rem",
  color: "#550FCC",
  textAlign: "center",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-8px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "60px",
    height: "4px",
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    borderRadius: "2px",
  },
}));

const AISearchBar = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    padding: "4px 16px",
    transition: "all 0.3s ease",
    background: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: "blur(8px)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
    "&:hover": {
      boxShadow: "0 6px 24px rgba(0, 0, 0, 0.1)",
    },
    "&.Mui-focused": {
      boxShadow: `0 8px 28px ${alpha(theme.palette.primary.main, 0.2)}`,
      "& fieldset": {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
  },
  "& .MuiInputBase-input": {
    padding: "14px 14px",
  },
}));

const AIButton = styled(Button)(({ theme }) => ({
  borderRadius: "12px",
  padding: "10px 24px",
  boxShadow: "0 4px 14px rgba(0, 0, 0, 0.12)",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  textTransform: "none",
  fontWeight: 600,
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)",
  },
  "&:active": {
    transform: "translateY(1px)",
  },
}));

const AIToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(8px)",
  borderRadius: "12px",
  padding: "4px",
  "& .MuiToggleButton-root": {
    borderRadius: "8px",
    margin: "0 4px",
    border: "none",
    fontWeight: 500,
    padding: "8px 16px",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const AIPaper = styled(Paper)(({ theme }) => ({
  borderRadius: "16px",
  padding: theme.spacing(3),
  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.08)",
  background: `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.95)}, ${alpha(
    theme.palette.background.paper,
    0.85
  )})`,
  backdropFilter: "blur(10px)",
  transition: "all 0.3s ease",
  border: `1px solid ${alpha(theme.palette.divider, 0.05)}`,
  overflow: "hidden",
  "&:hover": {
    boxShadow: "0 12px 28px rgba(0, 0, 0, 0.12)",
  },
}));

const AnimatedSearchIcon = styled("div")(({ theme }) => ({
  width: "24px",
  height: "24px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": {
    position: "absolute",
    transition: "all 0.5s ease",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.2)",
      opacity: 0.7,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
  "&.searching svg": {
    animation: "pulse 1.5s infinite",
  },
}));

const SuggestionsList = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  zIndex: 1000,
  borderRadius: "12px",
  marginTop: "8px",
  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  animation: "fadeIn 0.3s forwards",
  "@keyframes fadeIn": {
    from: { opacity: 0, transform: "translateY(-10px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  },
}));

const SuggestionItem = styled(ListItem)(({ theme }) => ({
  transition: "all 0.2s ease",
  borderLeft: "0px solid transparent",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
  },
}));

const AIFilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  borderRadius: "12px",
  fontWeight: 500,
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
  transition: "all 0.2s ease",
  "&:hover": {
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.12)",
    transform: "translateY(-2px)",
  },
  "&.active": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const AIFilterPanel = styled(Collapse)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: "12px",
  overflow: "hidden",
}));

const AIInfoCard = styled(Paper)(({ theme }) => ({
  width: "90%",
  maxWidth: "800px",
  margin: "4rem auto",
  padding: "2rem",
  borderRadius: "16px",
  position: "relative",
  textAlign: "center",
  background: `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.92)}, ${alpha(
    theme.palette.background.paper,
    0.85
  )})`,
  backdropFilter: "blur(10px)",
  boxShadow: "0 12px 36px rgba(0, 0, 0, 0.1)",
  border: `1px solid ${alpha(theme.palette.divider, 0.05)}`,
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "6px",
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  },
}));

const ActionBar = styled(Box)(({ theme }) => ({
  width: "fit-content",
  margin: "1rem auto",
  backdropFilter: "blur(10px)",
  background: alpha(theme.palette.background.paper, 0.95),
  padding: theme.spacing(1, 3),
  borderRadius: "50px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  transition: "all 0.3s ease",
}));

const AIBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "8px",
  right: "8px",
  padding: "4px 12px",
  borderRadius: "12px",
  fontSize: "12px",
  fontWeight: "bold",
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: "white",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
}));

const FolderCard = styled(Paper)(({ theme, isDragOver }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
  transition: "all 0.3s ease",
  border: isDragOver ? `2px dashed ${theme.palette.primary.main}` : `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  background: isDragOver 
    ? alpha(theme.palette.primary.main, 0.05)
    : `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.95)}, ${alpha(theme.palette.background.paper, 0.85)})`,
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.12)",
  },
}));

const AIAssistantPanel = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: theme.spacing(3),
  bottom: theme.spacing(3),
  zIndex: 1100,
}));

const AIAssistantButton = styled(IconButton)(({ theme }) => ({
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: theme.palette.primary.contrastText,
  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.25)",
  },
}));

const AIAssistantDialog = styled(Paper)(({ theme }) => ({
  position: "absolute",
  bottom: "70px",
  right: 0,
  width: "300px",
  borderRadius: "16px",
  padding: theme.spacing(2),
  background: theme.palette.background.paper,
  boxShadow: "0 10px 40px rgba(0, 0, 0, 0.2)",
  transition: "all 0.3s ease",
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  cursor: "pointer",
  "&:hover": {
    "& .MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
}));

const VideoCard = styled(Paper)(({ theme, isSelected }) => ({
  borderRadius: "12px",
  overflow: "hidden",
  position: "relative",
  transition: "all 0.3s ease",
  border: isSelected ? `2px solid ${theme.palette.primary.main}` : "none",
  boxShadow: isSelected 
    ? `0 8px 28px ${alpha(theme.palette.primary.main, 0.3)}`
    : "0 4px 12px rgba(0, 0, 0, 0.08)",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 12px 28px rgba(0, 0, 0, 0.15)",
  },
}));

const VideoDuration = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "8px",
  right: "8px",
  backgroundColor: alpha("#000", 0.7),
  color: "#fff",
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "0.75rem",
  fontWeight: "bold",
}));

const VideoResolution = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "8px",
  right: "8px",
  backgroundColor: alpha("#000", 0.7),
  color: "#fff",
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "0.75rem",
  fontWeight: "bold",
}));

const PlayOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: alpha("#000", 0.3),
  opacity: 0,
  transition: "opacity 0.2s ease",
  "&:hover": {
    opacity: 1,
  },
}));

// =============== MAIN COMPONENT ===============

const SearchAndRetrieveVideos = () => {
  // Base state variables
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [sortOrder, setSortOrder] = useState("date");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  
  // Selection and UI states
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [targetUsername, setTargetUsername] = useState("");
  
  // Context menu states
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuVideo, setContextMenuVideo] = useState(null);

  // Filter states
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [classLabel, setClassLabel] = useState("");
  const [minDuration, setMinDuration] = useState("");
  const [maxDuration, setMaxDuration] = useState("");
  const [resolution, setResolution] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [topClasses, setTopClasses] = useState([]);
  
  // Folder states
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderLoading, setFolderLoading] = useState(false);
  const [folderError, setFolderError] = useState('');
  const [showFolderPanel, setShowFolderPanel] = useState(false);
  const [currentFolderContents, setCurrentFolderContents] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [dragOverFolder, setDragOverFolder] = useState(null);
  
  // Create folder states
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');

  // New AI UI states
  const [showFilters, setShowFilters] = useState(false);
  const [searchAnimation, setSearchAnimation] = useState(false);
  const [viewMode, setViewMode] = useState("grid");
  const [showAssistant, setShowAssistant] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search for videos...");
  const [typingEffect, setTypingEffect] = useState(false);
  const [showAIClasses, setShowAIClasses] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const pageSize = 12;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  // Helper function to get folder path names
  const getFolderPathNames = (folderId, foldersList) => {
    const path = [];
    let current = foldersList.find(f => f.id === folderId);
    while (current) {
      path.unshift({
        id: current.id,
        name: current.name
      });
      current = foldersList.find(f => f.id === current.parent_folder_id);
    }
    return path;
  };

  // Fetch data functions
  const fetchTopClasses = async () => {
    try {
      const response = await api.get('/videos/classes/top');  // Use video-specific endpoint
      setTopClasses(response.data.top_classes);
    } catch (error) {
      console.error('Error fetching top classes:', error);
    }
  };

  const fetchFolders = async () => {
    try {
      setFolderLoading(true);
      setFolderError('');
  
      const response = await api.get('/folders', {
        params: {
          recursive: true,
          type: 'video'  // Specify that we want video folders
        }
      });
  
      if (response.data && response.data.folders) {
        console.log('Fetched folders:', response.data.folders);
        setFolders(response.data.folders);
      } else {
        setFolderError('Failed to load folders: Invalid data format');
      }
    } catch (error) {
      setFolderError('Failed to load folders: ' + (error.response?.data?.detail || error.message));
      console.error('Error fetching folders:', error);
    } finally {
      setFolderLoading(false);
    }
  };
  

  const fetchAutocomplete = async (queryText) => {
    if (queryText.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      setTypingEffect(true);
      const response = await api.get(`/autocomplete`, { 
        params: { 
          query: queryText,
          type: 'video'
        } 
      });
      setSuggestions(response.data.suggestions);
    } catch (error) {
      console.error("Error fetching autocomplete:", error);
    } finally {
      setTypingEffect(false);
    }
  };

  // Initialize data
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await fetchFolders();
        await fetchTopClasses();
        await searchVideos(false, true);
      } catch (error) {
        console.error('Error loading initial data:', error);
      }
    };

    loadInitialData();
  }, []);

  useEffect(() => {
    if (query.length > 2) {
      const debounce = setTimeout(() => fetchAutocomplete(query), 300);
      return () => clearTimeout(debounce);
    }
  }, [query]);

  // Cycling placeholder text for the search bar
  useEffect(() => {
    const placeholders = [
      "Search for videos...",
      "Try 'product demonstration' or 'tutorial'",
      "Search by content, duration, or quality...",
      "Find videos by description or subject",
    ];
    
    const interval = setInterval(() => {
      const currentIndex = placeholders.indexOf(searchPlaceholder);
      const nextIndex = (currentIndex + 1) % placeholders.length;
      setSearchPlaceholder(placeholders[nextIndex]);
    }, 5000);
    
    return () => clearInterval(interval);
  }, [searchPlaceholder]);

  // Handle folder selection
  const handleFolderSelect = async (folder) => {
    try {
      setLoading(true);
      setSearchAnimation(true);
      console.log('Selecting folder:', folder);
  
      setCurrentFolder(folder);
  
      // First get the latest folders
      await fetchFolders();
  
      // Then fetch contents of the selected folder
      const response = await api.post('/videos/search', {}, {
        params: {
          folder_id: folder?.id || null,
          recursive: false,
          page_size: pageSize,
          page_number: 1
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (folder) {
        setCurrentFolderContents(response.data.results);
        setResults([]);
  
        // Expand the selected folder in the tree
        setExpandedFolders(prev => ({
          ...prev,
          [folder.id]: true
        }));
      } else {
        setCurrentFolderContents([]);
        setResults(response.data.results);
      }
  
      setHasMore(response.data.results.length === pageSize);
      setPageNumber(1);
      setError('');
    } catch (error) {
      console.error('Failed to load folder contents:', error);
      setError('Failed to load folder contents');
    } finally {
      setTimeout(() => {
        setLoading(false);
        setSearchAnimation(false);
      }, 700);
    }
  };

  // Handle sort order change
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
    setResults([]);
    setPageNumber(1);
    setHasMore(true);
    searchVideos(false, !isSearching);
  };

  // Handle video selection
  const handleSelectVideo = (videoUrl) => {
    if (selectedVideos.includes(videoUrl)) {
      setSelectedVideos(selectedVideos.filter((url) => url !== videoUrl));
    } else {
      setSelectedVideos([...selectedVideos, videoUrl]);
    }
  };

  // Handle video click (for preview)
  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
  };

  // Context menu handlers
  const handleContextMenu = (event, videoUrl) => {
    event.preventDefault();
    setContextMenuVideo(videoUrl);
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    setContextMenuVideo(null);
  };

  // Video operations
  const handleDeleteVideos = async () => {
    if (!selectedVideos.length) {
      setError("Please select at least one video to delete.");
      return;
    }

    try {
      setDeleting(true);
      setError("");

      await api.delete("/videos", {
        data: { video_urls: selectedVideos }
      });

      if (currentFolder) {
        setCurrentFolderContents((prevResults) =>
          prevResults.filter((result) => !selectedVideos.includes(result.video_url))
        );
      } else {
        setResults((prevResults) =>
          prevResults.filter((result) => !selectedVideos.includes(result.video_url))
        );
      }

      setSelectedVideos([]);
      setSuccess("Videos deleted successfully");
    } catch (error) {
      setError("Error deleting videos: " + (error.response?.data?.detail || error.message));
    } finally {
      setDeleting(false);
    }
  };

  const handleDownloadVideos = async () => {
    if (!selectedVideos.length) {
      setError("Please select at least one video to download.");
      return;
    }

    try {
      setLoading(true);
      const response = await api.post("/videos/download-zip", 
        { video_urls: selectedVideos },
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "selected_videos.zip";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setError("Error downloading videos");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenShareDialog = () => {
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
    setTargetUsername("");
    setError("");
  };

  const handleShareVideos = async () => {
    if (!targetUsername) {
      setError("Please enter a username to share with");
      return;
    }

    try {
      setLoading(true);
      await api.post("/videos/share", {
        video_urls: selectedVideos,
        target_username: targetUsername
      });

      setSuccess("Videos shared successfully");
      handleCloseShareDialog();
    } catch (error) {
      setError("Error sharing videos: " + (error.response?.data?.detail || error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleMoveVideos = async (targetFolderId, videosToMove = selectedVideos) => {
    try {
      setLoading(true);
      
      await api.post('/videos/move', {
        video_urls: videosToMove,
        folder_id: targetFolderId
      });
  
      // Refresh current view
      if (currentFolder) {
        handleFolderSelect(currentFolder);
      } else {
        searchVideos(false, true);
      }
  
      // Clear selection if we moved the currently selected videos
      if (videosToMove === selectedVideos) {
        setSelectedVideos([]);
      }
  
      setMoveDialogOpen(false);
      setSuccess('Videos moved successfully');
    } catch (error) {
      setError('Failed to move videos: ' + (error.response?.data?.detail || error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleMoveVideo = async (targetFolderId) => {
    if (!contextMenuVideo) return;
    await handleMoveVideos(targetFolderId, [contextMenuVideo]);
    handleCloseContextMenu();
  };

  const handleFolderDelete = async (folderId) => {
    try {
      await api.delete(`/folders/${folderId}`);
      await fetchFolders();
      
      if (currentFolder?.id === folderId) {
        handleFolderSelect(null);
      }
    } catch (error) {
      setError('Failed to delete folder');
      console.error('Delete Folder Error:', error);
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;
  
    try {
      setError('');
      console.log('Creating folder:', {
        name: newFolderName,
        parent_folder_id: currentFolder?.id || null
      });
  
      const response = await api.post('/folders', {
        name: newFolderName,
        parent_folder_id: currentFolder?.id || null,
        description: "",
        tags: [],
        folder_type: "video"  // Specify that this is a video folder
      });
  
      console.log('Created folder:', response.data);
  
      // Clear input and close dialog
      setNewFolderName('');
      setCreateDialogOpen(false);
  
      // Refresh folders
      await fetchFolders();
  
      // If we're in a folder, expand and re-select it
      if (currentFolder) {
        setExpandedFolders(prev => ({
          ...prev,
          [currentFolder.id]: true
        }));
  
        await handleFolderSelect(currentFolder);
      }
  
    } catch (error) {
      console.error('Create Folder Error:', error);
      setError(error.response?.data?.detail || 'Failed to create folder');
    }
  };

  // Handle class selection
  const handleClassClick = (newClassLabel) => {
    setClassLabel(newClassLabel);
    setQuery("");
    setResults([]);
    setPageNumber(1);
    setIsSearching(true);
    
    // Immediately trigger search on class selection
    setTimeout(() => {
      searchVideos(false);
    }, 0);
  };
  
  // Search videos
  const searchVideos = async (loadMore = false, isInitialLoad = false) => {
    if (!loadMore) {
      setLoading(true);
      setSearchAnimation(true);
      
      if (!isInitialLoad && !query && !classLabel && !currentFolder) {
        setError("Please enter a search query, select a class, or choose a folder");
        setLoading(false);
        setSearchAnimation(false);
        return;
      }
      setResults([]);
      setPageNumber(1);
      setHasMore(true);
    }

    setError("");

    try {
      const params = {
        page_size: pageSize,
        page_number: loadMore ? pageNumber : 1
      };

      // This is the key part - copying the approach from the working version
      const searchBody = {
        query_text: query || undefined,
        class_label_filter: classLabel || undefined,
        start_date: startDate?.toISOString().split('T')[0],
        end_date: endDate?.toISOString().split('T')[0],
        min_duration: minDuration ? Number(minDuration) : undefined,
        max_duration: maxDuration ? Number(maxDuration) : undefined,
        resolution_filter: resolution || undefined,
        folder_id: currentFolder?.id
      };

      // Remove undefined values
      Object.keys(searchBody).forEach(key => {
        if (searchBody[key] === undefined) {
          delete searchBody[key];
        }
      });

      const response = await api.post('/videos/search', searchBody, {
        params: params,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      
      let fetchedResults = [...data.results];
      if (!query && sortOrder === 'date') {
        fetchedResults.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }
      
      if (loadMore) {
        setResults(prev => [...prev, ...fetchedResults]);
      } else {
        if (currentFolder) {
          setCurrentFolderContents(fetchedResults);
        } else {
          setResults(fetchedResults);
        }
      }

      setHasMore(data.results.length === pageSize);
      setPageNumber(prev => prev + 1);
      setError('');
    } catch (error) {
      setError(error.response?.data?.detail || 'Error searching videos');
      console.error('Search Videos Error:', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setSearchAnimation(false);
      }, 800);
    }
  };

  // Filter folders to show only those in the current folder
  const displayFolders = useMemo(() => {
    return folders.filter(folder => {
      const isInCurrentFolder = folder.parent_folder_id === (currentFolder?.id || null);
      const isNotDeleted = !folder.is_deleted;
      return isInCurrentFolder && isNotDeleted;
    });
  }, [folders, currentFolder?.id]);

  // Simulated AI recommendations - in a real implementation, these would come from the backend
  const getAIRecommendations = () => {
    return [
      "I notice you often search for tutorial videos",
      "Would you like to create a smart collection for similar videos?",
      "Try refining your search with 'high resolution' for better quality content",
      "Would you like to organize these videos by duration or theme?"
    ];
  };

  // Video Grid Component (fallback if EnhancedVideoList doesn't exist)
  const VideoGrid = ({ videos, selectedVideos, onVideoSelect, onVideoClick, onContextMenu }) => (
    <Grid container spacing={2}>
      {videos.map((video) => (
        <Grid item xs={12} sm={6} md={4} key={video.video_url}>
          <VideoCard
            isSelected={selectedVideos.includes(video.video_url)}
            onClick={() => onVideoClick(video.video_url)}
            onContextMenu={(e) => onContextMenu(e, video.video_url)}
          >
            <Box sx={{ position: 'relative' }}>
              <img
                src={video.thumbnail_url}
                alt={video.class_label || "Video thumbnail"}
                style={{ width: '100%', aspectRatio: '16/9', objectFit: 'cover' }}
              />
              <VideoDuration>
                {Math.floor(video.duration / 60)}:{String(Math.floor(video.duration % 60)).padStart(2, '0')}
              </VideoDuration>
              {video.resolution && (
                <VideoResolution>
                  {video.resolution[0]}x{video.resolution[1]}
                </VideoResolution>
              )}
              <PlayOverlay>
                <IconButton
                  sx={{
                    backgroundColor: alpha(theme.palette.primary.main, 0.8),
                    color: 'white',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  <PlayArrowIcon fontSize="large" />
                </IconButton>
              </PlayOverlay>
              <Checkbox
                checked={selectedVideos.includes(video.video_url)}
                onChange={(e) => {
                  e.stopPropagation();
                  onVideoSelect(video.video_url);
                }}
                onClick={(e) => e.stopPropagation()}
                sx={{ position: 'absolute', top: 0, left: 0 }}
              />
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                {video.class_label || "Untitled Video"}
              </Typography>
              {video.folder_name && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Folder size={16} />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {video.folder_name}
                  </Typography>
                </Box>
              )}
            </Box>
          </VideoCard>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <AIContainer maxWidth="lg">
      <AIHeader variant="h4">
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px" }}>
          <AutoAwesomeIcon sx={{ fontSize: 28 }} />
          AI-Powered Video Search
          <AutoAwesomeIcon sx={{ fontSize: 28 }} />
        </Box>
      </AIHeader>

      <Grid container spacing={3}>
        {/* Left Sidebar - Filters and Controls */}
        <Grid item xs={12} sm={4} md={3}>
          <AIPaper>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: "600", display: "flex", alignItems: "center", gap: 1 }}>
                <TuneIcon /> Filters
              </Typography>
              <IconButton 
                size="small" 
                onClick={() => setShowFilters(!showFilters)}
                sx={{ transform: showFilters ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.3s ease" }}
              >
                <ChevronLeft />
              </IconButton>
            </Box>

            <Collapse in={showFilters}>
              <Box sx={{ mb: 3 }}>
                {currentFolder ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <Chip
                      icon={<Folder size={20} />}
                      label={currentFolder.name}
                      onDelete={() => {
                        handleFolderSelect(null);
                      }}
                      sx={{ 
                        padding: "8px 4px", 
                        borderRadius: "12px", 
                        fontWeight: "medium",
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)}, ${alpha(theme.palette.primary.main, 0.05)})`,
                      }}
                    />
                    {currentFolder?.parent_folder_id && (
                      <Button
                        size="small"
                        startIcon={<ChevronLeft size={16} />}
                        variant="outlined"
                        onClick={() => {
                          const parentFolder = folders.find(f => f.id === currentFolder.parent_folder_id);
                          if (parentFolder) {
                            handleFolderSelect(parentFolder);
                          } else {
                            handleFolderSelect(null); // Go to root if parent not found
                          }
                        }}
                        sx={{ borderRadius: "12px" }}
                      >
                        Up
                      </Button>
                    )}
                  </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    All Locations
                  </Typography>
                )}

                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel id="sort-order-label">Sort By</InputLabel>
                  <Select
                    labelId="sort-order-label"
                    id="sort-order"
                    value={sortOrder}
                    label="Sort By"
                    onChange={handleSortOrderChange}
                    sx={{ borderRadius: "12px" }}
                  >
                    <MenuItem value="date">Date</MenuItem>
                    <MenuItem value="similarity">Similarity</MenuItem>
                    <MenuItem value="duration">Duration</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  label="Class Label"
                  variant="outlined"
                  value={classLabel}
                  onChange={(e) => setClassLabel(e.target.value)}
                  sx={{ mb: 3, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        fullWidth 
                        sx={{ mb: 3, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }} 
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        fullWidth 
                        sx={{ mb: 3, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }} 
                      />
                    )}
                  />
                </LocalizationProvider>

                <TextField
                  fullWidth
                  label="Min Duration (seconds)"
                  variant="outlined"
                  type="number"
                  value={minDuration}
                  onChange={(e) => setMinDuration(e.target.value)}
                  sx={{ mb: 3, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
                />

                <TextField
                  fullWidth
                  label="Max Duration (seconds)"
                  variant="outlined"
                  type="number"
                  value={maxDuration}
                  onChange={(e) => setMaxDuration(e.target.value)}
                  sx={{ mb: 3, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
                />

                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel>Resolution</InputLabel>
                  <Select
                    value={resolution}
                    label="Resolution"
                    onChange={(e) => setResolution(e.target.value)}
                    sx={{ borderRadius: "12px" }}
                  >
                    <MenuItem value="">Any</MenuItem>
                    <MenuItem value="HD">HD (720p)</MenuItem>
                    <MenuItem value="FullHD">Full HD (1080p)</MenuItem>
                    <MenuItem value="4K">4K</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Collapse>

            {/* AI Suggested Filters */}
            <Box sx={{ mt: 2, mb: 3 }}>
              <Typography variant="subtitle2" sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}>
                <Sparkles size={16} /> AI Suggested Filters
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                <AIFilterChip 
                  label="Tutorials" 
                  onClick={() => {
                    setQuery("tutorial");
                    searchVideos(false);
                  }}
                  avatar={<Avatar sx={{ bgcolor: theme.palette.primary.main }}>T</Avatar>}
                />
                <AIFilterChip 
                  label="Demonstrations" 
                  onClick={() => {
                    setQuery("demonstration");
                    searchVideos(false);
                  }}
                  avatar={<Avatar sx={{ bgcolor: theme.palette.secondary.main }}>D</Avatar>}
                />
                <AIFilterChip 
                  label="Short Videos" 
                  onClick={() => {
                    setMinDuration("");
                    setMaxDuration("60");
                    searchVideos(false);
                  }}
                  avatar={<Avatar sx={{ bgcolor: theme.palette.error.main }}>S</Avatar>}
                />
              </Box>
            </Box>

            <Box sx={{ mt: 4, mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}>
                <Folder size={16} /> Folder Navigation
              </Typography>
              
              <Button
                startIcon={<Folder size={20} />}
                onClick={() => setShowFolderPanel(!showFolderPanel)}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ 
                  borderRadius: "12px", 
                  mb: 1, 
                  justifyContent: "flex-start",
                  transition: "all 0.2s ease",
                  borderColor: showFolderPanel ? theme.palette.primary.main : undefined,
                  backgroundColor: showFolderPanel ? alpha(theme.palette.primary.main, 0.05) : undefined,
                }}
              >
                {showFolderPanel ? 'Hide Folders' : 'Show Folders'}
              </Button>
            </Box>

            <Collapse in={showFolderPanel}>
              <Box sx={{ maxHeight: "400px", overflowY: "auto", pr: 1 }}>
                {folderError && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {folderError}
                  </Alert>
                )}

                {folderLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    {currentFolder && (
                      <Box sx={{ mb: 2 }}>
                        <FolderBreadcrumb
                          path={[
                            { id: 'root', name: 'Root' },
                            ...getFolderPathNames(currentFolder.id, folders)
                          ]}
                          onNavigate={(folder) => {
                            handleFolderSelect(folder);
                          }}
                        />
                      </Box>
                    )}

                    <FolderTree
                      folders={folders}
                      selectedFolder={currentFolder}
                      onFolderSelect={handleFolderSelect}
                      onDropInFolder={async (type, item, targetFolder) => {
                        try {
                          if (type === 'folder') {
                            await api.post(`/folders/move`, {
                              folder_id: item.id,
                              target_folder_id: targetFolder.id
                            });
                          } else if (item && item.video_url) {
                            await handleMoveVideos(targetFolder.id, [item.video_url]);
                          }
                          fetchFolders();
                          if (currentFolder) {
                            handleFolderSelect(currentFolder);
                          } else {
                            searchVideos();
                          }
                        } catch (error) {
                          setError('Failed to move item');
                          console.error("Move Item Error:", error);
                        }
                      }}
                      sharedFolders={[]}
                      handleFolderDelete={handleFolderDelete}
                    />
                  </>
                )}
              </Box>
            </Collapse>
          </AIPaper>

          {/* AI Insights Panel */}
          <AIPaper sx={{ mt: 3, position: "relative" }}>
            <AIBadge>AI-Powered</AIBadge>
            <Typography variant="h6" sx={{ fontWeight: "600", display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
              <Brain size={20} /> AI Insights
            </Typography>
            
            <List dense>
              {getAIRecommendations().map((recommendation, index) => (
                <ListItem key={index} sx={{ pl: 0, mb: 1 }}>
                  <ListItemText 
                    primary={recommendation} 
                    primaryTypographyProps={{ 
                      fontSize: "0.9rem",
                      sx: { 
                        borderLeft: `3px solid ${theme.palette.primary.main}`,
                        pl: 1,
                        py: 0.5
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </AIPaper>
        </Grid>

        {/* Main Content Area */}
        <Grid item xs={12} sm={8} md={9}>
          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 3, 
                borderRadius: "12px",
                animation: "fadeInDown 0.5s forwards",
                "@keyframes fadeInDown": {
                  from: { opacity: 0, transform: "translateY(-10px)" },
                  to: { opacity: 1, transform: "translateY(0)" }
                }
              }}
            >
              {error}
            </Alert>
          )}

          {success && (
            <Alert 
              severity="success" 
              onClose={() => setSuccess('')}
              sx={{ 
                mb: 3, 
                borderRadius: "12px",
                animation: "fadeInDown 0.5s forwards"
              }}
            >
              {success}
            </Alert>
          )}

          {/* Search Controls */}
          <AIPaper>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ position: "relative" }}>
                <AISearchBar
                  fullWidth
                  placeholder={searchPlaceholder}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  variant="outlined"
                  inputRef={searchInputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AnimatedSearchIcon className={searchAnimation ? "searching" : ""}>
                          {searchAnimation ? (
                            <MemoryIcon color="primary" />
                          ) : (
                            <Search size={20} color={theme.palette.text.secondary} />
                          )}
                        </AnimatedSearchIcon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Voice Search">
                          <IconButton edge="end" sx={{ mr: 0.5 }}>
                            <KeyboardVoiceIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />

                {suggestions.length > 0 && (
                  <Zoom in={suggestions.length > 0}>
                    <SuggestionsList>
                      <List>
                        {suggestions.map((suggestion, index) => (
                          <SuggestionItem
                            button
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            <ListItemText 
                              primary={suggestion} 
                              primaryTypographyProps={{ fontSize: "0.95rem" }}
                            />
                          </SuggestionItem>
                        ))}
                      </List>
                    </SuggestionsList>
                  </Zoom>
                )}
              </Box>

              <Box sx={{ display: "flex", gap: 2, mt: 3, flexWrap: "wrap" }}>
                <AIButton
                  variant="contained"
                  color="primary"
                  onClick={() => searchVideos(false, false)}
                  disabled={loading}
                  sx={{ flex: "1 0 auto", minWidth: "120px" }}
                >
                  {loading ? (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CircularProgress size={20} color="inherit" />
                      <span>Analyzing...</span>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Zap size={18} />
                      <span>Search with AI</span>
                    </Box>
                  )}
                </AIButton>

                <AIButton
                  variant="outlined"
                  onClick={() => setShowFilters(!showFilters)}
                  startIcon={<TuneIcon />}
                  sx={{ flex: "0 0 auto" }}
                >
                  Filters
                </AIButton>

                <AIButton
                  variant="outlined"
                  onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
                  startIcon={viewMode === "grid" ? <VideoSettingsIcon /> : <ViewModuleIcon />}
                  sx={{ flex: "0 0 auto" }}
                >
                  {viewMode === "grid" ? "List View" : "Grid View"}
                </AIButton>
                
                {(query || classLabel || startDate || endDate || minDuration || maxDuration || resolution) && (
                  <AIButton
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setQuery("");
                      setClassLabel("");
                      setStartDate(null);
                      setEndDate(null);
                      setMinDuration("");
                      setMaxDuration("");
                      setResolution("");
                      searchVideos(false, true);
                    }}
                    startIcon={<ClearIcon />}
                    sx={{ flex: "0 0 auto" }}
                  >
                    Clear All Filters
                  </AIButton>
                )}
              </Box>
            </Box>
          </AIPaper>

          {/* Top Classes - Collapsible with default collapsed */}
          {topClasses.length > 0 && (
            <AIPaper sx={{ mt: 3, mb: 3 }}>
              <SectionHeader onClick={() => setShowAIClasses(!showAIClasses)}>
                <Typography variant="h6" sx={{ fontWeight: "600", display: "flex", alignItems: "center", gap: 1 }}>
                  <AnalyticsIcon fontSize="small" /> AI-Detected Categories
                </Typography>
                <IconButton size="small">
                  {showAIClasses ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </IconButton>
              </SectionHeader>
              
              <Collapse in={showAIClasses}>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {topClasses.map((classItem, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.05 }}
                    >
                      <AIFilterChip
                        label={`${classItem.class_label} (${classItem.count})`}
                        onClick={() => handleClassClick(classItem.class_label)}
                        onDelete={classLabel === classItem.class_label ? () => {
                          setClassLabel("");
                          setTimeout(() => {
                            searchVideos(false, true);
                          }, 0);
                        } : undefined}
                        className={classLabel === classItem.class_label ? "active" : ""}
                        avatar={
                          <Avatar 
                            sx={{ 
                              bgcolor: `hsl(${(index * 40) % 360}, 70%, 50%)`,
                              width: 24,
                              height: 24,
                              fontSize: "0.8rem"
                            }}
                          >
                            {classItem.class_label.charAt(0).toUpperCase()}
                          </Avatar>
                        }
                      />
                    </motion.div>
                  ))}
                </Box>
              </Collapse>
            </AIPaper>
          )}

          {/* Folders Section - Collapsible Section */}
          <AIPaper sx={{ mt: 3, mb: 4 }}>
            <SectionHeader onClick={() => setShowFolderPanel(!showFolderPanel)}>
              <Typography variant="h6" sx={{ fontWeight: "600", display: "flex", alignItems: "center", gap: 1 }}>
                <Folder size={20} /> Folders
              </Typography>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <AIButton
                  startIcon={<Plus size={16} />}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the collapse toggle
                    setCreateDialogOpen(true);
                  }}
                  variant="outlined"
                  size="small"
                >
                  NEW FOLDER
                </AIButton>
                <IconButton size="small" onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering twice
                  setShowFolderPanel(!showFolderPanel);
                }}>
                  {showFolderPanel ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </IconButton>
              </Box>
            </SectionHeader>
            
            <Collapse in={showFolderPanel}>
              {currentFolder && (
                <Box sx={{ mb: 2 }}>
                  <Chip
                    icon={<Folder size={20} />}
                    label={currentFolder.name}
                    onDelete={() => {
                      handleFolderSelect(null);
                    }}
                    sx={{ 
                      padding: "8px 4px", 
                      borderRadius: "12px", 
                      fontWeight: "medium",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                      background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)}, ${alpha(theme.palette.primary.main, 0.05)})`,
                    }}
                  />
                </Box>
              )}

              <Grid container spacing={2}>
                {displayFolders.length > 0 ? (
                  displayFolders.map((folder, index) => (
                    <Grid item key={folder.id} xs={12} sm={6} md={4}>
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.05 }}
                      >
                        <FolderCard
                          isDragOver={dragOverFolder === folder.id}
                          onClick={() => handleFolderSelect(folder)}
                          onDragOver={(e) => {
                            e.preventDefault();
                            setDragOverFolder(folder.id);
                          }}
                          onDragLeave={() => setDragOverFolder(null)}
                          onDrop={(e) => {
                            e.preventDefault();
                            setDragOverFolder(null);
                            // Handle drop logic here
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center", gap: 2, flex: 1 }}>
                            <Folder size={24} color={theme.palette.primary.main} />
                            <Typography noWrap sx={{ flex: 1, fontWeight: "medium" }}>
                              {folder.name}
                            </Typography>
                          </Box>
                          <Box>
                            <FolderActions
                              folder={folder}
                              onFolderUpdate={fetchFolders}
                              onFolderDelete={handleFolderDelete}
                            />
                          </Box>
                        </FolderCard>
                      </motion.div>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography color="text.secondary" textAlign="center" sx={{ py: 2 }}>
                      {currentFolder
                        ? `No folders found in "${currentFolder.name}"`
                        : "No folders found in root"}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Collapse>
          </AIPaper>

          {/* Videos Section - Redesigned */}
          <AIPaper sx={{ mt: 3, pb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "600", mb: 3, display: "flex", alignItems: "center", gap: 1 }}>
              <Film size={20} /> Videos
            </Typography>
            
            {loading ? (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", py: 6 }}>
                <CircularProgress size={40} />
                <Typography sx={{ mt: 2, color: "text.secondary" }}>
                  AI is analyzing your request...
                </Typography>
              </Box>
            ) : (currentFolder ? currentFolderContents : results).length > 0 ? (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  {/* Use EnhancedVideoList if it exists, otherwise fallback to VideoGrid */}
                  {typeof EnhancedVideoList !== 'undefined' ? (
                    <EnhancedVideoList
                      items={currentFolder ? currentFolderContents : results}
                      onVideoClick={handleVideoClick}
                      onVideoSelect={handleSelectVideo}
                      selectedVideos={selectedVideos}
                      isSmallScreen={isSmallScreen}
                      onContextMenu={handleContextMenu}
                      viewMode={viewMode}
                    />
                  ) : (
                    <VideoGrid
                      videos={currentFolder ? currentFolderContents : results}
                      selectedVideos={selectedVideos}
                      onVideoSelect={handleSelectVideo}
                      onVideoClick={handleVideoClick}
                      onContextMenu={handleContextMenu}
                    />
                  )}
                </motion.div>
              </AnimatePresence>
            ) : (
              <Box sx={{ textAlign: "center", py: 6 }}>
                <Typography variant="body1" color="text.secondary">
                  No videos found in {currentFolder ? `folder "${currentFolder.name}"` : "this location"}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Try adjusting your search criteria or browse another folder
                </Typography>
              </Box>
            )}

            {/* Place the action bar within the videos paper after the results */}
            {selectedVideos.length > 0 && (
              <Box sx={{ width: '100%', mt: 4, mb: 2 }}>
                <ActionBar>
                  <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                    {selectedVideos.length} selected
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <AIButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={handleDownloadVideos}
                    >
                      Download
                    </AIButton>
                    <AIButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleOpenShareDialog}
                    >
                      Share
                    </AIButton>
                    <AIButton
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleDeleteVideos}
                      disabled={deleting}
                    >
                      {deleting ? <CircularProgress size={20} /> : "Delete"}
                    </AIButton>
                    <AIButton
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<Move size={16} />}
                      onClick={() => setMoveDialogOpen(true)}
                    >
                      Move
                    </AIButton>
                  </Box>
                </ActionBar>
              </Box>
            )}
            
            {/* Load More Button */}
            {hasMore && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                <AIButton
                  variant="outlined"
                  color="primary"
                  onClick={() => searchVideos(true, !isSearching)}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Load More Results"
                  )}
                </AIButton>
              </Box>
            )}
          </AIPaper>
        </Grid>
      </Grid>

      {/* AI Assistant Button */}
      <AIAssistantPanel>
        <AIAssistantButton
          onClick={() => setShowAssistant(!showAssistant)}
          size="large"
        >
          <Brain size={28} />
        </AIAssistantButton>
        
        {showAssistant && (
          <AIAssistantDialog>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
              Video AI Assistant
            </Typography>
            <Typography variant="body2" paragraph>
              I can help you find the perfect videos for your needs. Try asking me natural language questions.
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: "italic", color: "text.secondary" }}>
              Examples:
            </Typography>
            <Box component="ul" sx={{ pl: 2, mt: 1 }}>
              <Typography component="li" variant="body2" sx={{ mb: 0.5 }}>
                "Find tutorial videos under 5 minutes"
              </Typography>
              <Typography component="li" variant="body2" sx={{ mb: 0.5 }}>
                "Show me all 4K videos from last month"
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button size="small" onClick={() => setShowAssistant(false)}>
                Close
              </Button>
            </Box>
          </AIAssistantDialog>
        )}
      </AIAssistantPanel>

      {/* Explanatory Card */}
      {showExplanatoryCard && (
        <AIInfoCard>
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Avatar
              sx={{
                width: 70,
                height: 70,
                mb: 2,
                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              }}
            >
              <Film size={40} />
            </Avatar>
            
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              AI-Powered Video Search
            </Typography>
            
            <Typography variant="body1" color="textSecondary" sx={{ lineHeight: 1.6, mb: 3 }}>
              Our advanced AI system helps you find exactly what you're looking for using intelligent video analysis.
              Try using natural language queries, filter by duration or resolution, or let the AI suggest relevant categories.
            </Typography>
            
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "center" }}>
              <AIButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowExplanatoryCard(false);
                  searchInputRef.current?.focus();
                }}
              >
                Get Started
              </AIButton>
              <AIButton
                variant="text"
                onClick={() => setShowExplanatoryCard(false)}
              >
                Dismiss
              </AIButton>
            </Box>
          </Box>
        </AIInfoCard>
      )}

      {/* Video Preview Dialog */}
      <Dialog 
        open={Boolean(selectedVideo)} 
        onClose={() => setSelectedVideo(null)} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '16px',
            overflow: 'hidden'
          }
        }}
      >
        <DialogContent 
          sx={{ 
            position: "relative", 
            p: 0,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#000'
          }}
        >
          {selectedVideo && (
            <>
              <IconButton
                aria-label="close"
                onClick={() => setSelectedVideo(null)}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: alpha("#000", 0.4),
                  "&:hover": {
                    backgroundColor: alpha("#000", 0.6),
                  },
                  zIndex: 10
                }}
              >
                <CloseIcon />
              </IconButton>
              <video
                src={selectedVideo}
                controls
                autoPlay
                style={{ 
                  width: "100%",
                  maxHeight: "80vh"
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <AIButton
            variant="outlined"
            color="primary"
            href={selectedVideo}
            download
          >
            Download Video
          </AIButton>
          <AIButton onClick={() => setSelectedVideo(null)} color="primary">
            Close
          </AIButton>
        </DialogActions>
      </Dialog>

      {/* Share Dialog */}
      <Dialog open={shareDialogOpen} onClose={handleCloseShareDialog}>
        <DialogTitle>Share Selected Videos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the username of the user you want to share the selected videos with.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={targetUsername}
            onChange={(e) => setTargetUsername(e.target.value)}
            sx={{ mt: 2, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog} color="primary">
            Cancel
          </Button>
          <AIButton onClick={handleShareVideos} color="primary" variant="contained">
            Share
          </AIButton>
        </DialogActions>
      </Dialog>

      {/* Create Folder Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          setError('');
          setSuccess('');
          setNewFolderName('');
        }}
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setError('')}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              sx={{ mb: 2 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleCreateFolder();
              }
            }}
            sx={{ mt: 2, "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCreateDialogOpen(false);
              setError('');
              setSuccess('');
              setNewFolderName('');
            }}
          >
            Cancel
          </Button>
          <AIButton
            onClick={handleCreateFolder}
            variant="contained"
            disabled={!newFolderName.trim()}
          >
            Create
          </AIButton>
        </DialogActions>
      </Dialog>

      {/* Move Dialog */}
      <Dialog
        open={moveDialogOpen}
        onClose={() => setMoveDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Move Videos</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Button 
              variant="outlined" 
              fullWidth
              onClick={() => handleMoveVideos(null)}
              sx={{ mb: 2, borderRadius: "12px" }}
            >
              Move to Root (No Folder)
            </Button>

            <Typography variant="subtitle2" gutterBottom>
              Or select a destination folder:
            </Typography>

            <Box sx={{ mt: 2 }}>
              <FolderTree
                folders={folders}
                selectedFolder={null}
                onFolderSelect={(folder) => handleMoveVideos(folder?.id)}
                onDropInFolder={null}
                sharedFolders={[]}
                handleFolderDelete={() => {}}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMoveDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Context Menu */}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          handleMoveVideo(null);
        }}>
          Move to Root
        </MenuItem>
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          setSelectedVideos([contextMenuVideo]);
          setMoveDialogOpen(true);
          handleCloseContextMenu();
        }}>
          Move to Folder...
        </MenuItem>
      </Menu>
    </AIContainer>
  );
};

export default SearchAndRetrieveVideos;